<template>
  <div>
    <div v-if="model===false">
      <div class="totalmakeHole" ref="total">   <div class="topback"  :style="{ height: '7vh', backgroundColor: scrollback ? 'rgb(196,196,196)' : 'transparent' ,position:'fixed'}">
          <headtip @showDialog="showPartner"></headtip>
        </div>
        <div class="makeHole1">
          <div class="background">
            <!--     文字-->
            <div class="titlle1">Oil & Gas Company Data (Knowledge) Governance Services</div>
            <div class="title4">Anton combines the capabilities of data governance service providers to achieve data cleaning, feature selection and data annotation, ultimately improving user data availability and maximizing the effectiveness of artificial intelligence models.

            </div>
            <div class="titlle3" @click="clickConsult('Oil & Gas Generic AI')">
              Product Inquiry
            </div>


          </div>
          <div class="screenmake0">
            <div class="heighLight">Service Offerings</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" style="border-radius: 9px"  src="@/assets/zijianCompany.jpg">
                  </div>


                  <div class="titleDesign">
                    <div class="title2">
                      <li style="margin-bottom: 1vh">Data architecture system design</li>
                      <li style="margin-bottom: 1vh">Data asset catalog system construction</li>
                      <div style="display: flex;margin-bottom: 1vh">
                        <li></li>
                        Evaluation of data quality, availability, diversity, timeliness and applicability  </div>

                      <li style="margin-bottom: 1vh">Assessment of data volume and acquisition methods</li>
                      <li style="margin-bottom: 1vh">Data security and privacy protection assessment</li>
                      <div style="display: flex;margin-bottom: 1vh">
                        <li></li>
                        Data collection, data set preprocessing and feature engineering</div>
                      <li style="margin-bottom: 1vh">Data classification and labeling</li>
                    </div>
                  </div>

                </div>


              </div>
            </div>

          </div>
          <div class="screenmake1">
            <div class="heighLight">Successful Cases</div>
            <div class="grid">
              <el-carousel indicator-position="outside" arrow="always" interval="5000">
                <el-carousel-item v-for="item in success" :key="item">
                  <div class="card">
                    <div class="back">
                      <div class="imgDesign">
                        <img style="border-radius: 9px" loading="lazy" :src="item.img"/>
                      </div>
                      <div class="titleDesign">
                        <div class="title1">{{item.title1}}</div>
                        <div class="title2" v-html="item.title2"></div>
                      </div>
                    </div>


                  </div>


                </el-carousel-item>
              </el-carousel>
            </div>

          </div>
          <bottomTip></bottomTip>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
                <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel">
        <div class="makeHole1">
          <div class="background">
            <!--      logo  you气通GPT社区 合作伙伴  登录-->
            <headtip @showDialog="showPartner"></headtip>
            <!--     文字-->
            <div class="layer2">
              <div class="titlle1">Oil & Gas Company Data (Knowledge) Governance Services</div>
              <div class="title4">Anton combines the capabilities of data governance service providers to achieve data cleaning, feature selection and data annotation, ultimately improving user data availability and maximizing the effectiveness of artificial intelligence models.</div>

            </div>
            <div class="title3" @click="clickConsult('Oil & Gas Generic AI')">
              Product Inquiry
            </div>


          </div>
          <div class="screenmake0">
            <div class="heighLight">Service Offerings</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" style="border-radius: 9px" src="@/assets/zijianCompany.jpg">
                  </div>


                  <div class="titleDesign">
                    <div class="title2">
                      <li>Data architecture system design</li>
                      <li>Data asset catalog system construction</li>
                      <li>Evaluation of data quality, availability, diversity, timeliness and applicability</li>
                      <li>Assessment of data volume and acquisition methods</li>
                      <li>Data security and privacy protection assessment</li>
                      <li>Data collection, data set preprocessing and feature engineering</li>
                      <li>Data classification and labeling</li>
                    </div>
                  </div>

                </div>


              </div>
            </div>

          </div>
          <div class="screenmake1">
            <div class="heighLight">Successful Cases</div>
            <div class="grid">
              <el-carousel indicator-position="outside" arrow="always" interval="5000">
                <el-carousel-item v-for="item in success" :key="item">
                  <div class="card">
                    <div class="back">
                      <div class="imgDesign">
                        <img style="border-radius: 9px" loading="lazy" :src="item.img"/>
                      </div>
                      <div class="titleDesign">
                        <div class="title1">{{item.title1}}</div>
                        <div class="title2" v-html="item.title2"></div>
                      </div>
                    </div>


                  </div>


                </el-carousel-item>
              </el-carousel>
            </div>

          </div>
          <bottomTip></bottomTip>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
                <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>



</template>


<script>

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
export default {

  name: "",

  props: [],

  components: {PartnerTip, consultTip, headtip,bottomTip},

  data() {

       return {title:"",
      pageTitle: 'Oil & Gas Company Data (Knowledge) Governance Services _ Anvision Oil and Gas GPT',
      pageDescription: 'Anton combines the capabilities of data governance service providers to achieve data cleaning, feature selection and data annotation, ultimately improving user data availability and maximizing the effectiveness of artificial intelligence models.',
         pageKeywords: 'Oil & Gas Enterprise Self-built GPT Cloud Platform Services，Enterprise Self-built GPT Cloud Platform Services，Anvision Oil and Gas GPT，Oil & Gas Generic AI，Oil & Gas Generic AI GPT，Oil & Gas Generic AI Cloud Platform',
         model:false, scrollback:false,
      dialogconsult:false,
      dialogVisible:false,
      success:[{
        img:require("@/assets/construct3.png"),
        title1:"Oil & Gas Company Data (Knowledge) Governance Services",
        title2:"Using local data provided by customers as the basic database of the model, Anton combines the capabilities of data governance service providers to achieve data cleaning, feature selection and data annotation, ultimately improving the availability of customers' local data and maximizing the effectiveness of artificial intelligence models.",
      },
        {
          img:require("@/assets/governance1.png"),
          title1:"Smart Production And Operation Platform Data Governance",
          title2:"Help customers establish a closed-loop unified data governance process that meets their own characteristics and covers data governance consulting planning, plan formulation, execution and evaluation, including data standard management, metadata management, quality management, asset management, security management, and data life cycle management. , empowering business application scenarios, helping enterprises build a solid data foundation and achieve digital operations.",
        },
      ]
    }

  },

  methods: {
    clickConsult(item){

      console.log(item)
      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },

    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner(){
      this.dialogconsult=true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
  handleScroll() {
      if (this.model === false) {
        this.scrollHeight = this.$refs.total.scrollTop;
      } else {
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if (this.scrollHeight < 100) {
        this.scrollTrue = true
      } else {
        this.scrollTrue = false
      }
      if (this.scrollHeight < 50) {
        this.scrollback = false
      } else {
        this.scrollback = true
      }
      console.log("gaodu", this.scrollHeight < 100)
    },
  },
  //进入该页面时，用之前保存的滚动位置赋值
  beforeDestroy() {
    // 移除事件监听器
    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);

    document.title=this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }

    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: 'Oil & Gas Company Data (Knowledge) Governance Services_ Anvision Oil and Gas GPT',
        pageDescription: 'Anton combines the capabilities of data governance service providers to achieve data cleaning, feature selection and data annotation, ultimately improving user data availability and maximizing the effectiveness of artificial intelligence models.',
        pageKeywords: 'Oil & Gas Enterprise Self-built GPT Cloud Platform Services，Enterprise Self-built GPT Cloud Platform Services，Anvision Oil and Gas GPT，Oil & Gas Generic AI，Oil & Gas Generic AI GPT，Oil & Gas Generic AI Cloud Platform',

      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .topback{
    position: fixed;
    z-index:999999999999;
    width: 100vw;
    padding: 0 0 0 2.86077411900635vw;
  }
  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    .background{
      padding: 7.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
      width: 100%;
      height: 72.41285537700865vh;
      background-image: url("../../assets/constructBanner.png");
      background-position: center;
      background-size:cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */
      .titlle1 {
        margin-top: 15.09270704573548vh;
        text-align: center;
        font-size: 3.0151935298vw;
         font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #111111;
        line-height: 2.84301606922126vh

      }
      .title4 {
        text-align: center;
        margin: 6.82076637824475vh auto 0 auto;
        width: 58.45869439630272vw;
        font-size: 1.0287117273vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color:#111111;
        line-height: 4.31520395550062vh;
      }
      .titlle3 {
        margin: 8.07911001236094vh auto;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #ffffff;
        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 53px;
        width: fit-content;
        padding: 2.4624227441vh 1.1989601386vw;
        height: 4.5624227441vh;
        background: #2f6eff;
        border: #2f6eff 1px solid;

      }
      .titlle3:hover{

        color: #2168DB;
        border: #2168DB 1px solid;
        background: rgba(255, 255, 255, 0.5);
      }
    }
    .screen1 {
      //z-index:: 3;
      position: relative;
      width: 100%;
      height: 74vh;
      padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
      background-image: url("../../assets/peixunBanner.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      .layer2 {
        position: absolute;
        top: 18.1495673671199vh;
        left: 9.17157712305026vw;

        .title1 {
          width: fit-content;
          height: 5.77626699629172vh;
          font-size: 3.50606585788562vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 400;
          color: #2f6eff;
          line-height: 12.23733003708282vh;
        }

        .title2 {
          margin-top: 6.82076637824475vh;
          width: 33.45869439630272vw;
          height: fit-content;
          font-size: 1.0597920277vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 400;
          color: #2f6eff;
          line-height: 4.31520395550062vh;
        }
        .title4{
          height: 8.77626699629172vh;
          font-size: 1.6060658579vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 400;
          color: #2f6eff;
          line-height: 9.31520395550062vh;
        }
        .titlle3{
          margin-top: 2.07911001236094vh;
          //cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 400;
          color: #ffffff;
          line-height: 3.3374536465vh;
          text-align: center;
          border-radius: 53px;
          width: fit-content;
          padding: 2.4624227441vh 1.1989601386vw;
          height: 4.5624227441vh;
          background: #2f6eff;
          border: #2f6eff 1px solid;

        }
        .titlle3:hover{

          color: #2168DB;
          border: #2168DB 1px solid;
          background: rgba(255, 255, 255, 0.5);
        }
      }


      //.layer3 {
      //  position: absolute;
      //  top: 5.06798516687268vh;
      //  right: 11.61178509532062vw;
      //  width: 47.42923165800116vw;
      //  height: 72.80593325092707vh;
      //  background-image: url("../assets/GPTplant.png");
      //  background-size: 100% auto;
      //  background-position: center;
      //  background-repeat: no-repeat;
      //  background-color: transparent;
      //  opacity: 1;
      //}
    }
    .screenmake0{
      padding: 5.7873918418vh 11.0976314269vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 1.7vw;
        //color: #53A3F7;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            //background: #EFF5FF;
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height:fit-content;
            overflow:hidden;
            .imgDesign{
              width:45vw;
              height:fit-content;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;

            }
            .titleDesign{
              padding: 3.5248454883vh 2.5248454883vw 2.5248454883vh 3vw;
              display: flex;
              justify-content: center;
              align-items: center;
              float: right;
              color: black;
              width: 45%;
              .title1{
                margin-top: 4vh;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }
              .title2{
                //text-align: justify;
                line-height: 3.194066749vh;
                font-size:  1vw;
              }
              .title3{
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size:  1vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake1{
      padding: 7.78739184177998vh 5.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: #212a3c;
      //background-color: #ebf1fd;
      .heighLight{
        color:white;
        text-align: center;
        height: 7.54017305315204vh;
        font-size: 1.7vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        //color: #2168DB;
        line-height: 2.71940667490729vh;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        .card{
          ////cursor: pointer;
          margin:0 auto;
          height:65vh;
          width: 86%;
          padding:0.98887515451174vh 0 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:64vh;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: fit-content;
              height:100%;
              opacity: 1;
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 1.5248454883vh 1.5248454883vw;
              color: white;
              width: 30vw;
              margin:auto;
              float: right;
              .title1{
                font-size: 1.1709416522vw;
                font-weight: bold;
              }
              .title2{
                margin-top:10px;
                line-height: 4vh;
                font-size: 0.9243212016vw;
              }
              .success{
                display: grid;
                grid-template-columns: minmax(0,1fr) minmax(0,1fr);
                column-gap: 1vw;
                margin-top: 2vh;
                .suc1{
                  width: 100%;
                  height: 8vh;
                  .title3{
                    text-align: center;
                    margin-top: 2vh;
                    font-size: 0.8243212016vw;
                  }

                }

              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height:68vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 550px;
        }
        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0  ;
          // //cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight:800;
          font-size: 25px;
        }
      }
    }
  }

}

.totalmakeHoleModel{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;

  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    .background{
      padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
      width: 100%;
      height: 400px;
      background-image: url("../../assets/constructBanner.png");
      background-position: center;
      background-size:cover;
      background-repeat: no-repeat;
      background-color: transparent;
      position: relative;
      /* Add any additional styling as needed */
      .layer2 {
        position: absolute;
        left: 0;

        .titlle1 {
          margin: 25px auto 0 auto;
          width: fit-content;
          text-align: center;
          height: fit-content;
          font-size: 22px;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          color: #111111;
          line-height: 22px;

        }

        .title4 {
          margin: 25px auto;
          width: 90%;
          text-align: center;
          height: 17px;
          font-size: 12px;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 400;
          color: #111111;
          line-height: 20px;
        }
      }
      .title3 {
        position: absolute;
        bottom:30px;
        left:50%;
        transform: translateX(-60%);
        font-size: 14px;
         font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        line-height: 15px;
        height: 35px;
        color:white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;


      }

    }
    .screenmake0{
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        //text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin-top: 5px;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .title3{
            margin-top: 5px;
            display: grid;
            grid-template-columns: minmax(0,1fr) minmax(0,1fr);
            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            margin-top: 10px;
          }
          .back{
            background: white;
            border-radius: 9px;
            display: grid;
            grid-template-columns: minmax(0,1fr);
            column-gap: 5px;
            position: relative;
            height:fit-content ;
            margin-top: 15px;
            overflow:hidden;
            .imgDesign{
              width: 100%;
              //border: #666666 1px solid;
              height:fit-content;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake1{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: black;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        color: white;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        //text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          left:-55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          right: -55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .card{
          //cursor: pointer;
          width: 98%;
          margin:0 auto;
          height:fit-content ;
          padding:0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;
          .back{
            border-radius: 9px;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height: 580px !important;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: 100%;
              height:fit-content;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 5px 20px 25px 20px;
              color: white;
              width:100%;
              float: right;
              .title1{
                margin-top:10px;
                font-size: 16px;
              }
              .title2{
                margin-top: 15px;
                line-height: 22px;
                font-size:14px;
                ::v-deep li{
                  margin-top:5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 555px !important;

        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__arrow {
          display: block;
          //border: 1px solid white;
          outline: -5px;
          padding: 0;
          margin: -155px -10px  ;
          //cursor: pointer;

          border-radius: 0;
          width: 25px;
          height: 48px;
          background: rgba(245, 246, 250, 0.8);
          color: #131313;
          position: absolute;
          top: 50%;
          //z-index:: 100;
          transform: translateY(-50%);
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }

}
</style>
