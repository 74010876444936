var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.model===false)?_c('div',[_c('div',{ref:"total",staticClass:"totalmakeHole"},[_c('div',{staticClass:"topback",style:({ height: '7vh', backgroundColor: _vm.scrollback ? 'rgb(196,196,196)' : 'transparent' ,position:'fixed'})},[_c('headtip',{on:{"showDialog":_vm.showPartner}})],1),_c('div',{staticClass:"makeHole1"},[_c('div',{staticClass:"background"},[_c('div',{staticClass:"titlle1"},[_vm._v("油气开发作业GPT应用项目服务")]),_c('div',{staticClass:"title4"},[_vm._v("帮助企业客户打造油气开发作业GPT应用项目服务，借助AI、大数据、IoT等技术，通过GPT与边缘智能融合，实现油藏地质、钻井、压裂、采油气、地层监测和设备设施监测的一体化智能解决方案，助力油气企业优化施工，改进工艺及流程，提高开发经济效益。")]),_c('div',{staticClass:"titlle3",on:{"click":function($event){return _vm.clickConsult('Oil & Gas Generic AI')}}},[_vm._v(" Product Inquiry ")])]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('bottomTip')],1),_c('partnerTip',{attrs:{"dialogVisible":_vm.dialogconsult},on:{"hideDialog":_vm.hide}}),_c('consultTip',{attrs:{"dialogVisible":_vm.dialogVisible,"thisTitle":_vm.title},on:{"hideDialog":_vm.hideDialog}})],1)]):_c('div',[_c('div',{staticClass:"totalmakeHoleModel"},[_c('div',{staticClass:"makeHole1"},[_c('div',{staticClass:"background"},[_c('headtip',{on:{"showDialog":_vm.showPartner}}),_vm._m(6),_c('div',{staticClass:"title3",on:{"click":function($event){return _vm.clickConsult('Oil & Gas Generic AI')}}},[_vm._v(" Product Inquiry ")])],1),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_c('bottomTip')],1),_c('partnerTip',{attrs:{"dialogVisible":_vm.dialogconsult},on:{"hideDialog":_vm.hide}}),_c('consultTip',{attrs:{"dialogVisible":_vm.dialogVisible,"thisTitle":_vm.title},on:{"hideDialog":_vm.hideDialog}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake0"},[_c('div',{staticClass:"heighLight"},[_vm._v("油气开发作业GPT应用项目服务 ")]),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"back"},[_c('div',{staticClass:"imgDesign"},[_c('img',{staticStyle:{"border-radius":"9px"},attrs:{"loading":"lazy","src":require("@/assets/oilApplication6.png")}})]),_c('div',{staticClass:"titleDesign"},[_c('div',{staticClass:"title2"},[_vm._v(" 安东在油气开发领域利用 AI 技术已有具体应用落地，在钻井领域，实现智能地质导向；在压裂领域，实现产量 EUR 预测和压裂工程智能设计；在光纤监测领域，基于光纤监测的DAS声波数据、DTS温度数据监控井筒漏点等。 ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake1"},[_c('div',{staticClass:"heighLight"},[_vm._v("GPT辅助智能地质导向")]),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"back"},[_c('div',{staticClass:"titleDesign"},[_c('div',{staticClass:"title2"},[_vm._v(" 在钻井过程中通过测量多种地质和工程参数来对所钻地层的地质参数进行实时评价，根据评价结果来精确的控制井下钻具命中最佳地质目标。 ")])]),_c('div',{staticClass:"imgDesign"},[_c('img',{staticStyle:{"border-radius":"9px"},attrs:{"loading":"lazy","src":require("@/assets/oilApplication2.png")}})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake0"},[_c('div',{staticClass:"heighLight"},[_vm._v("GPT辅助光纤漏点智能监测")]),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"back"},[_c('div',{staticClass:"imgDesign"},[_c('img',{staticStyle:{"border-radius":"9px"},attrs:{"loading":"lazy","src":require("@/assets/oilApplication3.png")}})]),_c('div',{staticClass:"titleDesign"},[_c('div',{staticClass:"title2"},[_c('li',[_vm._v("实时监测分布式温度声波信号、低频应变信号；")]),_c('li',[_vm._v("漏点异常监测预警及定位；")]),_c('li',[_vm._v("管柱震动评价；")]),_c('li',[_vm._v("漏点原因分析；")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake1"},[_c('div',{staticClass:"heighLight"},[_vm._v("GPT辅助压裂施工参数设计与优化")]),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"back"},[_c('div',{staticClass:"titleDesign"},[_c('div',{staticClass:"title2"},[_vm._v(" 压裂措施的增产效果，以尽可能小的投资获得最大的回报，就需要对影响压裂井的因素进行分析。以理论油藏模型和油田实际油藏模型为基础引进敏感系数，通过比较敏感系数绝对值的大小，对压裂井增产效果影响因素进行分析判断。 ")])]),_c('div',{staticClass:"imgDesign"},[_c('img',{staticStyle:{"border-radius":"9px"},attrs:{"loading":"lazy","src":require("@/assets/oilApplication4.png")}})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake0"},[_c('div',{staticClass:"heighLight"},[_vm._v("智能采油机器人")]),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"back"},[_c('div',{staticClass:"imgDesign"},[_c('img',{staticStyle:{"border-radius":"9px"},attrs:{"loading":"lazy","src":require("@/assets/oilApplication5.png")}})]),_c('div',{staticClass:"titleDesign"},[_c('div',{staticClass:"title2"},[_vm._v(" 针对抽油机井，实时采集压力温度、电参、示功图、动液面等数据，输入到智能采油机器人工艺脑，输出得到最优化生产运行参数，减少无效冲次，杜绝空抽，使抽油机在最佳工况下运行，油井能够保持动液面稳定，达到了油井供采平衡，实现了油井的稳产增产。 ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake1"},[_c('div',{staticClass:"heighLight"},[_vm._v("GPT辅助钻修机监测及预测性维护")]),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"card",staticStyle:{"height":"40vh"}},[_c('div',{staticClass:"back"},[_c('div',{staticClass:"titleDesign"},[_c('div',{staticClass:"title2",staticStyle:{"margin-top":"-3vh"}},[_vm._v(" 建设钻修机设备预测性维护平台，实现设备在线状态监测（智能预警、智能诊断、智能评估等），目前已在中海油海上平台上取得成果应用。 ")])]),_c('div',{staticClass:"imgDesign"},[_c('img',{staticStyle:{"border-radius":"9px"},attrs:{"loading":"lazy","src":require("@/assets/oilApplication7.png")}})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layer2"},[_c('div',{staticClass:"titlle1"},[_vm._v("油气开发作业GPT应用项目服务")]),_c('div',{staticClass:"title4"},[_vm._v("帮助企业客户打造油气开发作业GPT应用项目服务，借助AI、大数据、IoT等技术，通过GPT与边缘智能融合，实现油藏地质、钻井、压裂、采油气、地层监测和设备设施监测的一体化智能解决方案，助力油气企业优化施工，改进工艺及流程，提高开发经济效益。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake0"},[_c('div',{staticClass:"heighLight"},[_vm._v("油气开发作业GPT应用项目服务 ")]),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"back"},[_c('div',{staticClass:"imgDesign",staticStyle:{"height":"180px"}},[_c('img',{staticStyle:{"border-radius":"9px"},attrs:{"loading":"lazy","src":require("@/assets/oilApplication6.png")}})]),_c('div',{staticClass:"titleDesign"},[_c('div',{staticClass:"title2"},[_vm._v(" 安东在油气开发领域利用 AI 技术已有具体应用落地，在钻井领域，实现智能地质导向；在压裂领域，实现产量 EUR 预测和压裂工程智能设计；在光纤监测领域，基于光纤监测的DAS声波数据、DTS温度数据监控井筒漏点等。 ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake1"},[_c('div',{staticClass:"heighLight"},[_vm._v("GPT辅助智能地质导向")]),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"back"},[_c('div',{staticClass:"imgDesign"},[_c('img',{staticStyle:{"border-radius":"9px"},attrs:{"loading":"lazy","src":require("@/assets/oilApplication2.png")}})]),_c('div',{staticClass:"titleDesign"},[_c('div',{staticClass:"title2"},[_vm._v(" 在钻井过程中通过测量多种地质和工程参数来对所钻地层的地质参数进行实时评价，根据评价结果来精确的控制井下钻具命中最佳地质目标。 ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake0"},[_c('div',{staticClass:"heighLight"},[_vm._v("GPT辅助光纤漏点智能监测")]),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"back"},[_c('div',{staticClass:"imgDesign",staticStyle:{"height":"180px"}},[_c('img',{staticStyle:{"border-radius":"9px"},attrs:{"loading":"lazy","src":require("@/assets/oilApplication3.png")}})]),_c('div',{staticClass:"titleDesign"},[_c('div',{staticClass:"title2"},[_c('li',[_vm._v("实时监测分布式温度声波信号、低频应变信号；")]),_c('li',[_vm._v("漏点异常监测预警及定位；")]),_c('li',[_vm._v("管柱震动评价；")]),_c('li',[_vm._v("漏点原因分析；")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake1"},[_c('div',{staticClass:"heighLight"},[_vm._v("GPT辅助压裂施工参数设计与优化")]),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"back"},[_c('div',{staticClass:"imgDesign",staticStyle:{"height":"170px"}},[_c('img',{staticStyle:{"border-radius":"9px"},attrs:{"loading":"lazy","src":require("@/assets/oilApplication4.png")}})]),_c('div',{staticClass:"titleDesign"},[_c('div',{staticClass:"title2"},[_vm._v(" 压裂措施的增产效果，以尽可能小的投资获得最大的回报，就需要对影响压裂井的因素进行分析。以理论油藏模型和油田实际油藏模型为基础引进敏感系数，通过比较敏感系数绝对值的大小，对压裂井增产效果影响因素进行分析判断。 ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake0"},[_c('div',{staticClass:"heighLight"},[_vm._v("智能采油机器人")]),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"back"},[_c('div',{staticClass:"imgDesign",staticStyle:{"height":"180px"}},[_c('img',{attrs:{"loading":"lazy","src":require("@/assets/oilApplication5.png")}})]),_c('div',{staticClass:"titleDesign"},[_c('div',{staticClass:"title2"},[_vm._v(" 针对抽油机井，实时采集压力温度、电参、示功图、动液面等数据，输入到智能采油机器人工艺脑，输出得到最优化生产运行参数，减少无效冲次，杜绝空抽，使抽油机在最佳工况下运行，油井能够保持动液面稳定，达到了油井供采平衡，实现了油井的稳产增产。 ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake1"},[_c('div',{staticClass:"heighLight"},[_vm._v("GPT辅助钻修机监测及预测性维护")]),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"back"},[_c('div',{staticClass:"imgDesign",staticStyle:{"height":"160px"}},[_c('img',{staticStyle:{"border-radius":"9px"},attrs:{"loading":"lazy","src":require("@/assets/oilApplication7.png")}})]),_c('div',{staticClass:"titleDesign"},[_c('div',{staticClass:"title2"},[_vm._v(" 建设钻修机设备预测性维护平台，实现设备在线状态监测（智能预警、智能诊断、智能评估等），目前已在中海油海上平台上取得成果应用。 ")])])])])])])
}]

export { render, staticRenderFns }