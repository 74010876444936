<template>
  <div>
    <div v-if="model===false">
      <div class="totalmakeHole" ref="total">
        <div class="topback"  :style="{ height: '7vh', backgroundColor: scrollback ? 'rgb(196,196,196)' : 'transparent' ,position:'fixed'}">
          <headtip @showDialog="showPartner"></headtip>
        </div>

        <div class="makeHole1">
          <div class="background">
            <!--     文字-->
            <div class="titlle1">Formation Monitoring Solutions </div>
            <div class="title4">*The solution is the field of Oil & Gas development technology services and AI model fusion, through the field of the solution to provide services to clients, for further understanding of the solution cooperation, please feel free to contact us.</div>
            <div class="titlle3" @click="dialogVisible=true">
              Solution Consulting
            </div>


          </div>
          <div class="screenmake0">
            <div class="heighLight">Formation Monitoring Services Overview</div>
            <div class="heightLight2">Anton Deep Earth IntelliLight specializes in customized and systematic data acquisition and interpretation for the entire oil & gas development process, encompassing fiber optic monitoring, digital rock technology, reservoir monitoring, and well logging.
            </div>
            <div class="heightLight3">Stratigraphic Monitoring</div>
            <div class="line"></div>
            <div class="line0"></div>
            <div class="grid0">
              <div class="card">
                <div class="heightLight3">Fiber Optic Monitoring</div>
              </div>
              <div class="card">
                <div class="line"></div>
                <div class="heightLight3">Digital Rock Technology</div>
              </div>
              <div class="card">
                <div class="line"></div>
                <div class="heightLight3">Reservoir Inspection Technology</div>
              </div>
              <div class="card">
                <div class="heightLight3">Well Logging Technology</div>
              </div>


            </div>

          </div>
          <div class="screenmake">
            <div class="heighLight">
              <span style="margin-top:0.7vh;background-color: #FF6400;border-radius: 50%; width:1.7vw;font-size:1.5vw;height:1.7vw;display: flex;justify-content: center;align-items: center;color: white; ">1</span>
              <span>&nbsp;Intelligent Real-Time Fiber Optic Dynamic Monitoring Of Gas Storage Wellbore Integrity</span>
            </div>
            <div class="Light">Deploying intelligent fiber optic monitoring system to provide real-time monitoring and early warning of wellbore leakage, abnormal annular protection fluid level, tubing vibration, downhole pressure and injection and production dynamics.</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Dynamic monitoring map of the depth of the annular air protection fluid level surface</div>
                  </div>
                </div>


              </div>
              <div class="card1">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Real-time monitoring map of leakage point</div>
                  </div>
                </div>

              </div>

            </div>

          </div>
          <div class="screenmake2">
            <div class="heighLight">
              <span style="margin-top:0.7vh;background-color: #FF6400;border-radius: 50%; width:1.7vw;font-size:1.5vw;height:1.7vw;display: flex;justify-content: center;align-items: center;color: white; ">2</span>
              <span>&nbsp;&nbsp;Intelligent Monitoring Of Fiber Optic Leakage Points</span>
            </div>
            <div class="Light">Intelligent monitoring of fiber optic leaks includes real-time monitoring of distributed temperature acoustic signals, low-frequency strain signals, early warning and positioning of leaks, vibration evaluation of pipe columns and analysis of leakage causes.</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Real-time monitoring of steel wire fiber leakage point</div>
                  </div>
                </div>


              </div>
              <div class="card1">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Real-time monitoring of steel wire fiber leakage point</div>
                  </div>
                </div>

              </div>

            </div>

          </div>
          <div class="screenmake3">
            <div class="heighLight">
              <span style="margin-top:0.7vh;background-color: #FF6400;border-radius: 50%; width:1.7vw;font-size:1.5vw;height:1.7vw;display: flex;justify-content: center;align-items: center;color: white; ">3</span>
              <span>&nbsp;&nbsp;Intelligent Fiber Optic Production Monitoring</span>
            </div>
            <div class="Light">Deploying intelligent fiber-optic monitoring system to monitor wellbore temperature profile, acoustic vibration signals and downhole pressure in real time during production, and intelligently analyze and interpret the output data of the layers to guide the optimization of the production system and improve the recovery rate.</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Comprehensive interpretation of fiber optic output profile</div>
                  </div>
                </div>


              </div>
              <div class="card1">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Comprehensive interpretation of fiber optic output profile</div>
                  </div>
                </div>

              </div>

            </div>

          </div>
          <div class="screenmake4">
            <div class="heighLight">
              <span style="margin-top:0.7vh;background-color: #FF6400;border-radius: 50%; width:1.7vw;font-size:1.5vw;height:1.7vw;display: flex;justify-content: center;align-items: center;color: white; ">4</span>
              <span>&nbsp;&nbsp;Intelligent Fracturing Monitoring With Fiber Optics</span>
            </div>
            <div class="Light">Deploying intelligent fiber-optic monitoring system to dynamically monitor the fluid and sand intake, fracture development, and fracture impact in real time during the fracturing process, and evaluate the fracturing effect.</div>

            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Intelligent fracturing monitoring microseismic monitoring</div>
                  </div>
                </div>


              </div>
              <div class="card1">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Segment cluster modification monitoring</div>
                  </div>
                </div>


              </div>

            </div>

          </div>
          <div class="screenmake5">
            <div class="heighLight">Successful Cases</div>
            <div class="grid">
              <el-carousel indicator-position="outside" :current-index="currentIndex" arrow="always" interval="5000">
                <el-carousel-item v-for="(item, index) in success" :key="index">
                  <div class="card">
                    <div class="back">
                      <div class="imgDesign">
                        <img :src="item.img"/>
                      </div>
                      <div class="titleDesign">
                        <div class="title1">{{item.title1}}</div>
                        <div class="title1" style="margin-top: 25px">{{item.title2}}</div>
                        <div class="title2" v-html="item.title3"></div>
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
              <!--          <el-button class="arrowButtonleft" @click="leftnext" :disabled="currentIndex === 0">-->
              <!--            <i class="el-icon-arrow-left"></i>-->
              <!--          </el-button>-->
              <!--          <el-button class="arrowButtonright" @click="rightnext" :disabled="currentIndex === success.length - 1">-->
              <!--            <i class="el-icon-arrow-right"></i>-->
              <!--          </el-button>-->
            </div>

          </div>
          <bottomTip></bottomTip>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <resultTip
            :dialogVisible="dialogVisible"
            @hideDialog="hideDialog"
        ></resultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel">
        <div class="makeHole1">
          <div class="background">
            <!--      logo  you气通GPT社区 合作伙伴  登录-->
            <headtip @showDialog="showPartner"></headtip>
            <!--     文字-->
            <div class="titlle1">Formation Monitoring Solutions</div>
            <div class="title4">*The solution is the field of Oil & Gas development technology services and AI model fusion, through the field of the solution to provide services to clients, for further understanding of the solution cooperation, please feel free to contact us.</div>
            <div class="titlle3" @click="dialogVisible=true">
              Solution Consulting
            </div>


          </div>
          <div class="screenmake0">
            <div class="heighLight">Formation Monitoring Services Overview</div>
            <div class="heightLight2">Anton Deep Earth Smart Light Data Technology features customized and systematic data acquisition and interpretation services for the whole process of Oil & Gas development, and its business includes fiber-optic monitoring technology, digital rock technology, Oil & Gas reservoir monitoring technology, well logging technology.</div>

            <div class="heightLight3">Stratigraphic Monitoring</div>
            <div class="line"></div>
            <div class="line0"></div>
            <div class="grid0">
              <div class="card">
                <div class="heightLight3">Fiber Optic Monitoring</div>
              </div>
              <div class="card">
                <div class="line"></div>
                <div class="heightLight3">Digital Rock Technology</div>
              </div>
              <div class="card">
                <div class="line"></div>
                <div class="heightLight3">Reservoir Inspection Technology</div>
              </div>
              <div class="card">
                <div class="heightLight3">Well Logging Technology</div>
              </div>


            </div>

          </div>
          <div class="screenmake">
            <div class="heighLight">
              <span>①&nbsp;Intelligent Real-Time Fiber Optic Dynamic Monitoring Of Gas Storage Wellbore Integrity</span>
            </div>
            <div class="Light">Deploying intelligent fiber optic monitoring system to provide real-time monitoring and early warning of wellbore leakage, abnormal annular protection fluid level, tubing vibration, downhole pressure and injection and production dynamics.</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Dynamic monitoring map of the depth of the annular air protection fluid level surface</div>
                  </div>
                </div>


              </div>
              <div class="card1">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Real-time monitoring map of leakage point</div>
                  </div>
                </div>

              </div>

            </div>

          </div>
          <div class="screenmake2">
            <div class="heighLight">
              <span>②&nbsp;Intelligent Monitoring Of Fiber Optic Leakage Points</span>
            </div>
            <div class="Light">Intelligent monitoring of fiber optic leaks includes real-time monitoring of distributed temperature acoustic signals, low-frequency strain signals, early warning and positioning of leaks, vibration evaluation of pipe columns and analysis of leakage causes.</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Real-time monitoring of steel wire fiber leakage point</div>
                  </div>
                </div>


              </div>
              <div class="card1">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Real-time monitoring of steel wire fiber leakage point</div>
                  </div>
                </div>

              </div>

            </div>

          </div>
          <div class="screenmake3">
            <div class="heighLight">
              <span>③&nbsp;Intelligent Fiber Optic Production Monitoring</span>
            </div>
            <div class="Light">Deploying intelligent fiber-optic monitoring system to monitor wellbore temperature profile, acoustic vibration signals and downhole pressure in real time during production, and intelligently analyze and interpret the output data of the layers to guide the optimization of the production system and improve the recovery rate.</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Comprehensive interpretation of fiber optic output profile</div>
                  </div>
                </div>


              </div>
              <div class="card1">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Comprehensive interpretation of fiber optic output profile</div>
                  </div>
                </div>

              </div>

            </div>

          </div>
          <div class="screenmake4">
            <div class="heighLight">
              <span>④&nbsp;Intelligent Fracturing Monitoring With Fiber Optics</span>
            </div>
            <div class="Light">Deploying intelligent fiber-optic monitoring system to dynamically monitor the fluid and sand intake, fracture development, and fracture impact in real time during the fracturing process, and evaluate the fracturing effect.</div>

            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Intelligent fracturing monitoring microseismic monitoring</div>
                  </div>
                </div>


              </div>
              <div class="card1">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Segment cluster modification monitoring</div>
                  </div>
                </div>


              </div>

            </div>

          </div>
          <div class="screenmake5">
            <div class="heighLight">Successful Cases</div>
            <div class="grid">
              <el-carousel indicator-position="outside" :current-index="currentIndex" arrow="always" interval="5000">
                <el-carousel-item v-for="(item, index) in success" :key="index">
                  <div class="card">
                    <div class="back">
                      <div class="imgDesign">
                        <img :src="item.img"/>
                      </div>
                      <div class="titleDesign">
                        <div class="title1">{{item.title1}}</div>
                        <div class="title1">{{item.title2}}</div>
                        <div class="title2" v-html="item.title3"></div>
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
              <!--          <el-button class="arrowButtonleft" @click="leftnext" :disabled="currentIndex === 0">-->
              <!--            <i class="el-icon-arrow-left"></i>-->
              <!--          </el-button>-->
              <!--          <el-button class="arrowButtonright" @click="rightnext" :disabled="currentIndex === success.length - 1">-->
              <!--            <i class="el-icon-arrow-right"></i>-->
              <!--          </el-button>-->
            </div>

          </div>
          <div ref="bottom">
            <bottomTip></bottomTip>
          </div>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <resultTip
            :dialogVisible="dialogVisible"
            @hideDialog="hideDialog"
        ></resultTip>
      </div>
    </div>
  </div>



</template>


<script>

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import resultTip from "@/components/ResultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
export default {

  name: "",

  props: [],

  components: {PartnerTip, resultTip, headtip,bottomTip},

  data() {

    return {
      pageTitle: 'Formation monitoring solution_GPT application project service for Oil & Gas development operations-Oil and Gas Communication GPT',
      pageDescription: 'Anton Deep Earth Smart Light Data Technology features customized and systematic data acquisition and interpretation services for the whole process of Oil & Gas development, and its business includes fiber-optic monitoring technology, digital rock technology, Oil & Gas reservoir monitoring technology, well logging technology.',
      pageKeywords: 'Formation monitoring, optical fiber monitoring technology, digital rock technology, Oil & Gas reservoir detection technology, logging technology, Oil & Gas development operation GPT, Oil & Gas communication GPT',
      scrollback:false,
      model:false,
      dialogconsult:false,
      dialogVisible:false,
      currentIndex: 0 ,
      success:[{
        img:require("@/assets/success9.jpg"),
        title1:"Fiber Optic Leakage Monitoring GPT",
        title2:"DAS Analysis",
        title3:"Accurately determine the location of leaks based on temperature and sound information.",
             },{
        img:require("@/assets/success10.png"),
        title1:"Intelligent Fracturing GPTs System",
        title2:"DTS Analysis",
        title3:"Based on sound intensity and frequency band information, evaluate the shape and size of leakage point and give suggestions for leakage point repair program.",

      },
      ]
    }

  },

  methods: {
    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
    leftnext() {
      if (this.currentIndex > 0) {
        this.currentIndex--; // 减少索引以向左移动
      } else {
        // 如果 currentIndex 已经是0，则将其设置为最后一个索引
        this.currentIndex = this.success.length - 1;
      }
    },
    rightnext() {
      if (this.currentIndex < this.success.length - 1) {
        this.currentIndex++; // 增加索引以向右移动
      } else {
        // 如果 currentIndex 已经是最后一个索引，则将其设置为0
        this.currentIndex = 0;
      }
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner(){
      this.dialogconsult=true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
 handleScroll() {
      if (this.model === false) {
        this.scrollHeight = this.$refs.total.scrollTop;
      } else {
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if (this.scrollHeight < 100) {
        this.scrollTrue = true
      } else {
        this.scrollTrue = false
      }
      if (this.scrollHeight < 50) {
        this.scrollback = false
      } else {
        this.scrollback = true
      }
      console.log("gaodu", this.scrollHeight < 100)
    },
  },
  //进入该页面时，用之前保存的滚动位置赋值
  beforeDestroy() {
    // 移除事件监听器
    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);


    document.title=this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);

    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }
    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }

  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: 'Formation monitoring solution_GPT application project service for Oil & Gas development operations-Oil and Gas Communication GPT',
        pageDescription: 'Anton Deep Earth Smart Light Data Technology features customized and systematic data acquisition and interpretation services for the whole process of Oil & Gas development, and its business includes fiber-optic monitoring technology, digital rock technology, Oil & Gas reservoir monitoring technology, well logging technology.',
        pageKeywords: 'Formation monitoring, optical fiber monitoring technology, digital rock technology, Oil & Gas reservoir detection technology, logging technology, Oil & Gas development operation GPT, Oil & Gas communication GPT',

      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .topback{
    position: fixed;
    z-index:999999999999;
    width: 100vw;
    padding: 0 0 0 2.86077411900635vw;
  }
  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    .background{
      padding: 7.84301606922126vh 2.86077411900635vw  2.84301606922126vh 7.86077411900635vw;
      width: 100%;
      height: 72.41285537700865vh;
      background-image: url("../../assets/menage1.jpg");
      background-position: center;
      background-size:cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */
      .titlle1 {
        margin-top: 15.09270704573548vh;
        text-align: center;
        font-size: 2vw;
         font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #111111;
        line-height: 2.84301606922126vh

      }
      .title4 {
        text-align: center;
        margin: 6.82076637824475vh auto 0 auto;
        width: 62vw;
        font-size: 1vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color:#111111;
        line-height: 4.31520395550062vh;
      }
      .titlle3 {
        margin: 8.07911001236094vh auto;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.9vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #ffffff;
        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 53px;
        width: fit-content;
        padding: 2.4624227441vh 1.1989601386vw;
        height: 4.5624227441vh;
        background: #2f6eff;
        border: #2f6eff 1px solid;

      }
      .titlle3:hover{

        color: #2168DB;
        border: #2168DB 1px solid;
        background: rgba(255, 255, 255, 0.5);
      }
    }
    .screenmake0{
      padding:5.78739184177998vh 11.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:6.23733003708282vh;
        opacity: 1;
        font-size: 1.7vw;
        //color: #53A3F7;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        line-height: 3vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 8.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height:3.5vh;
        width: 66.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      }
      .heightLight3{
        margin: 2vh auto 0 auto;
        //cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 500;
        color: #2f6eff;
        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 53px;
        width: fit-content;
        padding: 2.4624227441vh 2.1989601386vw;
        height:7.5624227441vh;
        background: #B3D0FF;
        border: #B3D0FF 1px solid;
      }
      .line{
         height: 5.831891223733vh;
         width: 4px;
         left: 50%;
        margin:0 auto;
         background-color: #B3D0FF;
         margin:0 auto;
       }
      .line0{
        width:45.7vw;
        border-top-right-radius: 63px;
        border-top-left-radius: 63px;
        border-top:  #B3D0FF 4px solid;
        border-left:  #B3D0FF 4px solid;
        border-right:  #B3D0FF 4px solid;;
        height: 5.831891223733vh;
        margin:0 auto;
      }
      .grid0{
        display: grid;
        width: 60vw;
        margin:0 auto;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        //column-gap: 1vw;
        .card{
          position: relative;
          margin: 0 auto;
          text-align: center;
          .line{
            position: absolute;
            height:5.831891223733vh;
            width: 4px;
            left: 50%;
            top:-5.831891223733vh;
            transform: translateX(-50%);
            background-color: #B3D0FF;
            margin:0 auto;
          }

          .heightLight3{
            margin:0 auto;

            // cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.9vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 500;
            color: #2f6eff;
            line-height: 3.3374536465vh;
            text-align: center;
            border-radius: 53px;
            width: fit-content;
            padding: 2.4624227441vh 0.5vw;
            height:8.5624227441vh;
            background: transparent;
            border: #B3D0FF 4px solid;
          }
        }


      }
      .grid{
        margin-top:4.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        //column-gap: 1vw;
        .card{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:30.72311495673671vh ;
            background: #2F6EFF linear-gradient(to right, #CFE1FF 0%, #F0F6FF 100%);
            display: flex;

            .titleDesign{
              .Title1{
                text-align: center;
                margin:5.8124845488vh 2.5597920277vw 1.1124845488vh 2.155401502vw;
                height:fit-content;
                font-size: 1vw;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                margin:4.8124845488vh 2.5597920277vw 1.1124845488vh 2.155401502vw;
                text-align: justify;
                height: 4.6971569839vh;
                font-size:0.8243212016vw;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 400;
                //color:#2F6EFF;
                line-height: 3.194066749vh;
              }
            }
          }



        }
        .card1{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background: #FFFFFF;
            margin-bottom: 1.5248454883vh;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              text-align: center;
              height:54vh;
              width: 100%;
              background-image: url("../../assets/serve1.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 1.5248454883vh 1.5248454883vw;
              background-color: #2F6EFF;
              .Title1{
                color: white;
                text-align: center;
                font-size: 1vw;

                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 700;
              }

            }
            .backconsult{
              display: none;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              //color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left:5.55414091470952vw ;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
            }

          }

        }

      }
    }
    .screenmake{
      padding:5.78739184177998vh 11.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: #ebf1fd;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:fit-content;
        font-size: 1.7vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #FF6400;
        line-height: 5vh;

      }

      .Light{
        margin:2vh auto 0 auto;
        text-align: center;
        height: 8.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height:3.5vh;

        width: 66.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      }.grid{
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        //column-gap:2.21317157712305vw ;
        .card{
          // cursor: pointer;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            box-shadow: 5px 0 14px 0px #D4E4FF;
             height:fit-content;;
            background: #FFFFFF ;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              width: 100%;
              height: 29.5vh;
              opacity: 1;
              //border:1px solid blue;
              background-image: url("../../assets/menage2.jpg");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign{
              padding: 5.2124845488vh 5.5597920277vw 5.1124845488vh 5.155401502vw;
              .Title1{
                text-align: center;
                height:fit-content;
                font-size: 1vw;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                text-align: justify;
                margin-top: 3.8124845488vh;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 400;
                line-height: 2.7194066749vh;
              }
            }
          }
        }

        .card1{
          // cursor: pointer;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            box-shadow: 5px 0 14px 0px #D4E4FF;
             height:fit-content;;
            background: #FFFFFF ;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              width: 100%;
              height: 29.5vh;
              opacity: 1;
              //border:1px solid blue;
              background-image: url("../../assets/menage3.jpg");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign{
              padding: 5.2124845488vh 5.5597920277vw 5.1124845488vh 5.155401502vw;
              .Title1{
                text-align: center;
                height:5.4vh;
                font-size: 1vw;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                text-align: justify;
                margin-top: 3.8124845488vh;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 400;
                line-height: 2.7194066749vh;
              }
            }
          }
        }

      }
    }
    .screenmake2{
      padding:5.78739184177998vh 11.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: white;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:6.23733003708282vh;
        font-size: 1.7vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #FF6400;
        line-height: 4.23733003708282vh;

      }

      .Light{
        margin:0 auto;
        text-align: center;
        height: 8.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height:3.5vh;

        width: 66.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      }
      .grid{
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        //column-gap:2.21317157712305vw ;
        .card{
          // cursor: pointer;
          height:fit-content ;
          width:100%;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            background: #FFFFFF ;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              width: 100%;
              height: 60vh;
              opacity: 1;
              //border:1px solid blue;
              background-image: url("../../assets/menage4.jpg");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign{
              padding: 5.2124845488vh 5.5597920277vw 5.1124845488vh 5.155401502vw;
              .Title1{
                text-align: center;

                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1vw;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                margin-left: 1vw;
                margin-top: 2.8124845488vh;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }

        .card1{
          // cursor: pointer;
          height:fit-content ;
          width: 100%;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            background: #FFFFFF ;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              width: 100%;
              height: 60vh;
              opacity: 1;
              //border:1px solid blue;
              background-image: url("../../assets/menage4.jpg");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign{
              padding: 5.2124845488vh 5.5597920277vw 5.1124845488vh 5.155401502vw;
              .Title1{
                text-align: center;
                height:fit-content;
                font-size: 1vw;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                margin-left: 10vw;
                margin-top: 2.8124845488vh;

                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }

      }
    }
    .screenmake3{
      padding:5.78739184177998vh 11.09763142692085vw;
      width: 100%;
      height:fit-content;
      background:#ebf1fd;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:6.23733003708282vh;
        font-size: 1.7vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #FF6400;
        line-height: 4.23733003708282vh;

      }

      .Light{
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height:3.5vh;

        width: 66.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      }
      .grid{
        //margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        //column-gap:2.21317157712305vw ;
        .card{
          // cursor: pointer;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            background: #FFFFFF ;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              width: 100%;
              height: 35vh;
              opacity: 1;
              //border:1px solid blue;
              background-image: url("../../assets/menage10.jpg");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign{
              padding: 5.2124845488vh 5.5597920277vw 5.1124845488vh 5.155401502vw;
              .Title1{
                text-align: center;
                height:fit-content;
                font-size: 1vw;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                margin-left: 1vw;
                margin-top: 2.8124845488vh;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }

        .card1{
          // cursor: pointer;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            background: #FFFFFF ;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              width: 100%;
              height: 35vh;
              opacity: 1;
              //border:1px solid blue;
              background-image: url("../../assets/menage9.jpg");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign{
              padding: 5.2124845488vh 5.5597920277vw 5.1124845488vh 5.155401502vw;
              .Title1{
                text-align: center;
                height:fit-content;
                font-size: 1vw;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                margin-left: 10vw;
                margin-top: 2.8124845488vh;

                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }

      }
    }
    .screenmake4{
      padding:5.78739184177998vh 11.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: white;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:6.23733003708282vh;
        font-size: 1.7vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #FF6400;
        line-height: 4.23733003708282vh;

      }

      .Light{
        margin:0 auto;
        text-align: center;
        height: 8.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height:3.5vh;

        width: 66.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      }
      .grid{
        //margin-top:3.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        //column-gap:2.21317157712305vw ;
        .card{
          border-radius: 9px ;;
          // cursor: pointer;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            box-shadow: 5px 0 14px 0px #D4E4FF;
             height:fit-content;
            background: #FFFFFF ;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              width: 100%;
              height: 33.06056860321384vh;
              opacity: 1;
              //border:1px solid blue;
              background-image: url("../../assets/menage11.jpg");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign{
              padding: 5.2124845488vh 5.5597920277vw 5.1124845488vh 5.155401502vw;
              .Title1{
                text-align: center;
                height:fit-content;
                font-size: 1vw;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                text-align: justify;
                margin-top: 3.8124845488vh;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 400;
                line-height: 2.7194066749vh;
              }
            }
          }
        }

        .card1{
          // cursor: pointer;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            box-shadow: 5px 0 14px 0px #D4E4FF;
             height:fit-content;
            background: #FFFFFF ;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              width: 100%;
              height: 33.06056860321384vh;
              opacity: 1;
              background-image: url("../../assets/menage12.jpg");
              background-position: center;
              background-size:cover;
              //border:1px solid blue;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign{
              padding: 5.2124845488vh 5.5597920277vw 5.1124845488vh 5.155401502vw;
              .Title1{
                text-align: center;
                height:5.4vh;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1vw;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                text-align: justify;
                margin-top: 3.8124845488vh;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 400;
                line-height: 2.7194066749vh;
              }
            }
          }
        }

      }
    }
    .screenmake5{
      padding: 7.78739184177998vh 5.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: #212a3c;
      //background-color: #ebf1fd;
      .heighLight{
        color:white;
        text-align: center;
        height: 7.54017305315204vh;
        font-size: 1.7vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        //color: #2168DB;
        line-height: 2.71940667490729vh;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        .card{
          //// cursor: pointer;
          margin:0 auto;
          height:65vh;
          width: 86%;
          padding:0.98887515451174vh 0 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:64vh;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              width: fit-content;
              height:100%;
              opacity: 1;

              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 1.5248454883vh 1.5248454883vw;
              color: white;
              width: 30vw;
              margin:auto 0 auto 0;
              float: right;
              .title1{
                font-size: 1vw;
              }
              .title2{
                margin-top:25px;
                line-height: 4vh;
                font-size: 0.8243212016vw;
              }
              .success{
                display: grid;
                grid-template-columns: minmax(0,1fr) minmax(0,1fr);
                //column-gap: 1vw;
                margin-top: 2vh;
                .suc1{
                  width: 100%;
                  height: 8vh;
                  .title3{
                    text-align: center;
                    margin-top: 2vh;
                    font-size: 0.8243212016vw;
                  }

                }

              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height:68vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 550px;
        }
        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0  ;
          // cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight:800;
          font-size: 25px;
        }
      }
    }
  }

}
.totalmakeHoleModel{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    .background{
      //z-index:: 3;
      position: relative;
      width: 100%;
      height: 460px;
      padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 7.86077411900635vw;
      background-image: url("../../assets/gptModel71.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */
      .titlle1 {
        margin:50px auto;
        width: fit-content;
        text-align: center;
        height: 23px;
        font-size: 23px;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 500;
        color: #111111;
        line-height: 32px;
      }
      .titlle3{
        position: absolute;
        bottom:75px;
        left:50%;
        transform: translateX(-60%);
        font-size:12px;
         font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        height: 35px;
        color:white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }
      .title4{
        position: absolute;
        left:10%;
        bottom:-85px;
        margin: 35px auto 0 auto;
        width: 80%;
        height: fit-content;
        font-size: 12px;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #111111;
        line-height: 18px;
        border-radius: 15px;
        padding: 15px;
        background:white;
        border: #e8eefa 1px solid;
      }
    }
    .screenmake0{
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:90px 4.6875vw 20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 700;
        line-height: 20px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:12px;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .line{
        height: 25px;
        width: 4px;
        left: 50%;
        margin:0 auto;
        background-color: #B3D0FF;
      }
      .heightLight3{
        margin: 0 auto 0 auto;
        // cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size:14px;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 500;
        color: #2f6eff;
        line-height: 22px;
        text-align: center;
        border-radius: 9px;
        width: fit-content;
        padding: 10px 15px;
        height:40px;
        background: #B3D0FF;
        border: #B3D0FF 1px solid;
      }
      .line0{
        width: 245px;
        border-top-right-radius: 63px;
        border-top-left-radius: 63px;
        border-top:  #B3D0FF 4px solid;
        border-left:  #B3D0FF 4px solid;
        border-right:  #B3D0FF 4px solid;;
        height: 35px;
        margin:0 auto;
      }
      .grid0{
        width: 350px;
        margin:0 auto;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        column-gap: 5px;

        .card{
          position: relative;
          margin: 0 auto;
          text-align: center;
          .line{
            position: absolute;
            height: 35px;
            width: 4px;
            left: 50%;
            top:-35px;
            transform: translateX(-50%);
            background-color: #B3D0FF;
            margin:0 auto;
          }
          .line1{
            position: absolute;
            height: 75px;
            width: 4px;
            left: 50%;
            top:-75px;
            transform: translateX(-50%);
            background-color: #B3D0FF;
            margin:0 auto;
          }
          .heightLight3{
            margin:0 auto;

            // cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 8px;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 500;
            color: #2f6eff;
            line-height: 16px;
            text-align: center;
            border-radius: 9px;
            width: fit-content;
            padding: 5px 5px;
            height:45px;
            background: transparent;
            border: #B3D0FF 4px solid;
          }
        }


      }

    }
    .screenmake{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background:   #ebf1fd;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 21px;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #FF6400;
        line-height: 20px;

      }

      .Light{
        margin:15px 2vw 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:12px;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        //margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        .card{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:10px;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background:white;
            margin-bottom: 15px;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              text-align: center;
              border:1px solid #2537a8;
              height:155px;
              width: 100%;
              background-image: url("../../assets/menage2.jpg");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 10px 1.5248454883vw;
              .Title1{
                margin: 10px;
                text-align: center;
               height:fit-content;
                font-size:14px;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:12px;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 400;
                line-height: 22px;
              }
            }


          }
        }
        .card1{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:10px;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background:white;
            margin-bottom: 15px;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              text-align: center;
              border:1px solid #2537a8;
              height:155px;
              width: 100%;
              background-image: url("../../assets/menage3.jpg");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 10px 1.5248454883vw;
              .Title1{
                margin: 10px;
                text-align: center;
               height:fit-content;
                font-size:14px;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:12px;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 400;
                line-height: 22px;
              }
            }


          }
        }
      }
    }
    .screenmake2{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background:   white;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 21px;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #FF6400;
        line-height: 20px;

      }

      .Light{
        margin:19px 2vw 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:12px;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        //margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        .card{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:10px;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background:#ebf1fd;
            margin-bottom: 15px;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              text-align: center;
              border:1px solid #2537a8;
              height:285px;
              width: 100%;
              background-image: url("../../assets/menage4.jpg");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 10px 1.5248454883vw;
              .Title1{
                margin: 10px;
                text-align: center;
               height:fit-content;
                font-size:14px;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:12px;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 400;
                line-height: 22px;
              }
            }


          }
        }
        .card1{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:10px;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background:#ebf1fd;
            margin-bottom: 15px;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              text-align: center;
              border:1px solid #2537a8;
              height:285px;
              width: 100%;
              background-image: url("../../assets/menage5.jpg");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 10px 1.5248454883vw;
              .Title1{
                margin: 10px;
                text-align: center;
               height:fit-content;
                font-size:14px;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:12px;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 400;
                line-height: 22px;
              }
            }


          }
        }
      }
    }
    .screenmake3{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background:   #ebf1fd;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 21px;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #FF6400;
        line-height: 20px;

      }

      .Light{
        margin:19px 2vw 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:12px;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        //margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        .card{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:10px;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background:white;
            margin-bottom: 15px;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              text-align: center;
              border:1px solid #2537a8;
              height:225px;
              width: 100%;
              background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 10px 1.5248454883vw;
              .Title1{
                margin: 10px;
                text-align: center;
               height:fit-content;
                font-size:14px;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:12px;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 400;
                line-height: 22px;
              }
            }


          }
        }
        .card1{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:10px;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background:white;
            margin-bottom: 15px;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              text-align: center;
              border:1px solid #2537a8;
              height:185px;
              width: 100%;
              background-image: url("../../assets/menage7.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 10px 1.5248454883vw;
              .Title1{
                margin: 10px;
                text-align: center;
               height:fit-content;
                font-size:14px;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:12px;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 400;
                line-height: 22px;
              }
            }


          }
        }
      }
    }
    .screenmake4{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background:   white;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 21px;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #FF6400;
        line-height: 20px;

      }

      .Light{
        margin:19px 2vw 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:12px;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        //margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        .card{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:10px;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background:#ebf1fd;
            margin-bottom: 15px;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              text-align: center;
              border:1px solid #2537a8;
              height:185px;
              width: 100%;
              background-image: url("../../assets/menage8.jpg");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 10px 1.5248454883vw;
              .Title1{
                margin: 10px;
                text-align: center;
               height:fit-content;
                font-size:14px;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:12px;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 400;
                line-height: 22px;
              }
            }


          }
        }
        .card1{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:10px;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background:#ebf1fd;
            margin-bottom: 15px;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              text-align: center;
              border:1px solid #2537a8;
              height:185px;
              width: 100%;
              background-image: url("../../assets/menage9.jpg");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 10px 1.5248454883vw;
              .Title1{
                margin: 10px;
                text-align: center;
               height:fit-content;
                font-size:14px;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:12px;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 400;
                line-height: 22px;
              }
            }


          }
        }
      }
    }
    .screenmake5{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: black;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        color: white;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 700;
        line-height: 20px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:12px;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          left:-55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          right: -55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .card{
          cursor: pointer;
          width: 98%;
          margin:0 auto;
          height:fit-content ;
          padding:0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;
          .back{
            border-radius: 9px;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:400px;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              width: 100%;
              height:fit-content;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 5px 20px 25px 20px;
              color: white;
              width:100%;
              float: right;
              .title1{
                margin-top:10px;
                font-size:12px;
              }
              .title2{
                margin-top: 15px;
                line-height: 22px;
                font-size:12px;
                ::v-deep li{
                  margin-top:5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height:400px !important;

        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__arrow {
          display: block;
          //border: 1px solid white;
          outline: -5px;
          padding: 0;
          margin: -90px -10px  ;
          cursor: pointer;
          border-radius: 0;
          width: 25px;
          height: 48px;
          background: rgba(245, 246, 250, 0.8);
          color: #131313;
          position: absolute;
          top: 50%;
          //z-index:: 100;
          transform: translateY(-50%);
          text-align: center;
          font-size:12px;
        }
      }
    }
  }

}
</style>
