<template>
  <div>
    <div v-if="model===false">
      <div class="total" ref="total">
        <div class="topback"  :style="{ height: '7vh', backgroundColor: scrollback ? 'rgb(196,196,196)' : 'transparent' ,position:'fixed'}">
          <headtip @showDialog="showPartner"></headtip>
        </div>
        <div class="screen1">
          <!--      logo  you气通GPT社区 合作伙伴  登录-->

          <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
          <div class="layer2">
            <div class="title1">Process-related GPTs</div>
            <div class="title2">Process-related GPTs，which not only help to improve the development effect and recovery rate, but also reduce the risk loss during the development process to ensure the safe and stable development of Oil & Gas fields.</div>
            <div class="title4">* The generalization ability of Oil & Gas AI models is limited, and may need to be adapted and optimized according to specific scenarios and needs in practical applications.
              <br>①&nbsp;Process-related services are provided by Antonoil's offline technical personnel<br>②&nbsp;GPT development services are provided by Antonoil's AI professionals</div>

            <div class="titlle3"   @click="clickConsult('Oil & Gas Generic AI')">
              Product Inquiry
            </div>
          </div>
          <!--      平台大图-->
          <div class="layer3"></div>
        </div>
        <div class="screenmake2">
          <div class="heighLight">   <span>Process-related GPTs Services</span></div>
          <div class="grid">
            <div class="card1" @click="detail1()">
              <div class="back">
                <div class="imgDesign">
                  <img src="@/assets/gpt23.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1" style="display: flex;justify-content: center;align-items: center"> Intelligent Geosteering</div>
                  <div class="Title2">
                    During the drilling process, a variety of geological and engineering parameters are measured to evaluate the geological parameters of the formation being drilled in real time, and based on the evaluation results, the downhole drilling tools are precisely controlled to hit the best geological targets.
                  </div>
                  <div class="backconsult">
                    View Details
                  </div>
                  <div class="backconsultArrow">
                    <i style="font-size:14px;margin-left: 15px;" class="el-icon-right"></i>
                  </div>
                </div>
              </div>


            </div>
            <div class="card1" @click="detail2()">
              <div class="back">
                <div class="imgDesign">
                  <img src="@/assets/gpt33.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1">  EUR Prediction and Parameter Reversal Platform Based on Fracturing Parameters</div>
                  <div class="Title2">The factors affecting the fracturing wells need to be analyzed to determine the production increase effect of fracturing measures and to obtain the maximum return with the smallest possible investment. Sensitivity coefficients are introduced based on the theoretical reservoir model and the actual reservoir model of the oil field, and the factors influencing the production increase effect of fracturing wells are analyzed and judged by comparing the size of the absolute value of the sensitivity coefficients.
                  </div>
                  <div class="backconsult">
                    View Details
                  </div>
                  <div class="backconsultArrow">
                    <i style="font-size:14px;margin-left: 15px;" class="el-icon-right"></i>
                  </div>
                </div>
              </div>


            </div>
            <div class="card1" @click="detail3()">
              <div class="back">
                <div class="imgDesign">
                  <img src="@/assets/gpt43.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1">  Intelligent Injection And Recovery Decision-making</div>
                  <div class="Title2">In order to improve the efficiency of water injection and development, the construction personnel need to combine the intelligent layered water injection system with the intelligent layered oil recovery system, through the injection and recovery linkage, real-time monitoring of intelligent layered water injection and intelligent layered oil recovery, dynamic comparison and analysis of water injection, liquid production, water content, pressure, etc., in each layer, to improve the pertinence and flexibility of the reservoir regulation and control, and to improve the implementation rate of the water injection program.</div>
                  <div class="backconsult">
                    View Details
                  </div>
                  <div class="backconsultArrow">
                    <i style="font-size:14px;margin-left: 15px;" class="el-icon-right"></i>
                  </div>
                </div>
              </div>


            </div>

            <div class="card" @click="detail4()" style="cursor:pointer;">
              <div class="back">
                <div class="imgDesign">
                  <img src="@/assets/gpt53.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1" style="display: flex;justify-content: center;align-items: center">  Oil & Gas Field Video Surveillance</div>
                  <div class="Title2">Realize real-time capture, intelligent identification and active warning of on-site violations, but also significantly save supervisory human resources, enhance the quality of supervision and effective prevention and control of safety risks, the implementation of the electronic sandbox to perceive the risks and optimize the deployment of personnel, while objectively improving safety awareness and standardizing safety behavior.</div>
                  <div class="backconsult" >
                    View Details
                  </div>
                  <div class="backconsultArrow" >
                    <i style="font-size:14px;margin-left: 15px;" class="el-icon-right"></i>
                  </div>
                </div>
              </div>
            </div>

              <div class="card" @click="detail5()" style="cursor:pointer;">
                <div class="back">
                  <div class="imgDesign">
                    <img src="@/assets/gpt15.png">
                  </div>
                  <div class="titleDesign">
                    <div class="Title1"> Drilling Speed Prediction And Optimization</div>
                    <el-tooltip  placement="bottom" effect="light" popper-class="custom-tooltip" :class="'custom-tooltip'">
                      <div style="width: 50vw ;font-size: 0.8243212016vw; line-height: 3.194066749vh;" slot="content">
                        In order to carry out better exploration and development, improve drilling efficiency, reduce drilling cost and reduce drilling risk, one of the most effective methods is to increase the mechanical drilling speed. Optimization of mechanical drilling speed is one of the urgent problems to be solved in drilling engineering, and it is also a key project to be tackled in drilling engineering nowadays. In the drilling process, accurate prediction of the mechanical drilling speed of drilling wells can, on the one hand, monitor and prevent the occurrence of drilling accidents in advance, reduce the risk of drilling wells, and improve the safety of drilling wells; on the other hand, it can provide a strong support for the optimization method of drilling wells based on the real-time prediction, which can make the cycle time of drilling wells reduced and the cost lowered.
                      </div>
                      <div class="Title2">
                        In order to carry out better exploration and development, improve drilling efficiency, reduce drilling cost and reduce drilling risk, one of the most effective methods is to increase the mechanical drilling speed. Optimization of mechanical drilling speed is one of the urgent problems to be solved in drilling engineering, and it is also a key project to be tackled in drilling engineering nowadays. In the drilling process, accurate prediction of the mechanical drilling speed of drilling wells can, on the one hand, monitor and prevent the occurrence of drilling accidents in advance, reduce the risk of drilling wells, and improve the safety of drilling wells; on the other hand, it can provide a strong support for the optimization method of drilling wells based on the real-time prediction, which can make the cycle time of drilling wells reduced and the cost lowered.
                      </div>

                    </el-tooltip>
                    <div class="backconsult" >
                      View Details
                    </div>
                    <div class="backconsultArrow" >
                      <i style="font-size:14px;margin-left: 15px;" class="el-icon-right"></i>
                    </div>
                  </div>
                </div>
              </div>



            <div class="card" @click="detail6()" style="cursor:pointer;">
              <div class="back">
                <div class="imgDesign">
                  <img src="@/assets/gpt34.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1"> Abnormal Formation Pressure Condition Alarm</div>


                  <el-tooltip  placement="bottom" effect="light" popper-class="custom-tooltip" :class="'custom-tooltip'">
                    <div style="width: 50vw ;font-size: 0.8243212016vw; line-height: 3.194066749vh;" slot="content">
                      Fracturing sand plugging is a phenomenon that occurs when fracturing proppant collects and clogs the bottom of a well or a fracture, increasing the construction pressure. If sand plugging is not dealt with in a timely manner, it may lead to fracturing failure, waste of resources, additional sand flushing to unblock the well or overhauling, which increases the cost, and in serious cases, the well may be scrapped and lead to injuries or fatalities. The method is based on time loop neural network, which can handle and predict the risk of sand plugging with relatively long intervals and delays in the time series, and collects real-time pumping data from instrument trucks and fracturing design parameters through the collection terminal at the fracturing site, and transmits them to the cloud service through the network, so as to realize the monitoring of the front and back fracturing construction and the early warning of the risk of sand plugging.
                    </div>
                    <div class="Title2">
                      Fracturing sand plugging is a phenomenon that occurs when fracturing proppant collects and clogs the bottom of a well or a fracture, increasing the construction pressure. If sand plugging is not dealt with in a timely manner, it may lead to fracturing failure, waste of resources, additional sand flushing to unblock the well or overhauling, which increases the cost, and in serious cases, the well may be scrapped and lead to injuries or fatalities. The method is based on time loop neural network, which can handle and predict the risk of sand plugging with relatively long intervals and delays in the time series, and collects real-time pumping data from instrument trucks and fracturing design parameters through the collection terminal at the fracturing site, and transmits them to the cloud service through the network, so as to realize the monitoring of the front and back fracturing construction and the early warning of the risk of sand plugging.
                    </div>
                  </el-tooltip>
                  <div class="backconsult" >
                    View Details
                  </div>
                  <div class="backconsultArrow" >
                    <i style="font-size:14px;margin-left: 15px;" class="el-icon-right"></i>
                  </div>

                </div>
              </div>
            </div>


            <div class="card3">
              <div class="back">
                <div class="imgDesign">
                  <img src="@/assets/gpt25.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1" style="display: flex;justify-content: center;align-items: center"> Drilling Risk Warning</div>

                  <el-tooltip  placement="bottom" effect="light" popper-class="custom-tooltip" :class="'custom-tooltip'">
                    <div style="width: 50vw ;font-size: 0.8243212016vw; line-height: 3.194066749vh;" slot="content">
                      Drilling project has a lot of risks, is a large investment, high technical requirements and extremely hidden underground engineering, there are a lot of complexity and uncertainty factors in the drilling process, so the drilling construction often encountered some unexpected problems, which makes the drilling project in all stages of the existence of different degrees and forms of huge risks, based on the neural network algorithm to establish a real-time risk warning system model, when the real-time drilling parameter values higher than the risk safety threshold system alarm, followed by accident diagnosis, and provide an effective solution. When the real-time drilling parameter value is higher than the risk safety threshold, the system alarms, and then carries out accident diagnosis and provides effective solutions.
                    </div>
                    <div class="Title2">
                      Drilling project has a lot of risks, is a large investment, high technical requirements and extremely hidden underground engineering, there are a lot of complexity and uncertainty factors in the drilling process, so the drilling construction often encountered some unexpected problems, which makes the drilling project in all stages of the existence of different degrees and forms of huge risks, based on the neural network algorithm to establish a real-time risk warning system model, when the real-time drilling parameter values higher than the risk safety threshold system alarm, followed by accident diagnosis, and provide an effective solution. When the real-time drilling parameter value is higher than the risk safety threshold, the system alarms, and then carries out accident diagnosis and provides effective solutions.
                    </div>
                  </el-tooltip>

                </div>
              </div>

            </div>
            <div class="card3">
              <div class="back">
                <div class="imgDesign">
                  <img src="@/assets/gpt44.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1" style="display: flex;justify-content: center;align-items: center">Artificial Lift Optimization</div>

                  <el-tooltip  placement="bottom" effect="light" popper-class="custom-tooltip" :class="'custom-tooltip'">
                    <div style="width: 50vw ;font-size: 0.8243212016vw; line-height: 3.194066749vh;" slot="content">
                      The role of artificial lift is to extract Oil & Gas to the surface, which is an important part in crude oil extraction. Differences in oil wells in terms of well structure, production, and fluid properties promote the diversification of artificial lift technology. Intelligent lift optimization combined with real-time wellhead monitoring solutions can maximize well revenue by slowing down the rate of decline in oilfield production.
                    </div>
                    <div class="Title2">
                      The role of artificial lift is to extract Oil & Gas to the surface, which is an important part in crude oil extraction. Differences in oil wells in terms of well structure, production, and fluid properties promote the diversification of artificial lift technology. Intelligent lift optimization combined with real-time wellhead monitoring solutions can maximize well revenue by slowing down the rate of decline in oilfield production.
                    </div>
                  </el-tooltip>


                </div>
              </div>


            </div>
            <div class="card3">
              <div class="back">
                <div class="imgDesign">
                  <img src="@/assets/gpt45.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1"> Intelligent Control Of Machine Mining</div>
                  <el-tooltip  placement="bottom" effect="light" popper-class="custom-tooltip" :class="'custom-tooltip'">
                    <div style="width: 50vw ;font-size: 0.8243212016vw; line-height: 3.194066749vh;" slot="content">
                      Based on IOT data, combined with big data technology, working condition diagnosis technology and machine mining efficiency optimization technology, the potential value of massive data can be deeply explored, which can realize the overall control of the operating condition and energy consumption of dilute oil machine mining wells.
                    </div>
                    <div class="Title2">
                      Based on IOT data, combined with big data technology, working condition diagnosis technology and machine mining efficiency optimization technology, the potential value of massive data can be deeply explored, which can realize the overall control of the operating condition and energy consumption of dilute oil machine mining wells.
                    </div>
                  </el-tooltip>


                </div>
              </div>


            </div>

            <div class="card2">
              <div class="back">
                <div class="imgDesign">
                  <img src="@/assets/gptModel53.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1"> Optimization Of Energy Consumption In Oil & Gas Fields</div>

                  <el-tooltip  placement="bottom" effect="light" popper-class="custom-tooltip" :class="'custom-tooltip'">
                    <div style="width: 50vw ;font-size: 0.8243212016vw; line-height: 3.194066749vh;" slot="content">
                      Promote energy saving and consumption reduction in the four major production systems, namely, machine mining, water injection, gathering and transportation, and electric power, to enhance quality and efficiency. Through "shutting down, stopping, merging, transferring and reducing", as well as measures such as wellbore process optimization, balancing of wells for mechanical extraction and opening of low-producing wells, etc., the company realizes power saving in the mechanical extraction system, and implements measures such as reducing the capacity of power distribution in the electric power system and replacing energy-saving electric motors, etc., to save electric energy consumption.
                    </div>
                    <div class="Title2">
                      Promote energy saving and consumption reduction in the four major production systems, namely, machine mining, water injection, gathering and transportation, and electric power, to enhance quality and efficiency. Through "shutting down, stopping, merging, transferring and reducing", as well as measures such as wellbore process optimization, balancing of wells for mechanical extraction and opening of low-producing wells, etc., the company realizes power saving in the mechanical extraction system, and implements measures such as reducing the capacity of power distribution in the electric power system and replacing energy-saving electric motors, etc., to save electric energy consumption.
                    </div>
                  </el-tooltip>


                </div>
              </div>

            </div>
            <div class="card2">
              <div class="back">
                <div class="imgDesign">
                  <img src="@/assets/gpt55.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1"> Production Scheduling Strategy Optimization</div>
                  <el-tooltip  placement="bottom" effect="light" popper-class="custom-tooltip" :class="'custom-tooltip'">
                    <div style="width: 50vw ;font-size: 0.8243212016vw; line-height: 3.194066749vh;" slot="content">
                      The process of oil extraction and processing is relatively complex, with many processes and a wide range of scope, and the daily production process of oilfield enterprises needs to be arranged in a planned manner, which requires the use of AI technology to realize the role of intelligent operation scheduling in the oilfield, planning the work of oilfield enterprises, and ensuring that the enterprise's production and construction are carried out in an orderly and efficient manner.
                    </div>
                    <div class="Title2">
                      The process of oil extraction and processing is relatively complex, with many processes and a wide range of scope, and the daily production process of oilfield enterprises needs to be arranged in a planned manner, which requires the use of AI technology to realize the role of intelligent operation scheduling in the oilfield, planning the work of oilfield enterprises, and ensuring that the enterprise's production and construction are carried out in an orderly and efficient manner.
                    </div>
                  </el-tooltip>



                </div>
              </div>


            </div>
            <div class="card2">
              <div class="back">
                <div class="imgDesign">
                  <img src="@/assets/gpt64.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1"> Active Early Warning Of Equipment Failure</div>

                  <el-tooltip  placement="bottom" effect="light" popper-class="custom-tooltip" :class="'custom-tooltip'">
                    <div style="width: 50vw ;font-size: 0.8243212016vw; line-height: 3.194066749vh;" slot="content">
                      The online monitoring system can analyze the working status of the equipment, predict equipment failures and provide timely alarms to avoid downtime and prolonged maintenance caused by equipment failures.
                    </div>
                    <div class="Title2">
                      The online monitoring system can analyze the working status of the equipment, predict equipment failures and provide timely alarms to avoid downtime and prolonged maintenance caused by equipment failures.
                    </div>
                  </el-tooltip>

                </div>
              </div>

            </div>
          </div>

        </div>
        <div class="screenmake3">
          <div class="heighLight">Successful Cases</div>
          <div class="grid">
            <el-carousel indicator-position="outside" arrow="always" interval="5000">
              <el-carousel-item v-for="item in success" :key="item">
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img :src="item.img"/>
                    </div>
                    <div class="titleDesign">
                      <div class="title1">{{ item.title1 }}</div>
                      <div class="title2" v-html="item.title2"></div>
                    </div>
                  </div>


                </div>


              </el-carousel-item>
            </el-carousel>
          </div>

        </div>
        <bottomTip></bottomTip>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
               <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalModel">
        <div class="screen1">
          <headtip @showDialog="showPartner"></headtip>
          <!--      logo  you气通GPT社区 合作伙伴  登录-->

          <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
          <div class="layer2">
            <div class="title1">设计GPTs</div>
            <div class="title2">基于油藏地质知识智能解释处理、为智能钻井压裂等应用场景提供油藏地质设计</div>


          </div>
          <div class="titlle3"   @click="clickConsult('Oil & Gas Generic AI')">
            Product Inquiry
          </div>
          <div class="title4">*油气人工智能模型的泛化能力有限，实际应用中可能需根据具体场景和需求进行适配和优化。如您有合作意向或Product Inquiry，请随时与我们联系。</div>
          <!--      平台大图-->
          <div class="layer3"></div>
        </div>
        <div class="screenmake0">
          <div class="heighLight">油藏地质模型架构</div>
          <div class="heightLight2">以油藏地质研究及各个应用场景数据为中心，通过解释脑形成知识成果，智能优化油藏地质研究成果，从而达到油藏地质知识智能解释处理、为智能钻井压裂等应用场景提供油藏地质知识的目的。</div>
          <div class="grid">
            <div class="card1">
              <div class="back">
                <div class="imgDesign">
                  <img src="@/assets/gptModel12.png">
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="screenmake2">
          <div class="heighLight">   <span>油藏地质GPTs</span></div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">    <span style="background-color: #FF6400;border-radius: 50%; width:16px;font-size:16px;height:16px;display: flex;justify-content: center;align-items: center;color: white;margin-top: 4px">1</span>&nbsp;储层识别/岩性识别</div>
                  <div class="Title2">
                    油气储层是在油气勘探工程中，地下存在油气聚集的岩层区域。储层的特征包括了岩性、物性、含油气性等方面，这也是储层预测的主要方向。储层岩性是描述储层矿物质组成成分的主要特征，反映了岩层的储藏性能和储层特征，常用参数包括储层岩石物理结构、分布范围、储层顶界面构造形态、储层厚度等。综合储层特征，实现对油气富集区域即甜点进行预测，为油气钻探指明位置和开采方案。

                  </div>
                  <div class="backconsult">
                    View Details
                  </div>
                  <div class="backconsultArrow">
                    <i style="font-size:14px;margin-left: 15px;" class="el-icon-right"></i>
                  </div>
                </div>
              </div>


            </div>
            <div class="card1">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">     <span style="background-color: #FF6400;border-radius: 50%; width:16px;font-size:16px;height:16px;display: flex;justify-content: center;align-items: center;color: white;margin-top: 4px">2</span>&nbsp;压裂设计方案参数优化</div>
                  <div class="Title2">地质与工程相结合，根据新井实钻轨迹及不同河道砂体的叠置和接触关系，制定个性化的方案，优化分段、分簇参数，强化裂缝复杂程度，最大限度的提高裂缝控制体积、释放有效砂体产能。同时实时计算井底压力及净压力，根据净压力变化情况，及时判断裂缝延伸情况，预判砂堵风险，实时优化调整施工参数，确保单井压裂控制体积最大。</div>
                </div>
              </div>


            </div>
            <div class="card2">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">     <span style="background-color: #FF6400;border-radius: 50%; width:16px;font-size:16px;height:16px;display: flex;justify-content: center;align-items: center;color: white;margin-top: 4px">3</span>&nbsp;地质甜点/工程甜点识别</div>
                  <div class="Title2">油气勘探开发的过程中，会揭示大面积的含油气区及大段的含油气层段，但是往往其中一部分是具有当前经济、技术条件下具有较好开发效益的部分，称之为“甜点”。以实测地质参数、工程参数为输入，利用神经网络模拟技术，通过大数据智能分析地质参数与弹性参数的相关关系，解译出相应的甜点评价参数。</div>
                </div>
              </div>


            </div>
          </div>

        </div>
        <div class="screenmake3">
          <div class="heighLight">Successful Cases</div>
          <div class="grid">
            <el-carousel indicator-position="outside" arrow="always" interval="5000">
              <el-carousel-item v-for="item in 1" :key="item">
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img src="@/assets/gpt16.png">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">XX地区页岩气井实施方案优化、实时跟踪及综合效果评价</div>
                      <div class="title2">1.精细三维地应力预测及裂缝检测</div>
                      <div class="title2">2.新井精细跟踪服务</div>
                      <div class="title2">3.老井生产动态跟踪分析及评价</div>
                    </div>
                  </div>


                </div>


              </el-carousel-item>
            </el-carousel>
          </div>

        </div>
        <bottomTip></bottomTip>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
               <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>



</template>


<script>

import headtip from "@/views/headTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import consultTip from "@/components/consultTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import {shareUrl} from "@/utils/vxshare";

export default {

  name: "",

  props: [],

  components: {bottomTip, consultTip, PartnerTip, headtip},

  data() {

  return {
      title:'',
      pageTitle: 'Process GPTs_Oil and gas industry process large model artificial intelligence application-Oil and Gas Communication GPT',
      pageDescription: 'The Oil and Gas GPT Cloud Platform provides process-oriented GPTs, which not only contribute to enhancing development effectiveness and recovery rates but also minimize risk and losses during the development process, ensuring the safe and stable exploitation of Oil & Gas fields.',
      pageKeywords: 'Process GPTs, process GPTs, intelligent geosteering, intelligent injection and production decision-making, Oil & Gas field video monitoring, drilling risk warning, artificial lift optimization, mechanical production intelligent management and control, Oil & Gas field energy consumption optimization, Oil & Gas communication, Oil & Gas communication GPT, Oil & Gas communication GPT cloud Platform, Oil & Gas channel large model, Oil & Gas channel GPT large model',
    scrollback:false,
      model:false,
      dialogconsult:false,
      dialogVisible:false,
      success:[
        {
          img:require("@/assets/gpt26.png"),
          title1:"XX Deep Coalbed Methane Drilling Project",
          title2:"Anton entered the construction of XX coal project since the third quarter of 2022, with 2 rigs (50LDB with top drive) involved, and constructed a total of 10 deep CBM horizontal wells, with an average depth of 3,530m and a horizontal section length of 1,000-1,500m, with an average drilling cycle of 30.48 days (the shortest of which is 27.58 days), and a number of single wells or openings have set a record for the block, which is highly recognized by the client.",
        },
        {
          img:require("@/assets/gpt46.png"),
          title1:"Intelligent Oil Recovery Robot Service",
          title2:"        <li style=\"margin-top: 1vh\">For the pumping machine wells, real-time data such as pressure and temperature, electrical parameters, schematic diagrams, dynamic liquid level, etc. are collected and inputted into the process brain of the intelligent oil recovery robot, which outputs the optimized production and operation parameters, reduces the number of ineffective strokes, and puts an end to empty pumping, so that the pumping machine operates under the optimal working conditions and the wells can maintain a stable dynamic liquid level, thus achieving a balance between supply and extraction of the wells, and achieving a steady and increased production of the oil wells.</li>\n" +
              "                        <li style=\"margin-top: 0.5vh\">Application performance: The intelligent oil recovery robot has been tested in Dagang Oilfield, North China Oilfield, Shengli Oilfield, Zhongyuan Oilfield, Jianghan Oilfield and Yanchang Petroleum, and has been running stably and achieved good application results.</li>",
        },
        {
          img:require("@/assets/gpt56.png"),
          title1:"XX Petroleum Gas Dehydrocarbonization And Pressurization Intelligent Field Station Project",
          title2:"<span style=\"margin-top: 3vh;font-weight: bold\">Equipment Detection, Accurate And Real-time:</span><br>  " +
              "According to the intelligent target demand, supporting efficient new technology and new products, through on-site integration and application, all-weather real-time monitoring of equipment operating conditions.<br> <span style=\"font-weight: bold\">Program Results：</span>  <li>Reduce maintenance costs: timely detection of potential failures and problems, more preventive and accurate maintenance, reduce maintenance costs;</li>\n" +
              "                        <li style=\"margin-top: 0.5vh\">Enhance equipment reliability: Reduce equipment failures and downtime, increase equipment life;</li>\n" +
              "                        <li style=\"margin-top: 0.5vh\">Optimize resource utilization: Real-time monitoring and data analysis help to better utilize resources, including energy, raw materials and human resources, and reduce costs.</li>",
        },
      ]
    }

  },

  methods: {
    clickConsult(item){

      console.log(item)
      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
    detail1(){
      window.open("/ground", "_blank");
    },
    detail2(){
      window.open("/frack", "_blank");
    },
    detail3(){
      window.open("/decision", "_blank");
    },
    detail4(){
      window.open("/monitor", "_blank");
    },
    detail5(){
      window.open("/drilling", "_blank");
    },
    detail6(){
      window.open("/alarm", "_blank");
    },

    showPartner() {
      this.dialogconsult = true;
    },

    hideDialog() {
      this.dialogVisible = false;
    },
    hide() {
      this.dialogconsult = false;
    },
    handleScroll() {
      if (this.model === false) {
        this.scrollHeight = this.$refs.total.scrollTop;
      } else {
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if (this.scrollHeight < 100) {
        this.scrollTrue = true
      } else {
        this.scrollTrue = false
      }
      if (this.scrollHeight < 50) {
        this.scrollback = false
      } else {
        this.scrollback = true
      }
      console.log("gaodu", this.scrollHeight < 100)
    },
  },
  //进入该页面时，用之前保存的滚动位置赋值
  beforeDestroy() {
    // 移除事件监听器
    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);

    document.title = this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }
  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: 'Process GPTs_Oil and gas industry process large model artificial intelligence application-Oil and Gas Communication GPT',
        pageDescription: 'The Oil and Gas GPT Cloud Platform provides process-oriented GPTs, which not only contribute to enhancing development effectiveness and recovery rates but also minimize risk and losses during the development process, ensuring the safe and stable exploitation of Oil & Gas fields.',
        pageKeywords: 'Process GPTs, process GPTs, intelligent geosteering, intelligent injection and production decision-making, Oil & Gas field video monitoring, drilling risk warning, artificial lift optimization, mechanical production intelligent management and control, Oil & Gas field energy consumption optimization, Oil & Gas communication, Oil & Gas communication GPT, Oil & Gas communication GPT cloud Platform, Oil & Gas channel large model, Oil & Gas channel GPT large model',

      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>


<style type="text/scss" lang="scss" scoped>
.total {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  position: relative;
  .topback{
    position: fixed;
    z-index:999999999999;
    width: 100vw;
    padding: 0 0 0 2.86077411900635vw;
  }
  .screen1 {
    z-index: 3;
    position: relative;
    width: 100%;
    height: 74vh;
    padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 6.86077411900635vw;
    background-image: url("../../assets/gpt21.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    /* Add any additional styling as needed */

    .layer2 {
      margin-top: 10vh;
      margin-left:2vw;

      .title1 {
        width: fit-content;
        height: fit-content;
        font-size: 2vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #2f6eff;
        line-height: 4.23733003708282vh;
      }

      .title2 {
        margin-top: 2.82076637824475vh;
        width: 36vw;
        height: fit-content;
        //text-align: justify;
        font-size: 0.9597920277vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #2f6eff;
        line-height: 3.5vh;
      }
      .title4 {
        margin-top: 1.82076637824475vh;
        width: 36vw;
        //text-align: justify;
        font-size: 0.8287117273vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        line-height:3.5vh;
      }
      .titlle3{
        position: absolute;
        bottom: 10.78739184177998vh;
        margin-top: 2.82076637824475vh;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #ffffff;

        line-height: 2.3374536465vh;
        text-align: center;
        border-radius: 53px;
        width: fit-content;
        padding: 2.4624227441vh 1.1989601386vw;
        height: 4.5624227441vh;
        background: #2f6eff;
        border: #2f6eff 1px solid;


      }
      .titlle3:hover{

        color: #2168DB;
        border: #2168DB 1px solid;
        background: rgba(255, 255, 255, 0.5);
      }
    }

    //.layer3 {
    //  position: absolute;
    //  top: 5.06798516687268vh;
    //  right: 11.61178509532062vw;
    //  width: 47.42923165800116vw;
    //  height: 72.80593325092707vh;
    //  background-image: url("../assets/GPTplant.png");
    //  background-size: 100% auto;
    //  background-position: center;
    //  background-repeat: no-repeat;
    //  background-color: transparent;
    //  opacity: 1;
    //}
  }
  .screenmake2{
    padding: 7.78739184177998vh 11.09763142692085vw;
    width: 100%;
    height: fit-content;
    background: white;
    .heighLight{
      display: flex;
      justify-content: center;
      text-align: center;
      height:8.23733003708282vh;
      font-size: 1.7vw;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 500;
      color: #111111;
      line-height: 4.23733003708282vh;
    }


    .grid{
      margin-top:2.78739184177998vh ;
      display: grid;
      grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
      column-gap:15px ;
      row-gap: 20px;
      .card1{
        cursor: pointer;
        width: fit-content;

        height:fit-content ;
        padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
        opacity: 1;
        .back{
          border-radius: 9px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          min-height: 55vh;
          overflow:hidden;
          background: #ebf1fd;;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height:23vh;
            opacity: 1;
            background-image: url("../../assets/gpt13.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin: 26vh 1.8597920277vw 0 1.8597920277vw;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
              overflow: hidden;
              text-overflow: ellipsis;
              justify-content: center;
              text-align: center;
              height: 5.4vh;
              font-size: 1vw;
               font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 700;
              color:#111111;
              line-height: 2.7194066749vh;
            }
            .Title2{
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 5; /* 限制在一个块元素显示的文本的行数 */
              overflow: hidden;
              text-overflow: ellipsis;
              height: 16vh;
              margin: 1vh 1.8597920277vw 30px 1.8597920277vw;
              ////text-align: justify;
              font-size: 0.8243212016vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              line-height: 3.194066749vh;
            }
            .backconsult{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);

              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
        .back:hover{
          .imgDesign {
            transform: scale(1.05);
          }
          .backconsult{
            font-weight: bold;
          }

        }
      }
      .card1:hover{
        .imgDesign {
          transform: scale(1.05);
        }
        .backconsult{
          font-weight: bold;
        }
      }
      .card{
        //cursor: pointer;
        width: fit-content;

        height:fit-content ;
        padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
        opacity: 1;
        .back{
          border-radius: 9px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          min-height: 55vh;
          overflow:hidden;
          background: #ebf1fd;;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height:23vh;
            opacity: 1;
            background-image: url("../../assets/gpt13.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin: 26vh 1.8597920277vw 0 1.8597920277vw;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
              overflow: hidden;
              text-overflow: ellipsis;
              justify-content: center;
              text-align: center;
              height: 5.4vh;
              font-size: 1vw;
               font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 700;
              color:#111111;
              line-height: 2.7194066749vh;
            }
            .Title2{
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 5; /* 限制在一个块元素显示的文本的行数 */
              overflow: hidden;
              text-overflow: ellipsis;
              height: 16vh;
              margin: 1vh 1.8597920277vw 30px 1.8597920277vw;
              ////text-align: justify;
              font-size: 0.8243212016vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              line-height: 3.194066749vh;
            }
            .backconsult{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);

              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
        .back:hover{
          .imgDesign {
            transform: scale(1.05);
          } .backconsult{
              font-weight: bold;
            }

        }
      }
      .card:hover{
        .imgDesign {
          transform: scale(1.05);
        } .backconsult{
            font-weight: bold;
          }
      }
      .card2{
        //cursor: pointer;
        width: fit-content;

        height:fit-content ;
        padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
        opacity: 1;
        .back{
          border-radius: 9px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          min-height: 52vh;
          overflow:hidden;
          background: #ebf1fd;;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height:23vh;
            opacity: 1;
            background-image: url("../../assets/gpt13.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin: 26vh 1.8597920277vw 0 1.8597920277vw;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
              overflow: hidden;
              text-overflow: ellipsis;
              justify-content: center;
              text-align: center;
              height: 5.4vh;
              font-size: 1vw;
               font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 700;
              color:#111111;
              line-height: 2.7194066749vh;
            }
            .Title2{
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 5; /* 限制在一个块元素显示的文本的行数 */
              overflow: hidden;
              text-overflow: ellipsis;
              height: 16vh;
              margin: 1vh 1.8597920277vw 30px 1.8597920277vw;
              ////text-align: justify;
              font-size: 0.8243212016vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              line-height: 3.194066749vh;
            }
            .backconsult{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);

              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
        .back:hover{
          .imgDesign {
            transform: scale(1.05);
          }

        }
      }
      .card2:hover{
        .imgDesign {
          transform: scale(1.05);
        } .backconsult{
            font-weight: bold;
          }
      }
      .card3{
        //cursor: pointer;
        width: fit-content;

        height:fit-content ;
        padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
        opacity: 1;
        .back{
          border-radius: 9px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          min-height: 52vh;
          overflow:hidden;
          background: #ebf1fd;;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height:23vh;
            opacity: 1;
            background-image: url("../../assets/gpt13.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin: 26vh 1.8597920277vw 0 1.8597920277vw;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
              overflow: hidden;
              text-overflow: ellipsis;
              justify-content: center;
              text-align: center;
              height: 5.4vh;
              font-size: 1vw;
               font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 700;
              color:#111111;
              line-height: 2.7194066749vh;
            }
            .Title2{
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 5; /* 限制在一个块元素显示的文本的行数 */
              overflow: hidden;
              text-overflow: ellipsis;
              height: 16vh;
              margin: 1vh 1.8597920277vw 30px 1.8597920277vw;
              ////text-align: justify;
              font-size: 0.8243212016vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              line-height: 3.194066749vh;
            }
            .backconsult{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);

              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
        .back:hover{
          .imgDesign {
            transform: scale(1.05);
          }

        }
      }
      .card3:hover{
        .imgDesign {
          transform: scale(1.05);
        } .backconsult{
            font-weight: bold;
          }
      }
    }
  }
  .screenmake3{
    padding: 7.78739184177998vh 5.09763142692085vw;
    width: 100%;
    height: fit-content;
    background: #212a3c;
    //background-color: #ebf1fd;
    .heighLight{
      color:white;
      text-align: center;
      height: 7.54017305315204vh;
      font-size: 1.7vw;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 400;
      //color: #2168DB;
      line-height: 2.71940667490729vh;
    }
    .grid{
      margin-top:2.78739184177998vh ;
      .card{
        //cursor: pointer;
        margin:0 auto;
        width: 86%;
        height:65vh;
        padding:0.98887515451174vh 0 0.98887515451174vh 0;
        opacity: 1;
        .back{
          border-radius: 5px;
          display: flex;
          position: relative;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          height:64vh;
          overflow:hidden;
          background: #19202e;
          .imgDesign{
            width: fit-content;
            height:100%;
            opacity: 1;
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            padding: 1.5248454883vh 1vw;
            color: white;
            width: 30vw;
            height: fit-content;
           margin:auto 0 auto 0;
            //text-align: justify;
            float: right;
            .title1{
              font-size: 1vw;
            }
            .title2{
              margin-top: 10px;
              line-height: 3vh;
              font-size: 0.843212016vw;
            }
            .success{
              display: grid;
              grid-template-columns: minmax(0,1fr) minmax(0,1fr);
              column-gap: 1vw;
              margin-top: 3vh;
              .suc1{
                width: 100%;
                height: 6vh;
                .title3{
                  text-align: center;
                  margin-top: 1vh;
                  font-size: 0.8243212016vw;
                }

              }

            }


          }

        }
      }

      ::v-deep .el-carousel__container{
        height:68vh !important;
      }
      .el-carousel__item h3 {
        color: white;
        opacity: 0.75;
        margin: 0;
      }

      ::v-deep .el-carousel__container {
        position: relative;
        height: 550px;
      }
      ::v-deep .el-carousel__arrow {
        outline: 10vh;
        padding: 0;
        margin: 0  ;
        cursor: pointer;

        border-radius: 50%;
        width: 55px;
        height: 55px;
        background-color: rgba(249, 250, 252, 0.2);
        border: rgba(255, 255, 255, 0.5) 1px solid;
        color: #fff;
        position: absolute;
        top: 50%;
        z-index: 100;
        transform: translateY(-50%);
        text-align: center;
        font-weight:800;
        font-size: 25px;
      }
    }
  }
}
.totalModel {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  position: relative;

  .screen1 {
    z-index: 3;
    position: relative;
    width: 100%;
    height: 460px;
    padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 7.86077411900635vw;
    background-image: url("../../assets/gptModel11.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    /* Add any additional styling as needed */
    .titlle3{
      position: absolute;
      bottom:80px;
      left:50%;
      transform: translateX(-60%);
      font-size: 17px;
       font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 400;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 53px;
      width: fit-content;
      margin: 0 15px;
      padding: 10px 25px;
      height: 40px;
      color:white;
      background-color: #2f6eff;
      border: 1px solid #2f6eff;
      overflow: hidden;

    }
    .title4{
      position: absolute;
      left:10%;
      bottom:45px;
      margin: 35px auto 0 auto;
      width: 80%;
      height: 17px;
      font-size: 10px;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 400;
      color: #111111;
      line-height: 14px;
    }
    .layer2 {
      position: absolute;
      left:0;

      .title1 {
        margin:0 auto;
        width: fit-content;
        text-align: center;
        height: 23px;
        font-size: 23px;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 500;
        color: #111111;

      }

      .title2 {
        margin: 35px auto;
        width: 90%;
        text-align: center;
        height: 17px;
        font-size: 14px;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #111111;
        line-height: 24px;
      }
    }

  }
  .screenmake0 {
    padding: 20px 4.6875vw;
    width: 100%;
    height: fit-content;
    background: white;

    .heighLight {
      text-align: center;
      height: 21px;
      opacity: 1;
      font-size: 21px;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 700;
      line-height: 26px;

    }

    .heightLight2 {
      margin:19px 2vw;
      //text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size:14px;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 400;
      color: #2C3E50;
      line-height: 20px;
    }

    .grid {

      display: grid;
      grid-template-columns: minmax(0, 1fr);
      column-gap: 1vw;

      .card1 {
        //cursor: pointer;
        width: 100%;
        height: fit-content;
        padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
        opacity: 1;

        .back {
          border-radius: 5px;
          position: relative;
          height: fit-content;
          overflow: hidden;
          margin-bottom: 1.5248454883vh;

          .imgDesign {
            text-align: center;
            width: 100%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }

          .titleDesign {
            padding: 1.5248454883vh 1.5248454883vw;
            //background-color: #2F6EFF;
            .Title1 {
              color: black;
              text-align: center;
              font-size: 1vw;

               font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 700;
            }

          }

          .backconsult{
            position: absolute;
            bottom: 1.81248454882571vh;
            text-align: center;
            font-size: 0.8vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            color: #2168DB;
            align-items: center; /* Vertically center the text */
            justify-content: center; /* Horizontally center the text */
            height: 2.95414091470952vh;
            left: 50%;
            transform: translateX(-50%);

            color: #2168DB;
          }
          .backconsultArrow{
            position: absolute;
            bottom: 1.81248454882571vh;
            text-align: center;
            font-size: 0.8vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            color: #2168DB;
            align-items: center; /* Vertically center the text */
            justify-content: center; /* Horizontally center the text */
            height: 2.95414091470952vh;
            left: 80%;
            transform: translateX(-50%);
          }

        }


      }

    }
  }
  .screenmake2{
    padding: 20px 15px;
    width: 100%;
    height: fit-content;
    background: #ebf1fd;
    .heighLight {
      text-align: center;
      height: 21px;
      opacity: 1;
      font-size: 21px;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 700;
      line-height: 26px;

    }




    .grid{
      margin-top:2.78739184177998vh ;
      display: grid;
      grid-template-columns: minmax(0,1fr);
      column-gap:0 ;
      row-gap: 15px;
      .card{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 9px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 210px;
            opacity: 1;
            background-image: url("../../assets/gpt13.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin-top: 220px;
              display: flex;
              justify-content: center;
              text-align: center;
              height: 20px;
              font-size: 16px;
               font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 700;
              color:#FF6400;
              line-height: 2.7194066749vh;
            }
            .Title2{
              margin: 14px 12px 45px 12px;
              //text-align: justify;
              height: fit-content;
              font-size:14px;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              line-height: 22px;
            }
            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
      .card2{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 9px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 240px;
            opacity: 1;
            background-image: url("../../assets/gpt14.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin-top: 250px;
              display: flex;
              justify-content: center;
              text-align: center;
              height: 20px;
              font-size: 16px;
               font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 700;
              color:#FF6400;
              line-height: 2.7194066749vh;
            }
            .Title2{
              margin: 14px 12px 15px 12px;
              //text-align: justify;
              height: fit-content;
              font-size:14px;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              line-height: 22px;
            }
            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
      .card1{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 9px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            border-top-right-radius: 9px;
            border-top-left-radius: 9px;
            height: 185px;
            opacity: 1;
            background-image: url("../../assets/gpt15.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin-top: 195px;
              display: flex;
              justify-content: center;
              text-align: center;
              height: 20px;
              font-size: 16px;
               font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 700;
              color:#FF6400;
              line-height: 2.7194066749vh;
            }
            .Title2{
              margin: 14px 12px 15px 12px;
              //text-align: justify;
              height: fit-content;
              font-size:14px;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              line-height: 22px;
            }
            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }
  .screenmake3{
    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;

    padding:20px 15px;
    background: black;
    .heighLight{
      text-align: center;
      height:21px;
      opacity: 1;
      font-size: 21px;
      color: white;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 700;
      line-height: 26px;
    }
    .heightLight2{
      margin:19px 2vw;
      //text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size:14px;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 400;
      color: #2C3E50;
      line-height: 20px;
    }
    .grid{
      margin-top:2.78739184177998vh ;
      position: relative;

      .card{
        cursor: pointer;
        width: 98%;
        margin:0 auto;
        min-height: 250px ;
        padding:0 0.69324090121317vw 0 0;
        opacity: 1;
        background: #19202e;
        .back{
          border-radius: 5px;
          position: relative;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          min-height: 360px ;
          overflow:hidden;
          background: #19202e;
          .imgDesign{
            width: 100%;
            height:234px;
            opacity: 1;
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            padding: 15px 20px 25px 20px;
            color: white;
            width:100%;
            float: right;
            .title1{
              margin-top:15px;
              font-size: 16px;
            }
            .title2{
              margin-top: 15px;
              line-height: 22px;
              font-size:14px;
              ::v-deep li{
                margin-top:5px;
              }
            ;
            }


          }

        }
      }

      ::v-deep .el-carousel__container{
        height: 430px !important;

      }
      .el-carousel__item h3 {
        color: white;
        opacity: 0.75;
        margin: 0;
      }

      .el-carousel__item:nth-child(2n) {
        background-color: transparent;
      }

      .el-carousel__item:nth-child(2n+1) {
        background-color: transparent;
      }

      ::v-deep .el-carousel__arrow {
        display: block;
        //border: 1px solid white;
        outline: -5px;
        padding: 0;
        margin: -68px -10px  ;
        cursor: pointer;

        border-radius: 0;
        width: 25px;
        height: 48px;
        background: rgba(245, 246, 250, 0.8);
        color: #131313;
        position: absolute;
        top: 50%;
        z-index: 100;
        transform: translateY(-50%);
        text-align: center;
        font-size: 14px;
      }
    }
  }
}
</style>



