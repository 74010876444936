<template>
  <div>
    <div v-if="model===false">
      <div class="totalmakeHole" ref="total">
        <div class="topback"  :style="{ height: '7vh', backgroundColor: scrollback ? 'rgb(196,196,196)' : 'rgba(196,196,196,0.5)' ,position:'fixed'}">
          <headtip @showDialog="showPartner"></headtip>
        </div>
        <div class="makeHole1">
          <div class="talk_con">
            <div>
              <div class="title">Feedback</div>
              <div class="title1">Dear Users:</div>
              <div class="title2" >Hello! In order to provide you with a better online experience, we sincerely invite you to provide feedback on <span  style="color: rgb(255, 100, 0);font-weight: bold">the platform display content, online product functions and web page usage experience</span>. Each suggestion or comment you provide is vital for our ongoing development. We are committed to continuously refining our websites based on your input, aiming to offer you a more pleasant online experience. Thank you for your time and continued support!</div>
            </div>
            <div class="form-container">
              <el-row style="width: 100%">
                <el-form ref="formData" :model="formData" :rules="rules" size="medium" label-width="12vw">
                  <el-form-item label="Your suggestion" prop="feedbackContent">
                    <el-input v-model="formData.feedbackContent" type="textarea"  placeholder="Please describe what you want to feedback"
                              :autosize="{minRows: 4, maxRows: 4}" class="inputStyle"></el-input>
                  </el-form-item>
                  <el-form-item label="Attachment content" prop="field102">
                    <el-upload
                        ref="field102"
                        :file-list="fileList"
                        :on-preview="handlePreview"
                        :on-remove="handleRemove"
                        :before-remove="beforeRemove"
                        :before-upload="fileUpload"
                        class="inputStyle"
                        :show-file-list="true"
                        action="/common/upload"
                        drag
                        multiple>
                      <i class="el-icon-upload"></i>
                      <div class="el-upload__text" style="line-height: 2vh"><em>
                        Click to upload</em></div>
                      <div class="el-upload__text" style="margin-top: 0;line-height: 5vh">（Supports word, pdf, jpg, jpeg and other format files）</div>
                    </el-upload>

                  </el-form-item>
                  <el-form-item label="Your Name" prop="userName">
                    <el-input class="custom-input" prefix-icon='el-icon-user' v-model="formData.userName" placeholder="Please input your  name"
                              clearable :style="{width: '90%'}">
                    </el-input>
                  </el-form-item>
                  <el-form-item label="Your Phone" prop="phoneNum">
                    <el-input class="custom-input" v-model="formData.phoneNum"
                              placeholder="Please input your mobile phone number"
                              show-word-limit clearable prefix-icon='el-icon-mobile'
                              :style="{width: '90%'}"></el-input>
                  </el-form-item>
                  <el-form-item label="Your Email" prop="email">
                    <el-input class="custom-input" v-model="formData.email" placeholder="Please input your email"
                              clearable
                              prefix-icon='el-icon-message'
                              :style="{width: '90%'}">
                    </el-input>
                  </el-form-item>

                  <div style="margin-top:5vh;width: 60vw; text-align:center;">

                    <el-button
                        type="primary"
                        class="submitStyle"
                        @click="submitForm">
                      Submit
                    </el-button>
                  </div>

                </el-form>
              </el-row>

            </div>
          </div>


          <bottomTip></bottomTip>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
      <div v-if="showModal" @click="closeModal" class="modal-overlay">
        <div class="modal-content" >
          <img :src="largeSrc" alt="Large Image" class="large-image">
        </div>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel">
        <div>
          <div class="screen1">
            <headtip @showDialog="showPartner"></headtip>
          </div>
          <div class="makeHole1">
            <div class="talk_con">
              <div>
                <div class="title">Feedback</div>
                <div class="title1">Dear Users:</div>
                <div class="title2" >Hello! In order to provide you with a better online experience, we sincerely invite you to provide feedback on <span  style="color: rgb(255, 100, 0);font-weight: bold">the platform display content, online product functions and web page usage experience</span>. Each suggestion or comment you provide is vital for our ongoing development. We are committed to continuously refining our websites based on your input, aiming to offer you a more pleasant online experience. Thank you for your time and continued support!</div>
              </div>
              <div class="form-container">
                <el-row style="width: 100%">
                  <el-form ref="formData" :model="formData" :rules="rules" size="medium" label-width="180px">
                    <el-form-item label="Your suggestion" prop="feedbackContent">
                      <el-input v-model="formData.feedbackContent" type="textarea" placeholder="Please describe what you want to feedback"
                                :autosize="{minRows: 4, maxRows: 4}" class="inputStyle"></el-input>
                    </el-form-item>
                    <el-form-item label="Attachment content" prop="field102"><br>
                      <el-upload
                          ref="field102"
                          :file-list="fileList"
                          :on-preview="handlePreview"
                          :on-remove="handleRemove"
                          :before-remove="beforeRemove"
                          :before-upload="fileUpload"
                          class="inputStyle"
                          :show-file-list="true"
                          action="/common/upload"
                          drag
                          multiple>
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text" style="line-height: 2vh"><em>
                          Click to upload</em></div>
                        <div class="el-upload__text" style="margin-top: 0;line-height: 5vh;font-size: 12px">（Supports word, pdf, jpg, jpeg and other format files）</div>
                      </el-upload>
                    </el-form-item>
                    <el-form-item label="Your Name" prop="userName">
                      <el-input class="custom-input" v-model="formData.userName" prefix-icon='el-icon-user' placeholder="Please input your name"
                                clearable :style="{width: '90%'}">
                      </el-input>
                    </el-form-item>
                    <el-form-item label="Your Phone" prop="phoneNum">
                      <el-input class="custom-input" v-model="formData.phoneNum"
                                placeholder="Please input your mobile phone number"
                                show-word-limit clearable prefix-icon='el-icon-mobile'
                                :style="{width: '90%'}"></el-input>
                    </el-form-item>
                    <el-form-item label="Your Email" prop="email">
                      <el-input class="custom-input" v-model="formData.email" placeholder="Please input your email"
                                clearable
                                prefix-icon='el-icon-message'
                                :style="{width: '90%'}">
                      </el-input>
                    </el-form-item>

                    <div style="margin-top:15px;width:85vw; text-align:center;">

                      <el-button
                          type="primary"
                          class="submitStyle"
                          @click="submitForm">
                        Submit
                      </el-button>
                    </div>

                  </el-form>
                </el-row>

              </div>
            </div>

          </div>
          <div ref="bottom">
            <bottomTip></bottomTip>
          </div>

        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>



</template>


<script>

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
export default {

  name: "",

  props: [],

  components: {PartnerTip, consultTip, headtip,bottomTip},

  data() {
    return {
      showModal: false,
      largeSrc:'',
      title:'',
      pageTitle: 'Feedback-Oil & Gas Generic AI',
      pageDescription: 'We strive to enhance your online experience and would deeply appreciate your feedback. Each suggestion or comment you provide is vital for our ongoing development. We are committed to continuously refining our websites based on your input, aiming to offer you a more pleasant online experience. Thank you for your time and continued support!',
      pageKeywords: 'Feedback,Oil & Gas Generic AI',
      model:false,
      scrollback:false,
      dialogconsult:false,
      dialogVisible:false,
      contentType: {
        phone: "",
        email: "",
        address: "",
      },
      field102Action: 'http://54.223.240.188:8787',
      fileList: [],
      formData: {
        feedbackContent: '',
        field102: null,
        userName: undefined,
        phoneNum: '',
        email: undefined,
        fileName: '',
      },
      rules: {
        feedbackContent: [{
          required: true,
          message: 'Please input your suggestion',
          trigger: 'blur'
        }],
        userName: [{
          required: true,
          message: 'Please input your name',
          trigger: 'blur'
        }],
        field105: [],
        phoneNum: [{
          pattern: /^1(3|4|5|7|8|9)\d{9}$/,
          message: 'Mobile phone number format error',
          trigger: 'blur'
        }],
        field103: [],
      },

    }

  },


  methods: {
    showDialog(type) {
      this.dialogVisible = type;
    },
    GetColumnContact() {
      this.$axios.get('/api/feedback/add', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.token,
        }
      })
          .then((res) => {
            this.contentType = res.data;
          })
          .catch(() => {
          });
    },

    // 表单Submit
    submitForm() {
      this.$refs.formData.validate((valid) => {
        if (valid) {
          const formData = {

            feedbackContent: this.formData.feedbackContent,
            phoneNum: this.formData.phoneNum,
            email: this.formData.email,
            userName: this.formData.userName,
            dataSources: this.uploadedFileValue ? this.uploadedFileValue.fileName : null,
          };
          this.$axios.post('/api/feedback/add', formData, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': this.token,
            }
          })
              .then(response => {
                this.$message({
                  message: "Submission successful, thank you for your feedback!🙂",
                  type: "success",
                });
                console.log(response)
                // 在请求成功后延迟 5 秒跳转到 Home 页
                setTimeout(() => {
                  // 使用路由进行页面跳转（假设路由对象为 this.$router）
                  this.$router.push('/'); // 你需要替换成你的 Home 页路径
                }, 3000); // 延迟 5000 毫秒（即 5 秒）跳转
              })
              .catch(error => {
                this.$message({
                  message: "Submission failed, please try again later!😭",
                  type: "error",
                });
                console.log(error)
                // 在请求失败时执行的操作
              });
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },

    // 文件上传
    async fileUpload(file) {
      try {
        // 构建表单数据
        const formData = new FormData();
        formData.append('file', file);

        // 向后端发送文件到 /common/upload 接口
        const response = await this.$axios.post('/common/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        this.uploadedFileValue = response.data;
        console.log(response.data); // 打印响应数据到控制台

        // 返回 true 允许上传，false 禁止上传
        return true;
      } catch (error) {
        console.error('上传失败:', error);
        return false;
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log("预览")
      console.log(file);
      window.open(file.response.url)
      // this.showModal = true;
      // this.largeSrc=file.response.url
    },

    beforeRemove(file, fileList) {
      console.log(fileList)
      return this.$confirm(`Are you sure to removal ${ file.name }？`);
    },


    field102BeforeUpload(file) {
      let isRightSize = file.size / 1024 / 1024 < 2
      if (!isRightSize) {
        this.$message.error('文件大小超过 2MB')
      }
      return isRightSize
    },
    showModalTrue(item){
      this.showModal = true;
      console.log(item)
      this.largeSrc=item
    },
    closeModal() {
      this.showModal = false;
    },
    clickConsult(item){

      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner(){
      this.dialogconsult=true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
    handleScroll() {
      if(this.model===false){
        this.scrollHeight = this.$refs.total.scrollTop;
      }
      else{
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if( this.scrollHeight<100){
        this.scrollTrue=true
      }
      else{
        this.scrollTrue=false
      }
      if( this.scrollHeight<500){
        this.scrollback=false
      }
      else{
        this.scrollback=true
      }
      console.log("gaodu",this.scrollHeight<100)
    },
  },
  beforeDestroy() {

    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);
    document.title=this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }

    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: 'Feedback-油气通GPT',
        pageDescription: '您好！为了给您提供更好的网上体验，我们诚挚邀请您反馈使用感受，您留下的每个意见或建议都是我们不断改进的动力！我们会针对您的反馈不断优化网页，为您提供更加愉悦的服务体验！感谢您的支持！',
        pageKeywords: 'Feedback，油气通GPT',

      };
      shareUrl(shareData);
    }, 1000);
  },
}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole{
  background: white;
  overflow-x: hidden;
  width: 99vw;
  height: auto;
  position: relative;
  //overflow-y: hidden;
  .topback{
    position: fixed;
    z-index:1;
    width: 100vw;
    padding: 0 0 0 2.86077411900635vw;
  }
  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    height: fit-content;
    .talk_con {
      height: auto;
      width: 63vw;
      padding:3vh 5vw 3vh 5vw;
      margin: 10vh auto;
      border-radius: 5px;
      //box-shadow: 0 2px 4px 0 rgb(100, 98, 98);
      background-color: #fff;
      .title{
        margin:0 auto 3vh auto;
        text-align: center;
        font-size:1.5vw;
        color:#333333;
        font-weight: bold
      }
      .title1{
        margin:0 auto 2vh auto;
        text-align: left;
        font-size:1vw;
        color:#333333;
        font-weight: bold
      }
      .title2{
        margin:0 auto 4vh auto;
        text-align: left;
        line-height: 4vh;
        font-size:0.9vw;
        color:#333333;
        font-weight: normal;
      }

    }
    .submitStyle{
      font-size:16px;
      height:44px;
      background-color: #316fff;
      border: none;
      border-radius: 9px;
      text-align: center;
      margin: 5px auto;
      width: 15vw;
    }
  }
  ::v-deep .el-form-item__label{
    font-weight: bold !important;
    font-size: 1vw;
    margin-bottom: 1vh;
  }
  ::v-deep .el-textarea__inner{
    border: 1px solid black;
    width: 39vw;
  }
  ::v-deep .el-upload-list__item{
    width: 39vw;
  }
  ::v-deep .el-upload-dragger{
    border: 1px solid black;
  }
  ::v-deep el-form-item__content{
    margin-left: 0;
  }
  ::v-deep .el-upload-dragger{
    width: 39vw;
  }
  //::v-deep .el-form-item__content{
  //  margin-left: 0 !important;
  //}
  ::v-deep .el-input__inner{
    border: 1px solid black !important;
    width: 25vw;
    height: 6vh;
  }
}
.modal-overlay {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height:100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  .large-image {
    max-width: 100%;
    max-height: 80vh;
  }
}
.totalmakeHoleModel{
  //background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .screen1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    height: 0;
    padding: 2.84301606922126vh 2.86077411900635vw 2.84301606922126vh 2.86077411900635vw;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;

    /* Add any additional styling as needed */
  }
  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    height: fit-content;
    .talk_con {
      height: auto;
      width:90%;
      padding:15px;
      margin: 5px auto 15px auto;
      border-radius: 5px;
      //box-shadow: 0 2px 4px 0 rgb(100, 98, 98);
      //background-color: #fff;
      .title{
        margin:0 auto 20px auto;
        text-align: center;
        font-size:16px;
        color:#333333;
        font-weight: bold
      }
      .title1{
        margin:0 auto 10px auto;
        text-align: left;
        font-size:16px;
        color:#333333;
        font-weight: bold
      }
      .title2{
        margin:0 auto 15px auto;
        text-align: left;
        line-height: 26px;
        font-size:14px;
        color:#333333;
        font-weight: normal;
      }

    }
    .submitStyle{
      font-size:16px;
      height:44px;
      background-color: #316fff;
      border: none;
      border-radius: 9px;
      text-align: center;
      margin: 5px auto;
      width: 150px;
    }
  }
  ::v-deep .el-form-item__label{
    font-weight: bold !important;
    font-size: 14px;
    text-align: left;
    margin-bottom:5px ;
  }
  ::v-deep .el-form-item{
    margin-bottom:5px !important;
  }
  ::v-deep .el-form-item__error{
    top:7px;
    font-size: 12px;
    left:80px;
  }
  ::v-deep .el-textarea__inner{
    border: 1px solid black;
    width: 80vw;
  }
  ::v-deep .el-upload-dragger{
    border: 1px solid black;
  }
  ::v-deep el-form-item__content{
    margin-left: 0;
  }
  ::v-deep .el-upload-dragger{
    width: 80vw !important;
    margin-left: 0;
  }
  ::v-deep .el-form-item__content{
    margin-left: 0 !important;
  }
  ::v-deep .el-input__inner{
    border: 1px solid black !important;
    width: 80vw;
    height: 45px;
  }
}
</style>
