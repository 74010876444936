<template>
  <div>
    <div v-if="model===false">
      <div class="totalmakeHole" ref="total">
        <div class="topback"  :style="{ height: '7vh', backgroundColor: scrollback ? 'rgb(196,196,196)' : 'transparent' ,position:'fixed'}">
          <headtip @showDialog="showPartner"></headtip>
        </div>

        <div class="makeHole1">
          <div class="background">
            <!--     文字-->
            <div class="titlle1">Intelligent Recovery Solutions </div>
            <div class="title4">*The solution is the field of Oil & Gas development technology services and AI model fusion, through the field of the solution to provide services to clients, for further understanding of the solution cooperation, please feel free to contact us.</div>
            <div class="titlle3" @click="dialogVisible=true">
              Solution Consulting
            </div>


          </div>

          <div class="screenmake0">
            <div class="heighLight">Intelligent Oil & Gas Service Overview</div>
            <div class="heightLight2">Anton is a leading provider of integrated Oil & Gas technology services. Leveraging global resources, it offers lifecycle technical support to oilfields worldwide, encompassing water drainage, chemicals, artificial lifting, O&M, and specialized oil recovery.
            </div>
            <div class="heightLight3">Oil & Gas Recovery Technology</div>
            <div class="line0"></div>
            <div class="grid0">
              <div class="card">
                <div class="heightLight3">Drainage Technology</div>
              </div>
              <div class="card">
                <div class="line1"></div>
                <div class="heightLight3">Artificial Lift Technology</div>
              </div>
              <div class="card">
                <div class="heightLight3">Oil Recovery Technology</div>
              </div>


            </div>

          </div>
          <div class="screenmake">
            <div class="heighLight">
              <span style="background-color: #FF6400;border-radius: 50%; width:1.7vw;font-size:1.5vw;height:1.7vw;display: flex;justify-content: center;align-items: center;color: white; ">1</span>
              <span>&nbsp;&nbsp;Intelligent Oil Recovery Engineering</span>
            </div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Schematic diagram of fracturing construction parameter optimization</div>
                    <div class="Title2">Based on multiple wells in the same block, the model training is completed, and the mapping relationship between geological and engineering parameters and EUR is established.</div>
                  </div>
                </div>


              </div>
              <div class="card1">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Intelligent Layer Injection</div>
                    <div class="Title2">Applied to injection and extraction well groups to solve the problems of low efficiency of generalized water injection, imbalance of injection and extraction ratio, and prominent inter-layer conflicts.</div>
                  </div>
                </div>

              </div>
              <div class="card2">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Intelligent Artificial Lifting</div>
                    <div class="Title2">Applied to oil wells relying on manual lifting to solve the problems of unreasonable lifting parameters and low lifting efficiency.</div>
                  </div>
                </div>

              </div>
            </div>

          </div>
          <div class="screenmake2">
            <div class="heighLight">
              <span style="background-color: #FF6400;border-radius: 50%; width:1.7vw;font-size:1.5vw;height:1.7vw;display: flex;justify-content: center;align-items: center;color: white; ">2</span>
              <span>&nbsp;&nbsp;Intelligent Gas Recovery Engineering</span>
            </div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Intelligent Well Group Energy Optimization Management</div>
                    <div class="Title2">Applied to platform clump gas wells, it solves the problems of water flooding and difficult to resume production of low-pressure gas wells in the well group, intermittent gas lift, and low drainage efficiency of single process.</div>
                  </div>
                </div>


              </div>
              <div class="card1">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Intelligent Plunger Drainage Gas Recovery</div>
                    <div class="Title2">Applied to all kinds of water-producing gas wells, it solves the problems of water flooding of gas wells, serious slippage during wellbore liquid lifting, and irrational plunger operation system.</div>
                  </div>
                </div>

              </div>
              <div class="card2">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Intelligent Foam Drainage Gas Recovery</div>
                    <div class="Title2">It is applied to all stages of the whole life cycle of gas well extraction, solving the problems of high labor cost of traditional dosing and unreasonable dosing system.</div>
                  </div>
                </div>

              </div>
            </div>

          </div>
          <div class="screenmake3">
            <div class="heighLight">
              <span style="background-color: #FF6400;border-radius: 50%; width:1.7vw;font-size:1.5vw;height:1.7vw;display: flex;justify-content: center;align-items: center;color: white; ">3</span>
              <span>&nbsp;&nbsp;Intelligent Oil & Gas Recovery Explained</span>
            </div>
            <div class="grid">
              <div class="card">
                <div class="back" @click="dialog = true">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">

                    <el-tooltip  placement="bottom" effect="light" popper-class="custom-tooltip" :class="'custom-tooltip'">
                      <div style="font-size: 0.8243212016vw; line-height: 3.194066749vh;" slot="content">
                        Explanation Of Rising Water Content In Low-Permeability Wells</div>
                      <div class="Title1">Explanation Of Rising Water Content In Low-Permeability Wells</div>
                    </el-tooltip>

                    <div class="Title2">By collecting a large amount of water content data of low permeability oil wells and normalizing and fitting them with the static data of logging wells, fracture reforming data, production parameters, etc., we get the graphical interpretation of the rising water content law of low permeability oil wells, which provides the theoretical basis for the production of low permeability oil wells with water control.</div>
                  </div>
                  <div class="backconsult" @click="dialog = true">
                    Product Inquiry
                  </div>
                  <div class="backconsultArrow">
                    <i style="font-size: 0.9vw;margin-left: 1vw;" class="el-icon-right"></i>
                  </div>
                </div>


              </div>
              <div class="card1">
                <div class="back" @click="dialog = true">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Explanation Of Gas Well Fluid Accumulation Law</div>
                    <div class="Title2">By collecting a large number of gas well fluid accumulation rate, fluid accumulation height and production layer, pressure, gas production, tubing diameter size and other data for normalized fitting processing, to get the interpretation of gas well fluid accumulation pattern template, you can predict the fluid accumulation rate and height of a single well, and prevent gas well flooding in advance.</div>
                  </div>
                  <div class="backconsult" @click="dialog = true">
                    Product Inquiry
                  </div>
                  <div class="backconsultArrow">
                    <i style="font-size: 0.9vw;margin-left: 1vw;" class="el-icon-right"></i>
                  </div>
                </div>


              </div>
              <div class="card2">
                <div class="back" @click="dialog = true">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Explanation Of Gas Well Life Cycle Laws</div>
                    <div class="Title2">Through fitting the production cycle law of a large number of gas wells, we can obtain the interpretation of different life cycle lengths of tight gas and shale gas, which can predict the life cycle of a single well and provide a theoretical basis for selecting the technological support.</div>
                  </div>
                  <div class="backconsult" @click="dialog = true">
                    Product Inquiry
                  </div>
                  <div class="backconsultArrow">
                    <i style="font-size: 0.9vw;margin-left: 1vw;" class="el-icon-right"></i>
                  </div>
                </div>


              </div>
              <div class="card3">
                <div class="back" @click="dialog = true">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <el-tooltip  placement="bottom" effect="light" popper-class="custom-tooltip" :class="'custom-tooltip'">
                      <div style="font-size: 0.8243212016vw; line-height: 3.194066749vh;" slot="content">
                        Horizontal Well Inflow Dynamic Law Interpretation</div>
                      <div class="Title1">Horizontal Well Inflow Dynamic Law Interpretation</div>
                    </el-tooltip>


                    <div class="Title2">By collecting a large number of horizontal well production and flow pressure data, we redraw the interpretation template of IPR curve of horizontal wells, which provides a theoretical basis for stable production of horizontal wells.</div>
                  </div>
                  <div class="backconsult" @click="dialog = true">
                    Product Inquiry
                  </div>
                  <div class="backconsultArrow">
                    <i style="font-size: 0.9vw;margin-left: 1vw;" class="el-icon-right"></i>
                  </div>
                </div>


              </div>
              <div class="card4">
                <div class="back" @click="dialog = true">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Explanation Of The Law Of Decreasing Fluid Volume In Low-permeability Oilfields</div>
                    <div class="Title2">By collecting a large number of low-permeability oil well production data and normalizing and fitting them with the static data of logging wells, fracture reforming data, production parameters, etc., we get the graphical interpretation of the decreasing liquid volume law of low-permeability oil wells, which provides the theoretical basis for the production control of low-permeability oil wells.</div>
                  </div>
                  <div class="backconsult" @click="dialog = true">
                    Product Inquiry
                  </div>
                  <div class="backconsultArrow">
                    <i style="font-size: 0.9vw;margin-left: 1vw;" class="el-icon-right"></i>
                  </div>
                </div>


              </div>
            </div>

          </div>
          <div class="screenmake4">
            <div class="heighLight">
              <span  style="background-color: #FF6400;border-radius: 50%; width:1.7vw;font-size:1.5vw;height:1.7vw;display: flex;justify-content: center;align-items: center;color: white; ">4</span>
              <span>&nbsp;&nbsp;Intelligent Oil & Gas Design</span>
            </div>
            <div class="grid">
              <div class="card" >
                <div class="back" @click="dialog = true">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">

                    <el-tooltip  placement="bottom" effect="light" popper-class="custom-tooltip" :class="'custom-tooltip'">
                      <div style="font-size: 0.8243212016vw; line-height: 3.194066749vh;" slot="content">
                        Layer Injection And Recovery Linkage Optimization Design</div>
                      <div class="Title1">Layer Injection And Recovery Linkage Optimization Design</div>
                    </el-tooltip>

                    <div class="Title2"> <li>Layer-by-layer injection and recovery design</li>
                      <li>Parameter design</li>
                      <li>Injection and recovery linkage design</li></div>
                  </div>
                  <div class="backconsult" @click="dialog = true">
                    Product Inquiry
                  </div>
                  <div class="backconsultArrow">
                    <i style="font-size: 0.9vw;margin-left: 1vw;" class="el-icon-right"></i>
                  </div>
                </div>


              </div>

              <div class="card1">
                <div class="back" @click="dialog = true">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Optimization Of Manual Lifting</div>
                    <div class="Title2">
                      <li>Optimization of lifting method</li>
                      <li>Optimization of lifting parameters</li>
                      <li>Pipe & Column Structure Optimization</li>
                    </div>
                  </div>
                  <div class="backconsult" @click="dialog = true">
                    Product Inquiry
                  </div>
                  <div class="backconsultArrow">
                    <i style="font-size: 0.9vw;margin-left: 1vw;" class="el-icon-right"></i>
                  </div>
                </div>


              </div>
              <div class="card2">
                <div class="back" @click="dialog = true">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <el-tooltip  placement="bottom" effect="light" popper-class="custom-tooltip" :class="'custom-tooltip'">
                      <div style="font-size: 0.8243212016vw; line-height: 3.194066749vh;" slot="content">
                        Gas Well Energy Optimization Management Design</div>
                      <div class="Title1">Gas Well Energy Optimization Management Design</div>
                    </el-tooltip>


                    <div class="Title2">
                      <li>Energy Optimization Tubing Network Design</li>
                      <li>Energy optimization system design</li>
                      <li>Composite Drainage Process Design</li>
                    </div>
                  </div>
                  <div class="backconsult" @click="dialog = true">
                    Product Inquiry
                  </div>
                  <div class="backconsultArrow">
                    <i style="font-size: 0.9vw;margin-left: 1vw;" class="el-icon-right"></i>
                  </div>
                </div>


              </div>
              <div class="card3">
                <div class="back" @click="dialog = true">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Plunger Drainage Design</div>
                    <div class="Title2 " >
                      <li>Plunger type optimization</li>
                      <li>Plunger operation regime optimization</li>
                      <li>Optimization of caliper depth</li>
                    </div>
                  </div>
                  <div class="backconsult" @click="dialog = true">
                    Product Inquiry
                  </div>
                  <div class="backconsultArrow">
                    <i style="font-size: 0.9vw;margin-left: 1vw;" class="el-icon-right"></i>
                  </div>
                </div>


              </div>
              <div class="card4">
                <div class="back" @click="dialog = true">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">&nbsp;&nbsp;Foam Drainage Design  &nbsp;&nbsp;</div>
                    <div class="Title2" > <li>Ratio optimization</li>
                      <li>Dosing system optimization</li>
                      <li>Optimization of dosing method</li></div>
                  </div>
                  <div class="backconsult" @click="dialog = true">
                    Product Inquiry
                  </div>
                  <div class="backconsultArrow">
                    <i style="font-size: 0.9vw;margin-left: 1vw;" class="el-icon-right"></i>
                  </div>
                </div>


              </div>

            </div>

          </div>
          <div class="screenmake5">
            <div class="heighLight">Successful Cases</div>
            <div class="grid">
              <el-carousel indicator-position="outside" arrow="always" interval="5000">
                <el-carousel-item v-for="item in success" :key="item">
                  <div class="card">
                    <div class="back">
                      <div class="imgDesign">
                        <img :src="item.img"/>
                      </div>
                      <div class="titleDesign">
                        <div class="title1">{{item.title1}}</div>

                        <div class="title2" v-html="item.title3">  </div>
                        <div class="title1" style="margin-top: 25px">{{item.title2}}</div>
                        <div class="title2" v-html="item.title4"></div>

                      </div>
                    </div>


                  </div>


                </el-carousel-item>
              </el-carousel>
            </div>

          </div>
          <div style="z-index: 999999999999">
            <bottomTip></bottomTip>
          </div>

        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialog"
            @hideDialog="hideConsult"
        ></consultTip>
        <resultTip
            :dialogVisible="dialogVisible"
            @hideDialog="hideDialog"
        ></resultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel">
        <div class="makeHole1">
          <div class="background">
            <!--      logo  you气通GPT社区 合作伙伴  登录-->
            <headtip @showDialog="showPartner"></headtip>
            <!--     文字-->
            <div class="titlle1">Intelligent Recovery Solutions</div>
            <div class="title4">*The solution is the field of Oil & Gas development technology services and AI model fusion, through the field of the solution to provide services to clients, for further understanding of the solution cooperation, please feel free to contact us.</div>
            <div class="titlle3" @click="dialogVisible=true">
              Solution Consulting
            </div>

          </div>

          <div class="screenmake0">
            <div class="heighLight">Intelligent Oil & Gas Service Overview</div>
            <div class="heightLight2">Anton is a professional integrated service provider of oil recovery and gas technology, with high-quality and experienced oil recovery and gas technology service team, professional design software, international standard management system, through the integration of global oil recovery technology resources, to provide domestic and foreign oilfields with the whole life cycle of the drainage gas extraction process technology services, oilfield chemical products and technology services, artificial lift products and technology and oilfield operation and maintenance services, special oil recovery technical services, etc.</div>

            <div class="heightLight3">Recovery Technology</div>
            <div class="line0"></div>
            <div class="grid0">
              <div class="card">
                <div class="heightLight3">Drainage Technology</div>
              </div>
              <div class="card">
                <div class="line1"></div>
                <div class="heightLight3">Artificial Lift Technology</div>
              </div>
              <div class="card">
                <div class="heightLight3">Oil Recovery Technology</div>
              </div>


            </div>

          </div>
          <div class="screenmake">
            <div class="heighLight">
              <span>①&nbsp;Intelligent Oil Recovery Engineering</span>
            </div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Schematic diagram of fracturing construction parameter optimization</div>
                    <div class="Title2">Based on multiple wells in the same block, the model training is completed, and the mapping relationship between geological and engineering parameters and EUR is established.</div>
                  </div>
                </div>


              </div>
              <div class="card1">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Intelligent Layer Injection</div>
                    <div class="Title2">Applied to injection and extraction well groups to solve the problems of low efficiency of generalized water injection, imbalance of injection and extraction ratio, and prominent inter-layer conflicts.</div>
                  </div>
                </div>

              </div>
              <div class="card2">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Intelligent Artificial Lifting</div>
                    <div class="Title2">Applied to oil wells relying on manual lifting to solve the problems of unreasonable lifting parameters and low lifting efficiency.</div>
                  </div>
                </div>

              </div>
            </div>

          </div>
          <div class="screenmake2">
            <div class="heighLight">
              <span>②&nbsp;Intelligent Gas Recovery Engineering</span>
            </div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Intelligent Well Group Energy Optimization Management</div>
                    <div class="Title2">Applied to platform clump gas wells, it solves the problems of water flooding and difficult to resume production of low-pressure gas wells in the well group, intermittent gas lift, and low drainage efficiency of single process.</div>
                  </div>
                </div>


              </div>
              <div class="card1">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Intelligent Plunger Drainage Gas Recovery</div>
                    <div class="Title2">Applied to all kinds of water-producing gas wells, it solves the problems of water flooding of gas wells, serious slippage during wellbore liquid lifting, and irrational plunger operation system.</div>
                  </div>
                </div>

              </div>
              <div class="card2">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Intelligent Foam Drainage Gas Recovery</div>
                    <div class="Title2">It is applied to all stages of the whole life cycle of gas well extraction, solving the problems of high labor cost of traditional dosing and unreasonable dosing system.</div>
                  </div>
                </div>

              </div>
            </div>

          </div>
          <div class="screenmake3">
            <div class="heighLight">
              <span>③&nbsp;Intelligent Oil & Gas Recovery Explained</span>
            </div>
            <div class="grid">
              <div class="card"  @click="dialog = true">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Explanation Of Rising Water Content In Low-Permeability Wells</div>
                    <div class="Title2">By collecting a large amount of water content data of low permeability oil wells and normalizing and fitting them with the static data of logging wells, fracture reforming data, production parameters, etc..</div>
                  </div>

                </div>


              </div>
              <div class="card1"  @click="dialog = true">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Explanation Of Gas Well Fluid Accumulation Law</div>
                    <div class="Title2">By gathering various data such as gas well fluid accumulation rates, heights, production layer pressures, gas output, and tubing diameters, and subjecting this data to normalized fitting analysis, a template for interpreting gas well fluid accumulation patterns can be established.</div>
                  </div>

                </div>


              </div>
              <div class="card2"  @click="dialog = true">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Explanation Of Gas Well Life Cycle Laws</div>
                    <div class="Title2">Through fitting the production cycle law of a large number of gas wells, we can obtain the interpretation of different life cycle lengths of tight gas and shale gas, which can predict the life cycle of a single well and provide a theoretical basis for selecting the technological support.</div>
                  </div>

                </div>


              </div>
              <div class="card3"  @click="dialog = true">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Horizontal Well Inflow Dynamic Law Interpretation</div>
                    <div class="Title2">By collecting a large number of horizontal well production and flow pressure data, we redraw the interpretation template of IPR curve of horizontal wells, which provides a theoretical basis for stable production of horizontal wells.</div>
                  </div>

                </div>


              </div>
              <div class="card4"  @click="dialog = true">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Explanation Of The Law Of Decreasing Fluid Volume In Low-permeability Oilfields</div>
                    <div class="Title2">
Gathering extensive production data from low-permeability oil wells, along with static logging data, fracture reforming information, and production parameters, allows for normalized analysis and fitting. </div>
                  </div>

                </div>


              </div>
            </div>

          </div>
          <div class="screenmake4">
            <div class="heighLight">
              <span>④&nbsp;Intelligent Oil & Gas Design</span>
            </div>
            <div class="grid">
              <div class="card"  @click="dialog = true">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Layer injection and recovery linkage optimization design</div>
                    <div class="Title2"> <li>Layer-by-layer injection and recovery design</li>
                      <li>Parameter design</li>
                      <li>Injection and recovery linkage design</li></div>
                  </div>

                </div>


              </div>

              <div class="card1"  @click="dialog = true">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Optimization Of Manual Lifting</div>
                    <div class="Title2">
                      <li>Optimization of lifting method</li>
                      <li>Optimization of lifting parameters</li>
                      <li>Pipe & Column Structure Optimization</li>
                    </div>
                  </div>

                </div>


              </div>
              <div class="card2"  @click="dialog = true">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Gas Well Energy Optimization Management Design</div>
                    <div class="Title2">
                      <li>Energy Optimization Tubing Network Design</li>
                      <li>Energy optimization system design</li>
                      <li>Composite Drainage Process Design</li>
                    </div>
                  </div>

                </div>


              </div>
              <div class="card3"  @click="dialog = true">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Plunger Drainage Design</div>
                    <div class="Title2 " >
                      <li>Plunger type optimization</li>
                      <li>Plunger operation regime optimization</li>
                      <li>Optimization of caliper depth</li>
                    </div>
                  </div>

                </div>


              </div>
              <div class="card4"  @click="dialog = true">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">&nbsp;&nbsp;Foam Drainage Design&nbsp;&nbsp;</div>
                    <div class="Title2"> <li>Ratio optimization</li>
                      <li>Dosing system optimization</li>
                      <li>Optimization of dosing method</li></div>
                  </div>

                </div>


              </div>

            </div>

          </div>
          <div class="screenmake5">
            <div class="heighLight">Successful Cases</div>
            <div class="grid">
              <el-carousel indicator-position="outside" arrow="always" interval="5000">
                <el-carousel-item v-for="item in successMobel" :key="item">
                  <div class="card">
                    <div class="back">
                      <div class="imgDesign">
                        <img :src="item.img"/>
                      </div>
                      <div class="titleDesign">
                        <div class="title1">{{item.title1}}</div>

                        <div class="title2" v-html="item.title3">  </div>
                        <div class="title1">{{item.title2}}</div>
                        <div class="title2" v-html="item.title4"></div>

                      </div>
                    </div>


                  </div>


                </el-carousel-item>
              </el-carousel>
            </div>

          </div>
          <div ref="bottom">
            <bottomTip></bottomTip>
          </div>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialog"
            @hideDialog="hideConsult"
        ></consultTip>
        <resultTip
            :dialogVisible="dialogVisible"
            @hideDialog="hideDialog"
        ></resultTip>
      </div>
    </div>
  </div>


</template>


<script>

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import resultTip from "@/components/ResultTip.vue";
import consultTip from "@/components/consultTip.vue";
import {shareUrl} from "@/utils/vxshare";
export default {

  name: "",

  props: [],

  components: {resultTip,consultTip, PartnerTip, headtip,bottomTip},

  data() {

    return {
      pageTitle: 'Intelligent Oil & Gas production solutions_Oil and gas development operations GPT application project services-Oil and Gas Communication GPT',
      pageDescription: 'Anton is a professional integrated service provider of oil recovery and gas technology, with high-quality and experienced oil recovery and gas technology service team, professional design software, international standard management system, through the integration of global oil recovery technology resources, to provide domestic and foreign oilfields with the whole life cycle of the drainage gas extraction process technology services, oilfield chemical products and technology services, artificial lift products and technology and oilfield operation and maintenance services, special oil recovery technical services, etc.',
      pageKeywords: 'Intelligent Oil & Gas production, intelligent oil production engineering, fracturing construction parameter optimization, intelligent layered injection and production, intelligent artificial lift, intelligent gas production engineering, intelligent well group energy optimization management, intelligent plunger drainage gas production, intelligent foam drainage gas production, Intelligent Oil & Gas production explanation, intelligent Oil & Gas production design, Oil & Gas development operation GPT, Oil & Gas communication GPT',

      dialog:false,
      scrollback:false,
      model:false,
      dialogconsult:false,
      dialogVisible:false,
      success:[{
        img:require("@/assets/success5.png"),
        title1:"Intelligent Layered Injection Service",
        title2:"Application Performance",
        title3:"\n" +
            "For water-flooding development blocks, the NSGA-III downhole intelligent measurement and control system is used for refined layered injection and production. It collects real-time monitoring data such as downhole layered pressure, temperature, and flow rate of the water injection well group, and inputs it into the NSGA-III downhole intelligent measurement and control system brain.",
        title4: "In Shengli Oilfield and Liaohe Oilfield, we have carried out on-site application tests of the intelligent layered injection process, with a 100% success rate of intelligent adjustment of water nozzles and oil nozzles, and significant increase in production of well groups.",
      },{
        img:require("@/assets/success6.png"),
        title1:"Intelligent Well Group Energy Optimization Management Service",
        title2:"Application Achievements",
        title3:"\n" +
            "By collecting dynamic production data and data such as pressure, accumulation velocity, and accumulation height from gas wells, and inputting it into the intelligent well cluster energy optimization management process brain, the system intelligently optimizes the distribution of energy within the well group. Utilizing high-pressure gas wells within the platform to perform gas-lifting for liquid removal in low-pressure gas wells.",
         title4: "The system has been tried on the shale gas platform of Changning Company and successfully activated several low-pressure gas wells that had been shut down due to fluid accumulation, and the daily gas production of the platform has increased by 33% on average, and the daily water production has increased by 28%.",

      },
      ],
      successMobel:[{
        img:require("@/assets/success5.png"),
        title1:"Intelligent Layered Injection Service",
        title2:"Application Performance",
        title3:"The collected data is input into the process brain, which intelligently optimizes the energy distribution of the well group, supports drainage with supporting devices and processes, accurately optimizes the operation system, and improves drainage and gas production efficiency.",
        title4: "This system was trialled on the Changning Company's shale gas platform, and the daily gas production increased by an average of 33%, and the daily water production increased by 28%.",
      },{
        img:require("@/assets/success6.png"),
        title1:"Intelligent Well Group Energy Optimization Management Service",
        title3: "By collecting dynamic production data and data such as pressure, accumulation velocity, and accumulation height from gas wells, and inputting it into the intelligent well cluster energy optimization management process brain, the system intelligently optimizes the distribution of energy within the well group. Utilizing high-pressure gas wells within the platform to perform gas-lifting for liquid removal in low-pressure gas wells.",

      },
      ],
    }

  },

  methods: {
    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    hideConsult(){
      this.dialog = false;
    },
    showPartner(){
      this.dialogconsult=true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
 handleScroll() {
      if (this.model === false) {
        this.scrollHeight = this.$refs.total.scrollTop;
      } else {
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if (this.scrollHeight < 100) {
        this.scrollTrue = true
      } else {
        this.scrollTrue = false
      }
      if (this.scrollHeight < 50) {
        this.scrollback = false
      } else {
        this.scrollback = true
      }
      console.log("gaodu", this.scrollHeight < 100)
    },
  },
  //进入该页面时，用之前保存的滚动位置赋值
  beforeDestroy() {
    // 移除事件监听器
    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);

    document.title=this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);

    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }
    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: 'Intelligent Oil & Gas production solutions_Oil and gas development operations GPT application project services-Oil and Gas Communication GPT',
        pageDescription: 'Anton is a professional integrated service provider of oil recovery and gas technology, with high-quality and experienced oil recovery and gas technology service team, professional design software, international standard management system, through the integration of global oil recovery technology resources, to provide domestic and foreign oilfields with the whole life cycle of the drainage gas extraction process technology services, oilfield chemical products and technology services, artificial lift products and technology and oilfield operation and maintenance services, special oil recovery technical services, etc.',
        pageKeywords: 'Intelligent Oil & Gas production, intelligent oil production engineering, fracturing construction parameter optimization, intelligent layered injection and production, intelligent artificial lift, intelligent gas production engineering, intelligent well group energy optimization management, intelligent plunger drainage gas production, intelligent foam drainage gas production, Intelligent Oil & Gas production explanation, intelligent Oil & Gas production design, Oil & Gas development operation GPT, Oil & Gas communication GPT',

      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .topback{
    position: fixed;
    z-index:999999999999;
    width: 100vw;
    padding: 0 0 0 2.86077411900635vw;
  }
  .makeHole1 {
    //z-index: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    .background{
      padding: 7.84301606922126vh 2.86077411900635vw  2.84301606922126vh 7.86077411900635vw;
      width: 100%;
      height: 72.41285537700865vh;
      background-image: url("../../assets/oil1.png");
      background-position: center;
      background-size:cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */
      .titlle1 {
        margin-top: 15.09270704573548vh;
        text-align: center;
        font-size: 2vw;
         font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #111111;
        line-height: 2.84301606922126vh

      }
      .title4 {
        text-align: center;
        margin: 6.82076637824475vh auto 0 auto;
        width: 62vw;
        font-size: 1vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color:#111111;
        line-height: 4.31520395550062vh;
      }
      .titlle3 {
        margin: 8.07911001236094vh auto;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.9vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #ffffff;
        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 53px;
        width: fit-content;
        padding: 2.4624227441vh 1.1989601386vw;
        height: 4.5624227441vh;
        background: #2f6eff;
        border: #2f6eff 1px solid;

      }
      .titlle3:hover{

        color: #2168DB;
        border: #2168DB 1px solid;
        background: rgba(255, 255, 255, 0.5);
      }
    }
    .screenmake0{
      padding: 5.78739184177998vh 11.09763142692085vw;;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:6.23733003708282vh;
        opacity: 1;
        font-size: 1.7vw;
        //color: #53A3F7;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        line-height: 3.5vh

      }

      .heightLight2 {
        margin:0 auto 3vh auto;
        text-align: center;
        height:fit-content;
        font-weight: 400;
        color: #2C3E50;
        line-height: 3.5vh;
        width: 66.9693818602vw;
        font-size: 1vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      }
      .heightLight3{
        margin: 4.08739184177998vh auto 5.08739184177998vh auto;
       // cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 500;
        color: #2f6eff;
        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 53px;
        width: fit-content;
        padding: 2.4624227441vh 2.1989601386vw;
        height:7.5624227441vh;
        background: #B3D0FF;
        border: #B3D0FF 1px solid;
      }
      .line0{
        width: 40vw;
        margin:0 auto;
        border-top-right-radius: 63px;
        border-top-left-radius: 63px;
        border-top:  #B3D0FF 4px solid;
        border-left:  #B3D0FF 4px solid;
        border-right:  #B3D0FF 4px solid;;
        height: 5.831891223733vh;
      }
      .grid0{
        width: 57vw;
        margin:0 auto;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        //column-gap: 1vw;
        .card{
          position: relative;
          margin: 0 auto;
          text-align: center;
          .line{
            position: absolute;
            height: 3.831891223733vh;
            width: 4px;
            left: 50%;
            top:-3.831891223733vh;
            transform: translateX(-50%);
            background-color: #B3D0FF;
            margin:0 auto;
          }
          .line1{
            position: absolute;
            height: 12.663782447466vh;
            width: 4px;
            left: 50%;
            top:-12.663782447466vh;
            transform: translateX(-50%);
            background-color: #B3D0FF;
            margin:0 auto;
          }
          .heightLight3{
            margin:0 auto;

           // cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 500;
            color: #2f6eff;
            line-height: 3.3374536465vh;
            text-align: center;
            border-radius: 53px;
            width: fit-content;
            padding: 2.4624227441vh 2.1989601386vw;
            height:8.5624227441vh;
            background: transparent;
            border: #B3D0FF 4px solid;
          }
        }


      }
      .grid{
        margin-top:4.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        //column-gap: 1vw;
        .card{
         // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:30.72311495673671vh ;
            background: #2F6EFF linear-gradient(to right, #CFE1FF 0%, #F0F6FF 100%);
            display: flex;

            .titleDesign{
              padding: 2.8124845488vh 2.0597920277vw 7.1124845488vh 2.0597920277vw;
              .Title1{
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                height: 5vh;
                font-size: 1vw;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                //text-align: justify;
                margin-top: 1.5124845488vh;
                height: 4.6971569839vh;
                font-size: 0.9vw;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }



        }
        .card1{
         // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background: #FFFFFF;
            margin-bottom: 1.5248454883vh;
            .imgDesign{
              text-align: center;
              height:54vh;
              width: 100%;
              background-image: url("../../assets/serve1.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 1.5248454883vh 1.5248454883vw;
              background-color: #2F6EFF;
              .Title1{
                color: white;
                text-align: center;
                font-size: 1vw;

                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 700;
              }

            }
            .backconsult{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
              width: 100%;

            }
            .backconsultArrow{
              position: absolute;
                bottom: 1.5vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 90%;
              transform: translateX(-50%);
            }

          }

        }

      }
    }
    .screenmake{
      padding: 5.78739184177998vh 11.09763142692085vw;;
      width: 100%;
      height:fit-content;
      background: #ebf1fd;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:6.23733003708282vh;
        font-size: 1.7vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #FF6400;
        line-height: 3.5vh
      }

      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        column-gap:0;
        .card{
         // cursor: pointer;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:58vh;
            border-radius: 9px ;;
            background: #FFFFFF;
            border: white 2px solid;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              width: 100%;
              height: 30.06056860321384vh;
              opacity: 1;
              background-image: url("../../assets/oil2.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 2.8124845488vh 2.0597920277vw 7.1124845488vh 2.0597920277vw;
              .Title1{
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                height: 5vh;
                font-size: 1vw;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                //text-align: justify;
                margin-top: 1.5124845488vh;
                height: 4.6971569839vh;
                font-size: 0.9vw;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }

        .card1{
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
         // cursor: pointer;
          opacity: 1;

          .back{
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:58vh;
            border-radius: 9px ;;
            background: #FFFFFF;
            border: white 2px solid;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              width: 100%;
              height: 30.06056860321384vh;
              opacity: 1;
              background-image: url("../../assets/oil3.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 2.8124845488vh 2.0597920277vw 7.1124845488vh 2.0597920277vw;
              .Title1{
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                height: 5vh;
                font-size: 1vw;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                //text-align: justify;
                margin-top: 1.5124845488vh;
                height: 4.6971569839vh;
                font-size: 0.9vw;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }

        .card2{
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
         // cursor: pointer;
          opacity: 1;
          .back{
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:58vh;
            border-radius: 9px ;;
            background: #FFFFFF;
            border: white 2px solid;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              width: 100%;
              height: 30.06056860321384vh;
              opacity: 1;
              background-image: url("../../assets/oil4.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 2.8124845488vh 2.0597920277vw 7.1124845488vh 2.0597920277vw;
              .Title1{
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                height: 5vh;
                font-size: 1vw;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                //text-align: justify;
                margin-top: 1.5124845488vh;
                height: 4.6971569839vh;
                font-size: 0.9vw;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }

      }
    }
    .screenmake2{
      padding: 5.78739184177998vh 11.09763142692085vw;;
      width: 100%;
      height:fit-content;
      background: white;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:6.23733003708282vh;
        font-size: 1.7vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #FF6400;
        line-height: 3.5vh

      }

      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        column-gap:0;
        .card{
         // cursor: pointer;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:60vh;
            border-radius: 9px ;;
            background: #FFFFFF;
            border: white 1px solid;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              width: 100%;
              height: 30.06056860321384vh;
              opacity: 1;
              background-image: url("../../assets/oil5.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 2.8124845488vh 2.0597920277vw 7.1124845488vh 2.0597920277vw;
              .Title1{
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                height: 5vh;
                font-size: 1vw;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                //text-align: justify;
                margin-top: 1.5124845488vh;
                height: 4.6971569839vh;
                font-size: 0.9vw;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }

        .card1{
         // cursor: pointer;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:60vh;
            border-radius: 9px ;;
            background: #FFFFFF;
            border: white 1px solid;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              width: 100%;
              height: 30.06056860321384vh;
              opacity: 1;
              background-image: url("../../assets/oil6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 2.8124845488vh 2.0597920277vw 7.1124845488vh 2.0597920277vw;
              .Title1{
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                height: 5vh;
                font-size: 1vw;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                //text-align: justify;
                margin-top: 1.5124845488vh;
                height: 4.6971569839vh;
                font-size: 0.9vw;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }

        .card2{
         // cursor: pointer;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:60vh;
            border-radius: 9px ;;
            background: #FFFFFF;
            border: white 1px solid;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              width: 100%;
              height: 30.06056860321384vh;
              opacity: 1;
              background-image: url("../../assets/oil7.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 2.8124845488vh 2.0597920277vw 7.1124845488vh 2.0597920277vw;
              .Title1{
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                height: 5vh;
                font-size: 1vw;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                //text-align: justify;
                margin-top: 1.5124845488vh;
                height: 4.6971569839vh;
                font-size: 0.9vw;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }

      }
    }
    .screenmake3{
      padding: 5.78739184177998vh 11.09763142692085vw;;
      width: 100%;
      height:fit-content;
      background: #ebf1fd;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:6.23733003708282vh;
        font-size: 1.7vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #FF6400;
        line-height: 3.5vh

      }

      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        .card{
          transition:transform 0.5s ease-out;
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:46vh ;
            border-radius: 9px ;;
            overflow:hidden;
            background: #FFFFFF linear-gradient(to bottom, #CFE1FF 0%, #F0F6FF 100%);
            border: white 1px solid;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              margin: 3.1124845488vh auto;
              text-align: center;
              height: 8.3152039555vh;
              width: 8.3152039555vh;
              background-image: url("../../assets/icon-01.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1.3248454883vw;
              .Title1{
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 2.8124845488vh;
                text-align: center;
                font-size: 0.9vw;
                height: 6vh;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 700;
                line-height: 3vh;
              }
              .Title2{
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 5; /* 限制在一个块元素显示的文本的行数 */
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 2.0124845488vh;
                height: 16vh;
                //text-align: justify;
                font-size: 0.8243212016vw;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 500;
                line-height: 3.194066749vh;
              }
            }
            .backconsult {
              display: flex;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              align-items: center;
              justify-content: center;
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
              width: 100%;
              color: #2168DB;
            }

            .backconsultArrow{
              position: absolute;
                bottom: 1.5vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 70%;
            }

          }
        }
        .card:hover{
          transform: translateY(-10px);
        }
        .card1{
          cursor: pointer;
          transition:transform 0.5s ease-out;
          width: 100%;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:46vh;
            border-radius: 9px ;;
            overflow:hidden;
            background: #FFFFFF linear-gradient(to bottom, #CFE1FF 0%, #F0F6FF 100%);
            border: white 1px solid;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              margin: 3.1124845488vh auto;
              text-align: center;
              height: 8.3152039555vh;
              width: 8.3152039555vh;
              background-image: url("../../assets/icon-02.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1.3248454883vw;
              .Title1{
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 2.8124845488vh;
                text-align: center;
                font-size: 0.9vw;
                height: 6vh;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 700;
                line-height: 3vh;
              }
              .Title2{
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 5; /* 限制在一个块元素显示的文本的行数 */
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 2.0124845488vh;
                height: 16vh;
                //text-align: justify;
                font-size: 0.8243212016vw;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 500;
                line-height: 3.194066749vh;
              }
            }
            .backconsult {
              display: flex;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              align-items: center;
              justify-content: center;
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
              width: 100%;
              color: #2168DB;
            }

            .backconsultArrow{
              position: absolute;
              bottom: 1.5vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 70%;
            }

          }
        }
        .card1:hover{
          transform: translateY(-10px);
        }
        .card2{
          transition:transform 0.5s ease-out;
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:46vh ;
            border-radius: 9px ;;
            overflow:hidden;
            background: #FFFFFF linear-gradient(to bottom, #CFE1FF 0%, #F0F6FF 100%);
            border: white 1px solid;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              margin: 3.1124845488vh auto;
              text-align: center;
              height: 8.3152039555vh;
              width: 8.3152039555vh;
              background-image: url("../../assets/icon-03.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1.3248454883vw;
              .Title1{
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 2.8124845488vh;
                text-align: center;
                font-size: 0.9vw;
                height: 6vh;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 700;
                line-height: 3vh;
              }
              .Title2{
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 5; /* 限制在一个块元素显示的文本的行数 */
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 2.0124845488vh;
                height: 16vh;
                //text-align: justify;
                font-size: 0.8243212016vw;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 500;
                line-height: 3.194066749vh;
              }
            }
            .backconsult {
              display: flex;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              align-items: center;
              justify-content: center;
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
              width: 100%;
              color: #2168DB;
            }

            .backconsultArrow{
              position: absolute;
              bottom: 1.5vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 70%;
            }

          }
        }
        .card2:hover{
          transform: translateY(-10px);
        }
        .card3{
          transition:transform 0.5s ease-out;
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:46vh ;
            border-radius: 9px ;;
            overflow:hidden;
            background: #FFFFFF linear-gradient(to bottom, #CFE1FF 0%, #F0F6FF 100%);
            border: white 1px solid;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              margin: 3.1124845488vh auto;
              text-align: center;
              height: 8.3152039555vh;
              width: 8.3152039555vh;
              background-image: url("../../assets/icon-04.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1.3248454883vw;
              .Title1{
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 2.8124845488vh;
                text-align: center;
                font-size: 0.9vw;
                height: 6vh;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 700;
                line-height: 3vh;
              }
              .Title2{
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 5; /* 限制在一个块元素显示的文本的行数 */
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 2.0124845488vh;
                height: 16vh;
                //text-align: justify;
                font-size: 0.8243212016vw;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 500;
                line-height: 3.194066749vh;
              }
            }
            .backconsult {
              display: flex;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              align-items: center;
              justify-content: center;
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
              width: 100%;
              color: #2168DB;
            }

            .backconsultArrow{
              position: absolute;
              bottom: 1.5vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 70%;
            }

          }
        }
        .card3:hover{
          transform: translateY(-10px);
        }
        .card4{
          transition:transform 0.5s ease-out;
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:46vh ;
            border-radius: 9px ;;
            overflow:hidden;
            background: #FFFFFF linear-gradient(to bottom, #CFE1FF 0%, #F0F6FF 100%);
            border: white 1px solid;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              margin: 3.1124845488vh auto;
              text-align: center;
              height: 8.3152039555vh;
              width: 8.3152039555vh;
              background-image: url("../../assets/icon-05.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1.3248454883vw;
              .Title1{
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 2.8124845488vh;
                text-align: center;
                font-size: 0.9vw;
                height: 6vh;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 700;
                line-height: 3vh;
              }
              .Title2{
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 5; /* 限制在一个块元素显示的文本的行数 */
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 2.0124845488vh;
                height: 16vh;
                //text-align: justify;
                font-size: 0.8243212016vw;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 500;
                line-height: 3.194066749vh;
              }
            }
            .backconsult {
              display: flex;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              align-items: center;
              justify-content: center;
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
              width: 100%;
              color: #2168DB;
            }

            .backconsultArrow{
              position: absolute;
              bottom: 1.5vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 70%;
            }

          }
        }
        .card4:hover{
          transform: translateY(-10px);
        }
      }
    }
    .screenmake4{
      padding: 5.78739184177998vh 11.09763142692085vw;;
      width: 100%;
      height:fit-content;
      background: white;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:6.23733003708282vh;
        font-size: 1.7vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #FF6400;
        line-height: 3.5vh

      }

      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);

        .card{
          transition:transform 0.5s ease-out;
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:48vh ;
            border-radius: 9px ;;
            overflow:hidden;
            background: #FFFFFF linear-gradient(to bottom, #CFE1FF 0%, #F0F6FF 100%);
            border: white 1px solid;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              margin: 3.1124845488vh auto;
              text-align: center;
              height: 8.3152039555vh;
              width: 8.3152039555vh;
              background-image: url("../../assets/icon-06.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1vw;
              .Title1{
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
                overflow: hidden;
                justify-content: center;
                align-items: center;
                text-overflow: ellipsis;
                margin-top: 2.8124845488vh;
                text-align: center;
                font-size: 0.9vw;
                height: 6vh;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 700;
                line-height: 3vh;
              }
              .Title2{
                margin-top: 1.0124845488vh;
                margin-left: 0;
                text-align: left;
                font-size: 0.8243212016vw;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 500;
                line-height: 3.194066749vh;
              }
            }
            .backconsult {
              display: flex;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              align-items: center;
              justify-content: center;
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
              width: 100%;
              color: #2168DB;
            }

            .backconsultArrow{
              position: absolute;
              bottom: 1.5vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 70%;
            }

          }
        }
        .card:hover{
          transform: translateY(-10px);
        }
        .card1{
          transition:transform 0.5s ease-out;
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:48vh ;
            border-radius: 9px ;;
            overflow:hidden;
            background: #FFFFFF linear-gradient(to bottom, #CFE1FF 0%, #F0F6FF 100%);
            border: white 1px solid;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              margin: 3.1124845488vh auto;
              text-align: center;
              height: 8.3152039555vh;
              width: 8.3152039555vh;
              background-image: url("../../assets/icon-07.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1vw;
              .Title1{
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
                overflow: hidden;
                justify-content: center;
                align-items: center;
                text-overflow: ellipsis;
                margin-top: 2.8124845488vh;
                text-align: center;
                font-size: 0.9vw;
                height: 6vh;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 700;
                line-height: 3vh;
              }
              .Title2{
                margin-top: 1.0124845488vh;
                margin-left: 0;
                text-align: left;
                font-size: 0.8243212016vw;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 500;
                line-height: 3.194066749vh;
              }
            }
            .backconsult {
              display: flex;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              align-items: center;
              justify-content: center;
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
              width: 100%;
              color: #2168DB;
            }

            .backconsultArrow{
              position: absolute;
              bottom: 1.5vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 70%;
            }


          }
        }
        .card1:hover{
          transform: translateY(-10px);
        }
        .card2{
          transition:transform 0.5s ease-out;
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:48vh ;
            border-radius: 9px ;;
            overflow:hidden;
            background: #FFFFFF linear-gradient(to bottom, #CFE1FF 0%, #F0F6FF 100%);
            border: white 1px solid;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              margin: 3.1124845488vh auto;
              text-align: center;
              height: 8.3152039555vh;
              width: 8.3152039555vh;
              background-image: url("../../assets/icon-08.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1vw;
              .Title1{
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
                overflow: hidden;

                text-overflow: ellipsis;
                margin-top: 2.8124845488vh;
                text-align: center;
                font-size: 0.9vw;
                height: 6vh;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 700;
                line-height: 3vh;
              }
              .Title2{
                margin-top: 1.0124845488vh;
                margin-left: 0;
                text-align: left;
                font-size: 0.8243212016vw;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 500;
                line-height: 3.194066749vh;
              }
            }
            .backconsult {
              display: flex;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              align-items: center;
              justify-content: center;
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
              width: 100%;
              color: #2168DB;
            }

            .backconsultArrow{
              position: absolute;
              bottom: 1.5vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 70%;
            }


          }
        }
        .card2:hover{
          transform: translateY(-10px);
        }
        .card3{
          transition:transform 0.5s ease-out;
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:48vh ;
            overflow:hidden;
            background: #FFFFFF linear-gradient(to bottom, #CFE1FF 0%, #F0F6FF 100%);
            border: white 1px solid;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              margin: 3.1124845488vh auto;
              text-align: center;
              height: 8.3152039555vh;
              width: 8.3152039555vh;
              background-image: url("../../assets/icon-09.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1vw;
              .Title1{
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
                overflow: hidden;
                justify-content: center;
                align-items: center;
                text-overflow: ellipsis;
                margin-top: 2.8124845488vh;
                text-align: center;
                font-size: 0.9vw;
                height: 6vh;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 700;
                line-height: 3vh;
              }
              .Title2{
                margin-top: 1.0124845488vh;
                margin-left: 0;
                text-align: left;
                font-size: 0.8243212016vw;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 500;
                line-height: 3.194066749vh;
              }
            }
            .backconsult {
              display: flex;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              align-items: center;
              justify-content: center;
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
              width: 100%;
              color: #2168DB;
            }

            .backconsultArrow{
              position: absolute;
              bottom: 1.5vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 70%;
            }


          }
        }
        .card3:hover{
          transform: translateY(-10px);
        }
        .card4{
          transition:transform 0.5s ease-out;
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:48vh ;
            overflow:hidden;
            background: #FFFFFF linear-gradient(to bottom, #CFE1FF 0%, #F0F6FF 100%);
            border: white 1px solid;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              margin: 3.1124845488vh auto;
              text-align: center;
              height: 8.3152039555vh;
              width: 8.3152039555vh;
              background-image: url("../../assets/icon-10.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1vw;
              .Title1{
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
                overflow: hidden;
                justify-content: center;
                align-items: center;
                text-overflow: ellipsis;
                margin-top: 2.8124845488vh;
                text-align: center;
                font-size: 0.9vw;
                height: 6vh;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 700;
                line-height: 3vh;
              }
              .Title2{
                margin-top: 1.0124845488vh;
                margin-left: 0;
                text-align: left;
                font-size: 0.8243212016vw;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 500;
                line-height: 3.194066749vh;
              }
            }
            .backconsult {
              display: flex;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              align-items: center;
              justify-content: center;
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
              width: 100%;
              color: #2168DB;
            }

            .backconsultArrow{
              position: absolute;
              bottom: 1.5vh;
              text-align: center;
              font-size: 0.8vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 70%;
            }

          }
        }
        .card4:hover{
          transform: translateY(-10px);
        }
      }
    }
    .screenmake5{
      padding: 7.78739184177998vh 5.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: #212a3c;
      //background-color: #ebf1fd;
      .heighLight{
        color:white;
        text-align: center;
        height: 7.54017305315204vh;
        font-size: 1.7vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        //color: #2168DB;
        line-height: 2.71940667490729vh;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        .card{
          //cursor: pointer;
          margin:0 auto;
          height:85vh;
          width: 86%;
          padding:0.98887515451174vh 0 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:70vh;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: fit-content;
              height:100%;
              opacity: 1;
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 1.5248454883vh 1.5248454883vw;
              color: white;
              width: 35vw;
              margin:auto 0 auto 0;
              float: right;
              .title1{
                font-size: 1vw;
              }
              .title2{
                ////text-align: justify;
                margin-top:5px;
                line-height: 3vh;
                font-size: 0.9vw;
              }
              .success{
                display: grid;
                grid-template-columns: minmax(0,1fr) minmax(0,1fr);
                //column-gap: 1vw;
                margin-top: 1vh;
                .suc1{
                  width: 100%;
                  height: 8vh;
                  .title3{
                    text-align: center;
                    margin-top: 2vh;
                    font-size: 0.8243212016vw;
                  }

                }

              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height:72vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 550px;
        }
        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0  ;
         // cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight:800;
          font-size: 25px;
        }
      }
    }
  }

}
.totalmakeHoleModel{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    //z-index: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    .background{
      //z-index: 3;
      position: relative;
      width: 100%;
      height: 460px;
      padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 7.86077411900635vw;
      background-image: url("../../assets/gptModel41.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */
      .titlle1 {
        margin:50px auto;
        width: fit-content;
        text-align: center;
        height: 23px;
        font-size: 23px;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 500;
        color: #111111;
        line-height: 32px;
      }
      .titlle3{
        position: absolute;
        bottom:75px;
        left:50%;
        transform: translateX(-60%);
        font-size: 12px;
         font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        height: 35px;
        color:white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }
      .title4{
        position: absolute;
        left:10%;
        bottom:-85px;
        margin: 35px auto 0 auto;
        width: 80%;
        height: fit-content;
        font-size: 12px;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #111111;
        line-height: 18px;
        border-radius: 15px;
        padding: 15px;
        background:white;
        border: #e8eefa 1px solid;
      }
    }
    .screenmake0{
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:90px 4.6875vw 20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 700;
        line-height: 20px;
      }
      .heightLight2{
        margin:19px 2vw;
        //text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 12px;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #2C3E50;
        line-height:22px;
      }

      .heightLight3{
        margin: 0 auto 25px auto;
       // cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size:12px;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 500;
        color: #2f6eff;
        line-height: 22px;
        text-align: center;
        border-radius: 9px;
        width: fit-content;
        padding: 10px 15px;
        height:40px;
        background: #B3D0FF;
        border: #B3D0FF 1px solid;
      }
      .line0{
        width: 245px;
        border-top-right-radius: 63px;
        border-top-left-radius: 63px;
        border-top:  #B3D0FF 4px solid;
        border-left:  #B3D0FF 4px solid;
        border-right:  #B3D0FF 4px solid;;
        height: 35px;
        margin:0 auto;
      }
      .grid0{
        width: 350px;
        margin:0 auto;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        column-gap: 5px;

        .card{
          position: relative;
          margin: 0 auto;
          text-align: center;
          .line{
            position: absolute;
            height: 35px;
            width: 4px;
            left: 50%;
            top:-35px;
            transform: translateX(-50%);
            background-color: #B3D0FF;
            margin:0 auto;
          }
          .line1{
            position: absolute;
            height: 75px;
            width: 4px;
            left: 50%;
            top:-75px;
            transform: translateX(-50%);
            background-color: #B3D0FF;
            margin:0 auto;
          }
          .heightLight3{
            margin:0 auto;

           // cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 8px;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 500;
            color: #2f6eff;
            line-height: 16px;
            text-align: center;
            border-radius: 9px;
            width: fit-content;
            padding: 5px 5px;
            height:45px;
            background: transparent;
            border: #B3D0FF 4px solid;
          }
        }


      }

    }
    .screenmake{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background:  #ebf1fd;;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 21px;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #FF6400;
        line-height: 20px;

      }


      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        row-gap: 15px;
        .card{
         // cursor: pointer;
          height:fit-content ;
          padding:5px 5px;
          opacity: 1;
          .back{
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            border-radius: 9px ;;
            background: #FFFFFF;
            .imgDesign{
              width: 100%;
              height: 230px;
              opacity: 1;
              background-image: url("../../assets/oil2.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 5px 15px;
              .Title1{
                margin: 10px auto auto 8px;
                text-align: center;
                height:fit-content;
                font-size:12px;
               font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 10px 8px 20px 8px;
                //text-align: justify;
                height: fit-content;
                font-size: 12px;
                font-weight: 400;
                line-height: 22px;
              }
            }
          }
        }
        .card1{
         // cursor: pointer;
          height:fit-content ;
          padding:5px 5px;
          opacity: 1;
          .back{
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            border-radius: 9px ;;
            background: #FFFFFF;
            .imgDesign{
              width: 100%;
              height: 230px;
              opacity: 1;
              background-image: url("../../assets/oil3.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 5px 15px;
              .Title1{
                margin: 10px auto auto 8px;
                text-align: center;
                height:fit-content;
                font-size:12px;
               font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 10px 8px 20px 8px;
                //text-align: justify;
                height: fit-content;
                font-size: 12px;
                font-weight: 400;
                line-height: 22px;
              }
            }
          }
        }
        .card2{
         // cursor: pointer;
          height:fit-content ;
          padding:5px 5px;
          opacity: 1;
          .back{
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            border-radius: 9px ;;
            background: #FFFFFF;
            .imgDesign{
              width: 100%;
              height: 230px;
              opacity: 1;
              background-image: url("../../assets/oil4.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 5px 15px;
              .Title1{
                margin: 10px auto auto 8px;
                text-align: center;
                height:fit-content;
                font-size:12px;
               font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 10px 8px 20px 8px;
                //text-align: justify;
                height: fit-content;
                font-size: 12px;
                font-weight: 400;
                line-height: 22px;
              }
            }
          }
        }
      }
    }

    .screenmake2{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: white;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 21px;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #FF6400;
        line-height: 20px;

      }


      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        row-gap: 15px;
        .card{
         // cursor: pointer;
          height:fit-content ;
          padding:5px 5px;
          opacity: 1;
          .back{
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            border-radius: 9px ;;
            background:  #ebf1fd;;
            .imgDesign{
              width: 100%;
              height: 230px;
              opacity: 1;
              background-image: url("../../assets/oil5.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 5px 15px;
              .Title1{
                margin: 10px auto auto 8px;
                text-align: center;
                height:fit-content;
                font-size:12px;
               font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 10px 8px 20px 8px;
                //text-align: justify;
                height: fit-content;
                font-size: 12px;
                font-weight: 400;
                line-height: 22px;
              }
            }
          }
        }
        .card1{
         // cursor: pointer;
          height:fit-content ;
          padding:5px 5px;
          opacity: 1;
          .back{
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            border-radius: 9px ;;
            background:  #ebf1fd;;
            .imgDesign{
              width: 100%;
              height: 230px;
              opacity: 1;
              background-image: url("../../assets/oil6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 5px 15px;
              .Title1{
                margin: 10px auto auto 8px;
                text-align: center;
                height:fit-content;
                font-size:12px;
               font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 10px 8px 20px 8px;
                //text-align: justify;
                height: fit-content;
                font-size: 12px;
                font-weight: 400;
                line-height: 22px;
              }
            }
          }
        }
        .card2{
         // cursor: pointer;
          height:fit-content ;
          padding:5px 5px;
          opacity: 1;
          .back{
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            border-radius: 9px ;;
            background:  #ebf1fd;;
            .imgDesign{
              width: 100%;
              height: 230px;
              opacity: 1;
              background-image: url("../../assets/oil7.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 5px 15px;
              .Title1{
                margin: 10px auto auto 8px;
                text-align: center;
                height:fit-content;
                font-size:12px;
               font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 10px 8px 20px 8px;
                //text-align: justify;
                height: fit-content;
                font-size: 12px;
                font-weight: 400;
                line-height: 22px;
              }
            }
          }
        }
      }
    }

    .screenmake3{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: #ebf1fd;;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 21px;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #FF6400;
        line-height: 20px;

      }

      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        .card{
          cursor: pointer;
          width: 100%;
          min-height: fit-content;
          padding: 7.5px 6px;
          opacity: 1;
          position: relative;
          .back{
            width: 100%;
            height: 100%;
            background-color: white;;
            padding: 7px 15px;
            box-shadow: 5px 0 2px 0px #D4E4FF;
            //border: 2px solid #FFFFFF;
            border-radius: 9px ;;
            display: flex;
            .imgDesign{
              border-radius: 9px;
              opacity: 1.25;
              display: flex;
              justify-content: center;
              text-align: center;
              width: 58px;
              height: 100%;
              background-image: url("../../assets/icon-01.png");
              background-position: center;
              background-size:contain;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              width: 80%;
              padding: 0 7px 0 15px;
              .Title1{
                opacity: 4;
                width:100%;
                //white-space: nowrap; //换行
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 7.5px;
                text-align: left;
                justify-content: center;
                align-items: center;
                font-size:12px;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: bold;
                line-height: 20px;
              }
              .Title2{
                opacity: 4;
                width: 230px;
                margin: 2px 0 10px 0;
                //text-align: justify;
                font-size: 12px;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 500;
                line-height: 15px;
              }
            }
          }
        }
        .card1{
          cursor: pointer;
          width: 100%;
          min-height: fit-content;
          padding: 7.5px 6px;
          opacity: 1;
          position: relative;
          .back{
            width: 100%;
            height: 100%;
            background-color: white;;
            padding: 7px 15px;
            box-shadow: 5px 0 2px 0px #D4E4FF;
            //border: 2px solid #FFFFFF;
            border-radius: 9px ;;
            display: flex;
            .imgDesign{
              border-radius: 9px;
              opacity: 1.25;
              display: flex;
              justify-content: center;
              text-align: center;
              width: 58px;
              height: 100%;
              background-image: url("../../assets/icon-02.png");
              background-position: center;
              background-size:contain;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              width: 80%;
              padding: 0 7px 0 15px;
              .Title1{
                opacity: 4;
                width:100%;
                //white-space: nowrap; //换行
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 7.5px;
                text-align: left;
                justify-content: center;
                align-items: center;
                font-size:12px;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: bold;
                line-height: 20px;
              }
              .Title2{
                opacity: 4;
                width: 230px;
                margin: 2px 0 10px 0;
                //text-align: justify;
                font-size: 12px;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 500;
                line-height: 15px;
              }
            }
          }
        }
        .card2{
          cursor: pointer;
          width: 100%;
          min-height: fit-content;
          padding: 7.5px 6px;
          opacity: 1;
          position: relative;
          .back{
            width: 100%;
            height: 100%;
            background-color: white;;
            padding: 7px 15px;
            box-shadow: 5px 0 2px 0px #D4E4FF;
            //border: 2px solid #FFFFFF;
            border-radius: 9px ;;
            display: flex;
            .imgDesign{
              border-radius: 9px;
              opacity: 1.25;
              display: flex;
              justify-content: center;
              text-align: center;
              width: 58px;
              height: 100%;
              background-image: url("../../assets/icon-03.png");
              background-position: center;
              background-size:contain;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              width: 80%;
              padding: 0 7px 0 15px;
              .Title1{
                opacity: 4;
                width:100%;
                //white-space: nowrap; //换行
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 7.5px;
                text-align: left;
                justify-content: center;
                align-items: center;
                font-size:12px;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: bold;
                line-height: 20px;
              }
              .Title2{
                opacity: 4;
                width: 230px;
                margin: 2px 0 10px 0;
                //text-align: justify;
                font-size: 12px;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 500;
                line-height: 15px;
              }
            }
          }
        }
        .card3{
          cursor: pointer;
          width: 100%;
          min-height: fit-content;
          padding: 7.5px 6px;
          opacity: 1;
          position: relative;
          .back{
            width: 100%;
            height: 100%;
            background-color: white;;
            padding: 7px 15px;
            box-shadow: 5px 0 2px 0px #D4E4FF;
            //border: 2px solid #FFFFFF;
            border-radius: 9px ;;
            display: flex;
            .imgDesign{
              border-radius: 9px;
              opacity: 1.25;
              display: flex;
              justify-content: center;
              text-align: center;
              width: 58px;
              height: 100%;
              background-image: url("../../assets/icon-04.png");
              background-position: center;
              background-size:contain;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              width: 80%;
              padding: 0 7px 0 15px;
              .Title1{
                opacity: 4;
                width:100%;
                //white-space: nowrap; //换行
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 7.5px;
                text-align: left;
                justify-content: center;
                align-items: center;
                font-size:12px;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: bold;
                line-height: 20px;
              }
              .Title2{
                opacity: 4;
                width: 230px;
                margin: 2px 0 10px 0;
                //text-align: justify;
                font-size: 12px;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 500;
                line-height: 15px;
              }
            }
          }
        }
        .card4{
          cursor: pointer;
          width: 100%;
          min-height: fit-content;
          padding: 7.5px 6px;
          opacity: 1;
          position: relative;
          .back{
            width: 100%;
            height: 100%;
            background-color: white;;
            padding: 7px 15px;
            box-shadow: 5px 0 2px 0px #D4E4FF;
            //border: 2px solid #FFFFFF;
            border-radius: 9px ;;
            display: flex;
            .imgDesign{
              border-radius: 9px;
              opacity: 1.25;
              display: flex;
              justify-content: center;
              text-align: center;
              width: 58px;
              height: 100%;
              background-image: url("../../assets/icon-05.png");
              background-position: center;
              background-size:contain;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              width: 80%;
              padding: 0 7px 0 15px;
              .Title1{
                opacity: 4;
                width:100%;
                //white-space: nowrap; //换行
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 7.5px;
                text-align: left;
                justify-content: center;
                align-items: center;
                font-size:12px;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: bold;
                line-height: 20px;
              }
              .Title2{
                opacity: 4;
                width: 230px;
                margin: 2px 0 10px 0;
                //text-align: justify;
                font-size: 12px;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 500;
                line-height: 15px;
              }
            }
          }
        }


      }
    }

    .screenmake4{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: white;;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #FF6400;
        line-height: 20px;

      }

      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        .card{
          cursor: pointer;
          width: 100%;
          min-height: fit-content;
          padding: 7.5px 6px;
          opacity: 1;
          position: relative;
          .back{
            width: 100%;
            height: 100%;
            background:#FFFFFF linear-gradient(to right, #CFE1FF 0%, #F0F6FF 100%);
            padding: 7px 15px;
            box-shadow: 5px 0 2px 0px #D4E4FF;
            //border: 2px solid #FFFFFF;
            border-radius: 9px ;;
            display: flex;
            .imgDesign{
              border-radius: 9px;
              opacity: 1.25;
              display: flex;
              justify-content: center;
              text-align: center;
              width: 58px;
              height: 100%;
              background-image: url("../../assets/icon-06.png");
              background-position: center;
              background-size:contain;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              width: 80%;
              padding: 0 7px 0 15px;
              .Title1{
                opacity: 4;
                width:100%;
                //white-space: nowrap; //换行
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 7.5px;
                text-align: left;
                justify-content: center;
                align-items: center;
                font-size:12px;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: bold;
                line-height: 20px;
              }
              .Title2{
                opacity: 4;
                width: 230px;
                margin: 2px 0 10px 0;
                //text-align: justify;
                font-size: 12px;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 500;
                line-height: 15px;
              }
            }
          }
        }
        .card1{
          cursor: pointer;
          width: 100%;
          min-height: fit-content;
          padding: 7.5px 6px;
          opacity: 1;
          position: relative;
          .back{
            width: 100%;
            height: 100%;
            background:#FFFFFF linear-gradient(to right, #CFE1FF 0%, #F0F6FF 100%);
            padding: 7px 15px;
            box-shadow: 5px 0 2px 0px #D4E4FF;
            //border: 2px solid #FFFFFF;
            border-radius: 9px ;;
            display: flex;
            .imgDesign{
              border-radius: 9px;
              opacity: 1.25;
              display: flex;
              justify-content: center;
              text-align: center;
              width: 58px;
              height: 100%;
              background-image: url("../../assets/icon-07.png");
              background-position: center;
              background-size:contain;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              width: 80%;
              padding: 0 7px 0 15px;
              .Title1{
                opacity: 4;
                width:100%;
                //white-space: nowrap; //换行
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 7.5px;
                text-align: left;
                justify-content: center;
                align-items: center;
                font-size:12px;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: bold;
                line-height: 20px;
              }
              .Title2{
                opacity: 4;
                width: 230px;
                margin: 2px 0 10px 0;
                //text-align: justify;
                font-size: 12px;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 500;
                line-height: 15px;
              }
            }
          }
        }
        .card2{
          cursor: pointer;
          width: 100%;
          min-height: fit-content;
          padding: 7.5px 6px;
          opacity: 1;
          position: relative;
          .back{
            width: 100%;
            height: 100%;
            background:#FFFFFF linear-gradient(to right, #CFE1FF 0%, #F0F6FF 100%);
            padding: 7px 15px;
            box-shadow: 5px 0 2px 0px #D4E4FF;
            //border: 2px solid #FFFFFF;
            border-radius: 9px ;;
            display: flex;
            .imgDesign{
              border-radius: 9px;
              opacity: 1.25;
              display: flex;
              justify-content: center;
              text-align: center;
              width: 58px;
              height: 100%;
              background-image: url("../../assets/icon-08.png");
              background-position: center;
              background-size:contain;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              width: 80%;
              padding: 0 7px 0 15px;
              .Title1{
                opacity: 4;
                width:100%;
                //white-space: nowrap; //换行
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 7.5px;
                text-align: left;
                justify-content: center;
                align-items: center;
                font-size:12px;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: bold;
                line-height: 20px;
              }
              .Title2{
                opacity: 4;
                width: 230px;
                margin: 2px 0 10px 0;
                //text-align: justify;
                font-size: 12px;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 500;
                line-height: 15px;
              }
            }
          }
        }
        .card3{
          cursor: pointer;
          width: 100%;
          min-height: fit-content;
          padding: 7.5px 6px;
          opacity: 1;
          position: relative;
          .back{
            width: 100%;
            height: 100%;
            background:#FFFFFF linear-gradient(to right, #CFE1FF 0%, #F0F6FF 100%);
            padding: 7px 15px;
            box-shadow: 5px 0 2px 0px #D4E4FF;
            //border: 2px solid #FFFFFF;
            border-radius: 9px ;;
            display: flex;
            .imgDesign{
              border-radius: 9px;
              opacity: 1.25;
              display: flex;
              justify-content: center;
              text-align: center;
              width: 58px;
              height: 100%;
              background-image: url("../../assets/icon-09.png");
              background-position: center;
              background-size:contain;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 7px 0 15px;
              width: 80%;
              .Title1{
                opacity: 4;
                width:100%;
                //white-space: nowrap; //换行
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 7.5px;
                text-align: left;
                justify-content: center;
                align-items: center;
                font-size:12px;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: bold;
                line-height: 20px;
              }
              .Title2{
                opacity: 4;
                width: 230px;
                margin: 2px 0 10px 0;
                //text-align: justify;
                font-size: 12px;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 500;
                line-height: 15px;
              }
            }
          }
        }
        .card4{
          cursor: pointer;
          width: 100%;
          min-height: fit-content;
          padding: 7.5px 6px;
          opacity: 1;
          position: relative;
          .back{
            width: 100%;
            height: 100%;
            background:#FFFFFF linear-gradient(to right, #CFE1FF 0%, #F0F6FF 100%);
            padding: 7px 15px;
            box-shadow: 5px 0 2px 0px #D4E4FF;
            //border: 2px solid #FFFFFF;
            border-radius: 9px ;;
            display: flex;
            .imgDesign{
              border-radius: 9px;
              opacity: 1.25;
              display: flex;
              justify-content: center;
              text-align: center;
              width: 58px;
              height: 100%;
              background-image: url("../../assets/icon-10.png");
              background-position: center;
              background-size:contain;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              width: 80%;
              padding: 0 7px 0 15px;
              .Title1{
                opacity: 4;
                width:100%;
                //white-space: nowrap; //换行
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 7.5px;
                text-align: left;
                justify-content: center;
                align-items: center;
                font-size:12px;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: bold;
                line-height: 20px;
              }
              .Title2{
                opacity: 4;
                width: 230px;
                margin: 2px 0 10px 0;
                //text-align: justify;
                font-size: 12px;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 500;
                line-height: 15px;
              }
            }
          }
        }
      }
    }

    .screenmake5{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: black;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        color: white;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 700;
        line-height: 20px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 12px;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          left:-55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          right: -55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .card{
          cursor: pointer;
          width: 98%;
          margin:0 auto;
          height:fit-content ;
          padding:0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;
          .back{
            border-radius: 9px;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:545px;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: 100%;
              height:fit-content;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 5px 20px 25px 20px;
              color: white;
              width:100%;
              float: right;
              .title1{
                margin-top:10px;
                font-size:12px;
                line-height: 20px;
              }
              .title2{
                margin-top: 10px;
                line-height: 22px;
                font-size: 12px;
                ::v-deep li{
                  margin-top:5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 545px !important;

        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__arrow {
          display: block;
          //border: 1px solid white;
          outline: -5px;
          padding: 0;
          margin: -150px -10px  ;
          cursor: pointer;
          border-radius: 0;
          width: 25px;
          height: 48px;
          background: rgba(245, 246, 250, 0.8);
          color: #131313;
          position: absolute;
          top: 50%;
          //z-index:: 100;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
  }

}
</style>
