<template>
  <div>
    <div v-if="model===false">
      <div class="totalmakeHole" ref="total">   <div class="topback"  :style="{ height: '7vh', backgroundColor: scrollback ? 'rgb(196,196,196)' : 'transparent' ,position:'fixed'}">
          <headtip @showDialog="showPartner"></headtip>
        </div>
        <div class="makeHole1">
          <div class="background">
            <!--     文字-->
            <div class="titlle1">数智油田GPT开发应用项目服务</div>
            <div class="title4">利用大模型人工智能技术，与目前的数智油田全场景结合，赋能数智油田全面升级。</div>
            <div class="titlle3"   @click="clickConsult('Oil & Gas Generic AI')">
               Product Inquiry
            </div>


          </div>
          <div class="screenmake0">
            <div class="heighLight">智慧生产集控</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                     <img loading="lazy" style="border-radius: 9px" src="@/assets/application4.png">
                  </div>


                  <div class="titleDesign">
                    <div class="title2">
                      通过大模型人工智能，实现井群智能化生产的预测、计划、排产、以及调度，实现提高生产的综合采收率效果。
                      <li  style="margin-top: 15px">智能化生产预测</li>
                      <li>智能化生产排产</li>
                      <li> 单井生产智能化调控</li>
                      <li>智能化井群调度</li>
                      <li>智能化生产监控</li>


                    </div>
                  </div>

                </div>


              </div>
            </div>

          </div>
          <div class="screenmake1">
            <div class="heighLight">智慧能控中心</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="titleDesign">
                    <div class="title2">

                      实现油田处理场站的智能管理和监控，电网、水网管理系统的智能优化和调配，提升能源使用效率。

                      <li style="margin-top: 15px"> 电网智能化动态监控</li>
                      <li> 发电机智能化动态管理</li>
                      <li>   智能化电网负载均衡系统</li>
                      <li>水务动态智能化管理</li>


                    </div>
                  </div>
                  <div class="imgDesign">
                     <img loading="lazy" style="border-radius: 9px" src="@/assets/application3.png">
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="screenmake0">
            <div class="heighLight">智慧场站升级解决方案</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                     <img loading="lazy" style="border-radius: 9px" src="@/assets/application1.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title2">
                      智能生产调控：智能调节控制场站智能化设备，最优化排产； 智能人机交互：通过AI人机交互系统，实现智能化控制交互。
                      <li style="margin-top: 15px"> 智能生产调控系统</li>
                      <li> 一体化智能排产</li>
                      <li>    AR 智能作业</li>
                      <li> 智慧用能控制</li>

                    </div>
                  </div>
                </div>


              </div>
            </div>

          </div>
          <div class="screenmake1">
            <div class="heighLight">智慧管网升级解决方案</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="titleDesign">
                    <div class="title2">
                      基于客户具体场景需求，结合算力服务厂商与基础大模型厂商相关技术规范，安东提供算力优化适配服务，最大程度发挥大模型效果与价值。

                    </div>
                  </div>
                  <div class="imgDesign">
                     <img loading="lazy" style="border-radius: 9px" src="@/assets/application2.png">
                  </div>
                </div>
              </div>
            </div>

          </div>
          <bottomTip></bottomTip>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
               <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel">
        <div class="makeHole1">
          <div class="background">
            <!--      logo  you气通GPT社区 合作伙伴  登录-->
            <headtip @showDialog="showPartner"></headtip>
            <!--     文字-->
            <div class="layer2">
              <div class="titlle1">数智油田GPT开发应用项目服务</div>
              <div class="title4">利用大模型人工智能技术，与目前的数智油田全场景结合，赋能数智油田全面升级。</div>

            </div>
            <div class="title3"   @click="clickConsult('Oil & Gas Generic AI')">
               Product Inquiry
            </div>


          </div>
          <div class="screenmake0">
            <div class="heighLight">智慧生产集控</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                     <img loading="lazy" style="border-radius: 9px" src="@/assets/application4.png">
                  </div>


                  <div class="titleDesign">
                    <div class="title2">
                      通过大模型人工智能，实现井群智能化生产的预测、计划、排产、以及调度，实现提高生产的综合采收率效果。
                      <li>智能化生产预测</li>
                      <li>智能化生产排产</li>
                      <li> 单井生产智能化调控</li>
                      <li>智能化井群调度</li>
                      <li>智能化生产监控</li>


                    </div>
                  </div>

                </div>


              </div>
            </div>

          </div>
          <div class="screenmake1">
            <div class="heighLight">智慧能控中心</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                     <img loading="lazy" style="border-radius: 9px" src="@/assets/application3.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title2">

                      实现油田处理场站的智能管理和监控，电网、水网管理系统的智能优化和调配，提升能源使用效率。

                      <li> 电网智能化动态监控</li>
                      <li> 发电机智能化动态管理</li>
                      <li>   智能化电网负载均衡系统</li>
                      <li>水务动态智能化管理</li>


                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
          <div class="screenmake0">
            <div class="heighLight">智慧场站升级解决方案</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                     <img loading="lazy" style="border-radius: 9px" src="@/assets/application1.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title2">
                      智能生产调控：智能调节控制场站智能化设备，最优化排产； 智能人机交互：通过AI人机交互系统，实现智能化控制交互。
                      <li > 智能生产调控系统</li>
                      <li> 一体化智能排产</li>
                      <li>    AR 智能作业</li>
                      <li> 智慧用能控制</li>

                    </div>
                  </div>
                </div>


              </div>
            </div>

          </div>
          <div class="screenmake1">
            <div class="heighLight">智慧管网升级解决方案</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                     <img loading="lazy" style="border-radius: 9px" src="@/assets/application2.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title2">
                      基于客户具体场景需求，结合算力服务厂商与基础大模型厂商相关技术规范，安东提供算力优化适配服务，最大程度发挥大模型效果与价值。

                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
          <bottomTip></bottomTip>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
               <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>



</template>


<script>

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
export default {

  name: "",

  props: [],

  components: {PartnerTip, consultTip, headtip,bottomTip},

  data() {

    return {
      pageTitle: '数智油田GPT开发应用项目服务_安迅Oil & Gas Generic AI-Oil & Gas Generic AIGPT',
      pageDescription: '安迅Oil & Gas Generic AI智能技术有限公司专注为石油行业提供人工智能技术解决方案，提供数智油田GPT开发应用项目服务，利用大模型人工智能技术，与目前的数智油田全场景结合，赋能数智油田全面升级。',
      pageKeywords: '数智油田，数智油田GPT，数智油田GPT开发，安迅Oil & Gas Generic AI智能技术有限公司，Oil & Gas Generic AI，Oil & Gas Generic AIGPT，Oil & Gas Generic AIGPT云平台',
      model:false, scrollback:false,
      dialogconsult:false,
      dialogVisible:false,
      success:[{
        img:require("@/assets/anxun52.png"),
        title1:"场站工艺管理平台 Successful Cases",
        title2:"<li>中石化元坝净化厂工艺管理平台</li>",
      },{
        img:require("@/assets/anxun53.png"),
        title1:"场站工艺管理平台 Successful Cases",
        title2:"<li>达州某净化厂工艺管理平台</li>",
      },
      ]
    }

  },

  methods: {
    clickConsult(item){

      console.log(item)
      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner(){
      this.dialogconsult=true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
  handleScroll() {
      if (this.model === false) {
        this.scrollHeight = this.$refs.total.scrollTop;
      } else {
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if (this.scrollHeight < 100) {
        this.scrollTrue = true
      } else {
        this.scrollTrue = false
      }
      if (this.scrollHeight < 50) {
        this.scrollback = false
      } else {
        this.scrollback = true
      }
      console.log("gaodu", this.scrollHeight < 100)
    },
  },
  //进入该页面时，用之前保存的滚动位置赋值
  beforeDestroy() {
    // 移除事件监听器
    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);

    document.title=this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }

    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: '数智油田GPT开发应用项目服务_安迅Oil & Gas Generic AI-Oil & Gas Generic AIGPT',
        pageDescription: '安迅Oil & Gas Generic AI智能技术有限公司专注为石油行业提供人工智能技术解决方案，提供数智油田GPT开发应用项目服务，利用大模型人工智能技术，与目前的数智油田全场景结合，赋能数智油田全面升级。',
        pageKeywords: '数智油田，数智油田GPT，数智油田GPT开发，安迅Oil & Gas Generic AI智能技术有限公司，Oil & Gas Generic AI，Oil & Gas Generic AIGPT，Oil & Gas Generic AIGPT云平台',

      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .topback{
    position: fixed;
    z-index:999999999999;
    width: 100vw;
    padding: 0 0 0 2.86077411900635vw;
  }
  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    .background{
      padding: 7.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
      width: 100%;
      height: 72.41285537700865vh;
      background-image: url("../../assets/applicationBanner.png");
      background-position: center;
      background-size:cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */
      .titlle1 {
        margin-top: 15.09270704573548vh;
        text-align: center;
        font-size: 2VW;
         font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #111111;
        line-height: 2.84301606922126vh

      }
      .title4 {
        text-align: center;
        margin: 6.82076637824475vh auto 0 auto;
        width: 58.45869439630272vw;
        font-size: 1VW;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color:#111111;
        line-height: 4.31520395550062vh;
      }
      .titlle3 {
        margin: 8.07911001236094vh auto;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #ffffff;
        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 53px;
        width: fit-content;
        padding: 2.4624227441vh 1.1989601386vw;
        height: 4.5624227441vh;
        background: #2f6eff;
        border: #2f6eff 1px solid;

      }
      .titlle3:hover{

        color: #2168DB;
        border: #2168DB 1px solid;
        background: rgba(255, 255, 255, 0.5);
      }
    }
    .screen1 {
      //z-index:: 3;
      position: relative;
      width: 100%;
      height: 74vh;
      padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
      background-image: url("../../assets/applicationBanner.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      .layer2 {
        position: absolute;
        top: 18.1495673671199vh;
        left: 9.17157712305026vw;

        .title1 {
          width: fit-content;
          height: 5.77626699629172vh;
          font-size: 3.50606585788562vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 400;
          color: #2f6eff;
          line-height: 12.23733003708282vh;
        }

        .title2 {
          margin-top: 6.82076637824475vh;
          width: 33.45869439630272vw;
          height: fit-content;
          font-size: 1.0597920277vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 400;
          color: #2f6eff;
          line-height: 4.31520395550062vh;
        }
        .title4{
          height: 8.77626699629172vh;
          font-size: 1.6060658579vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 400;
          color: #2f6eff;
          line-height: 9.31520395550062vh;
        }
        .titlle3{
          margin-top: 2.07911001236094vh;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 400;
          color: #ffffff;
          line-height: 3.3374536465vh;
          text-align: center;
          border-radius: 53px;
          width: fit-content;
          padding: 2.4624227441vh 1.1989601386vw;
          height: 4.5624227441vh;
          background: #2f6eff;
          border: #2f6eff 1px solid;

        }
        .titlle3:hover{

          color: #2168DB;
          border: #2168DB 1px solid;
          background: rgba(255, 255, 255, 0.5);
        }
      }


      //.layer3 {
      //  position: absolute;
      //  top: 5.06798516687268vh;
      //  right: 11.61178509532062vw;
      //  width: 47.42923165800116vw;
      //  height: 72.80593325092707vh;
      //  background-image: url("../assets/GPTplant.png");
      //  background-size: 100% auto;
      //  background-position: center;
      //  background-repeat: no-repeat;
      //  background-color: transparent;
      //  opacity: 1;
      //}
    }
    .screenmake0{
      padding: 5.7873918418vh 11.0976314269vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 1.7vw;
        //color: #53A3F7;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1VW;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .card{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            //background: #EFF5FF;
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height:fit-content;
            overflow:hidden;
            .imgDesign{
              margin-left: 10%;
              width: 40vw;
              height:fit-content;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 3.5248454883vh 2.5248454883vw 2.5248454883vh 3.5248454883vw;
              display: flex;
              justify-content: center;
              align-items: center;
              float: right;
              color: black;
              width: 48%;
              .title1{
                margin-top: 4vh;
                font-size: 1VW;
                font-weight: bold;
              }
              .title2{
                //text-align: justify;
                line-height: 3.194066749vh;
                font-size: 1vw;
              }
              .title3{
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 1vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake1{
      padding: 5.7873918418vh 11.0976314269vw;
      width: 100%;
      height: fit-content;
      background: transparent;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 1.7vw;
        //color: #53A3F7;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1VW;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .card{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            //background: #EFF5FF;
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height:fit-content;
            overflow:hidden;
            .imgDesign{
              margin-left: 5%;
              width: 38vw;
              height:fit-content;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 3.5248454883vh 2.5248454883vw 2.5248454883vh 8.5248454883vw;
              display: flex;
              justify-content: center;
              align-items: center;
              float: right;
              color: black;
              width: 50%;
              .title1{
                margin-top: 4vh;
                font-size: 1VW;
                font-weight: bold;
              }
              .title2{

                //text-align: justify;
                line-height: 3.194066749vh;
                font-size: 1vw;
              }
              .title3{
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 1vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
  }

}

.totalmakeHoleModel{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    .background{
      padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
      width: 100%;
      height: 400px;
      background-image: url("../../assets/applicationBanner.png");
      background-position: center;
      background-size:cover;
      background-repeat: no-repeat;
      background-color: transparent;
      position: relative;
      /* Add any additional styling as needed */
      .layer2 {
        position: absolute;
        left: 0;

        .titlle1 {
          margin: 25px auto 0 auto;
          width: fit-content;
          text-align: center;
          height: fit-content;
          font-size: 22px;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          color: #111111;
          line-height: 22px;

        }

        .title4 {
          margin: 25px auto;
          width: 90%;
          text-align: center;
          height: 17px;
          font-size: 12px;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 400;
          color: #111111;
          line-height: 20px;
        }
      }
      .title3 {
        position: absolute;
        bottom:30px;
        left:50%;
        transform: translateX(-60%);
        font-size: 14px;
         font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        line-height: 15px;
        height: 35px;
        color:white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;


      }

    }
    .screenmake0{
      padding:30px 4.6875vw 20px 4.6875vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        text-align: center;
        height: 20px;
        opacity: 1;
        font-size: 21px;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 700;
        line-height: 26px;

      }

      .grid{
        margin-top:20px ;
        position: relative;
        .card{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:5px 5px;
          opacity: 1;
          .imgDesign{
            margin:0 auto;
            text-align: center;
            width:320px;
            height:fit-content;
            opacity: 1;
            //background-image: url("../../assets/menage6.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{

            .title2{
              margin: 25px 12px 15px 12px;
              //text-align: justify;
              height: fit-content;
              font-size:14px;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              line-height: 22px;
            }

          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake1{
      padding:30px 4.6875vw 20px 4.6875vw;
      width: 100%;
      height: fit-content;
      background: transparent;

      .heighLight {
        text-align: center;
        height: 20px;
        opacity: 1;
        font-size: 21px;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 700;
        line-height: 26px;

      }

      .grid{
        margin-top:20px ;
        position: relative;
        .card{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:5px 5px;
          opacity: 1;
          .imgDesign{
            margin:0 auto;
            text-align: center;
            width:320px;
            height:fit-content;
            opacity: 1;
            //background-image: url("../../assets/menage6.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{

            .title2{
              margin: 25px 12px 15px 12px;
              //text-align: justify;
              height: fit-content;
              font-size:14px;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              line-height: 22px;
            }

          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
  }

}
</style>
