<template>
    <div class="totalmakeHole" ref="total">
        <div class="topback"  :style="{ height: '7vh', backgroundColor: scrollback ? 'rgb(196,196,196)' : 'rgba(201,199,199,0.5)' ,position:'fixed'}">
          <headtip @showDialog="showPartner"></headtip>
        </div>

        <div class="makeHole1">
      <div class="background">
        <!--     文字-->
        <div class="titlle1">Drilling Parameter Optimization Platform</div>
        <div class="title4">*The generalization ability of the Oil & Gas AI model is limited, so it may need to be adapted and optimized according to specific scenes and needs in practical applications. </div>
        <div class="title2">①&nbsp;Process-related services are provided by Antonoil's offline technical personnel<br>②&nbsp;GPT development services are provided by Antonoil's AI professionals</div>
        <div class="titlle3" @click="clickConsult('Oil & Gas Generic AI')">
          Product Inquiry
        </div>
      </div>
      <div class="screenmake1">
        <div class="heighLight">Function Introduction</div>

        <div class="grid">
          <div class="card">
            <div class="back">
              <div class="imgDesign">
                <img src="@/assets/drilling1.png">
              </div>
              <div class="titleDesign">
                <div class="Title1" style="font-weight: bolder;">Optimization of drilling parameters </div>
                <div class="Title2" style="">
                  In drilling operation, Intelligent algorithm accurately recommends appropriate adjustable drilling parameters such as drilling pressure, rotary speed and pumping pressure according to input well depth, basic attributes of drilling tool, physical and chemical properties of drilling fluid such as cylinder diameter, mud density, etc. This will provide a protection for safety of real-time drilling.


                </div>

              </div>
            </div>
          </div>
          <div class="card">
            <div class="back">
              <div class="imgDesign">
                <img src="@/assets/drilling2.png">
              </div>
              <div class="titleDesign">
                <div class="Title1" style="font-weight: bolder;">Drilling Parameter Threshold Planning</div>
                <div class="Title2">
                  The customized setting of drilling parameters is realized by using drilling design and drilling tool characteristic analysis. Threshold values for key metrics such as weight on bit and RPM can be manually adjusted to ensure that each drilling operation meets the precise requirements of specific geographical and geological conditions.
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="screenmake0">
        <div class="heighLight">Functions experience</div>
        <div class="heighLight1">By accurately recommending appropriate drilling parameters, we can maximize drilling efficiency, reduce energy consumption and costs, achieve a highly efficient and stable drilling process. <span style="font-weight: bold;color: red;">You can use function below,free experience!</span> <br>If you have cooperation intention and product consultation, please<span style="color: #FF6400;cursor: pointer" @click="clickConsult('Oil & Gas Generic AI')"> contact us</span>. </div>
        <div class="grid">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane  name="first">
              <div class="card">
              <div class="back">
                <div class="titleDesign">
                  <div style="display: flex">
                    <div class="Title1">
                      1. Data source：
                    </div>
                    <div class="Title2">
                      Drilling Data;
                      Logging Data;
                      Geological Parameters;
                      Real-Time drilling data collection
                    </div>
                  </div>
                  <div>
                    <div class="Title1">
                      2.    Operation Procedures:<span class="Title2" > Fill in the well depth, cylinder diameter and other data,Click 【Confirm】 to output recommended application parameters in the right column of page.
                 </span>
                    </div>
                    <div class="Title2" style="display: flex">
                       </div>
                  </div>
                  <div style="display: flex">
                    <div class="Title1">
                      *&nbsp;  Attention：
                    </div>
                    <div class="Title2">
                      According to specified thresholds in project to set parameter ranges.
                    </div>
                  </div>
                </div>
                <div ref="iframeContainer" class="iframeStyle">
                  <iframe :src="iframeUrl1" frameborder="0" allowfullscreen></iframe> </div>
              </div>


            </div>
            </el-tab-pane>

          </el-tabs>


        </div>

      </div>

      <bottomTip></bottomTip>
    </div>
      <partnerTip
          :dialogVisible="dialogconsult"
          @hideDialog="hide"
      ></partnerTip>
      <consultTip
          :dialogVisible="dialogVisible"
          :thisTitle="title"
          @hideDialog="hideDialog"
      ></consultTip>
    </div>

</template>
<script>

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
// import consultTip from "@/components/consultTip.vue";
// import PartnerTip from "@/components/partnerTip.vue";
export default {

  name: "",

  props: [],

  // components: {PartnerTip, consultTip, headtip,bottomTip},
  components: {PartnerTip, consultTip, headtip,bottomTip},

  data() {

    return {
      title:"",
      dialogconsult:false,   scrollback:false,
      dialogVisible:false,
      activeName: 'first',
      userId:localStorage.getItem("userId"),
      iframeUrl1:"https://ab.oilgasgpts.com/frackEn/zjcshyx",
      // iframeUrl2:"http://localhost:8891/zjcshyx?tag=params-config",
      // iframeUrl1:"http://54.223.240.188:8686/jyylcshdycjcshfy?type=correlation",
      // iframeUrl2:"http://54.223.240.188:8686/jyylcshdycjcshfy?type=eur",
      success:[{
        img:require("@/assets/success11.png"),
        title1:"助力长庆油田某采油厂实现地面工程设备设施健康管理",
        title3:"油田地面工程是油气田开发生产这个大系统中的]一个重要子系统，包括油气集输、污水处理、配制注入系统等，由离心泵、柱塞泵、加热炉等核心设备构成。核心设备健康管理水平的高低，!直接影响着油气田开发技术的高低和经济效益。",
      },{
        title1: "助力天津石化设备健康管理",
        title3:"<li>往复式压缩机气阀温度采集无线传感器替代</li><li>实现设备多源参数采集统管理</li><li>实现设备、测点、数据自主配置组态</li><li>实现多维图谱分析工具应用</li>",

      },
      ]
    }

  },

   methods: {
     handleClick(tab, event) {
       console.log(tab, event);
     },

    clickConsult(item){
      // console.log("联系联系")
      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner(){
      this.dialogconsult=true;
    },
     handleScroll() {
         this.scrollHeight = this.$refs.total.scrollTop;


       if (this.scrollHeight < 100) {
         this.scrollTrue = true
       } else {
         this.scrollTrue = false
       }
       if (this.scrollHeight < 50) {
         this.scrollback = false
       } else {
         this.scrollback = true
       }
       console.log("gaodu", this.scrollHeight < 100)
     },
   },
  //进入该页面时，用之前保存的滚动位置赋值
  beforeDestroy() {
    // 移除事件监听器
    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);


    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
  },
  watch:{
    "$store.state.userId"(newValue) {
      this.userId = newValue;

    },
  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: 'Process GPTs_Oil and gas industry process large model artificial intelligence application-Oil and Gas Communication GPT',
        pageDescription: 'The Oil and Gas GPT Cloud Platform provides process-oriented GPTs, which not only contribute to enhancing development effectiveness and recovery rates but also minimize risk and losses during the development process, ensuring the safe and stable exploitation of Oil & Gas fields.',
        pageKeywords: 'Process GPTs, process GPTs, intelligent geosteering, intelligent injection and production decision-making, Oil & Gas field video monitoring, drilling risk warning, artificial lift optimization, mechanical production intelligent management and control, Oil & Gas field energy consumption optimization, Oil & Gas communication, Oil & Gas communication GPT, Oil & Gas communication GPT cloud Platform, Oil & Gas channel large model, Oil & Gas channel GPT large model',

      };
      shareUrl(shareData);
    }, 1000);

  },
}

</script>

<style type="text/scss" lang="scss" scoped>
.totalmakeHole{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .topback{
    position: fixed;
    z-index:999999999999;
    width: 100vw;
    padding: 0 0 0 2.86077411900635vw;
  }
  .makeHole1 {
    //z-index: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    .background{
      padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 7.86077411900635vw;
      width: 100%;
      height: 50vh;
      background-image: url("../../assets/third4.png");
      background-position: center;
      background-size:cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */
      .titlle1 {
        margin-top: 8.09270704573548vh;
        text-align: center;
        font-size: 2vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #111111;
        line-height: 2.84301606922126vh

      }
      .title4 {
        text-align: center;
        margin: 6.82076637824475vh auto 0 auto;
        width: 62vw;
        font-size: 1vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color:#111111;
        line-height: 4.31520395550062vh;
      }
      .title2 {
        text-align: left;
        margin: 3.82076637824475vh auto 0 auto;
        width: fit-content;
        font-size: 0.9vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color:#111111;
        line-height: 4.31520395550062vh;
      }
      .titlle3 {
        margin: 6.07911001236094vh auto;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.9vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #ffffff;
        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 53px;
        width: fit-content;
        padding: 2.4624227441vh 1.1989601386vw;
        height: 4.5624227441vh;
        background: #2f6eff;
        border: #2f6eff 1px solid;
        margin-top: 9px;
      }
      .titlle3:hover{

        color: #2168DB;
        border: #2168DB 1px solid;
        background: rgba(255, 255, 255, 0.5);
      }
    }
    .screenmake0{
      padding:6.8430160692vh  9vw 2.8430160692vh 9vw;
      width: 100%;
      height: fit-content;
      background: transparent;

      .heighLight{
        text-align: center;
        height: 7.54017305315204vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: bold;
        //color: #2168DB;
        line-height: 2.71940667490729vh;
      }
      .heighLight1{
        text-align: left;
        margin: 0 auto;
        width: 85vw;
        height:fit-content;
        font-size: 1.1vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        //color: #2168DB;
        line-height: 3.5vh;
      }
      ::v-deep .el-tabs__nav{
       text-align: center;
        float: none;
      }
      ::v-deep .el-tabs__item{
        width:50%;
        font-size:  1.2vw !important;
        padding:2vh  5vh;
        height: fit-content;
      }
      ::v-deep .el-tabs__active-bar{
        background-color: transparent;
      }
      ::v-deep .el-tabs__header{

        margin:0 0 0 0;
      }

      ::v-deep .el-tabs__item.is-active{
        color: #316fff;
      }

      .grid{
        display: grid;
        grid-template-columns: minmax(0,1fr);
        column-gap:0 ;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content;
          //padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            //display: flex;
            position: relative;
            //background-color: #ececec;
            padding: 0 0 0 0;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            min-height:85vh ;
            overflow:hidden;
            .iframeStyle{
              background-color: #ececec;
              margin: -9vh 0 0 -10vw;
              width:100vw;
              transform: scale(0.82);
              //border: #0a0a0a 5px solid;

              border-bottom-left-radius: 9px;
              border-bottom-right-radius: 9px;

              overflow: hidden;
            }
            .iframeStyle iframe {
              background-color: #ececec;
              width:100vw;
              min-height:90vh;
              height: fit-content;
              //top: 0;
              //left: 0;
              transform-origin: center center;
              overflow: hidden;
            }
            .imgDesign{

              width:fit-content;
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              justify-content: center;
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 2vh 3vw;
              background-color: #ececec;
              border-top-left-radius: 0;
              border-top-right-radius: 9px;
              .Title1{

                color:#111111;
                text-align: justify;
                font-size: 0.8vw;
                height: fit-content;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight:bold;
                line-height: 1.2vw;
              }
              .Title2{
                color:#111111;
                text-align: justify;
                font-size: 0.8vw;
                height: fit-content;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 1.2vw;
              }
            }
          }
        }

      }
    }
    .screenmake1{
      padding:6.8430160692vh  9vw ;
      width: 100%;
      height:  fit-content;
      background: white;

      .heighLight{
        text-align: center;
        height: 7.54017305315204vh;
        font-size: 1.7vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: bold;
        //color: #2168DB;
        line-height: 4vh;
      }

      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        column-gap:0 ;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw ;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            display: flex;
            border: white 1px solid;
            background-color: #D4E4FF;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:40vh ;
            padding: 0 0 3vh 0;
            overflow:hidden;
            .imgDesign{
              height:40vh;
              width: 400vh;
              margin:0vh auto;
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              justify-content: center;
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              margin:0 1vw ;
              .Title1{
                width: 30vh;
                margin: 1vh auto;
                display: flex;
                flex-direction: column;
                justify-content: center; /* Center vertically */
                align-items: center;
                color:#111111;
                text-align: center;
                font-size: 1vw;
                height: fit-content;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 2.7194066749vh;
              }
              .Title2{
                // display: flex;
                // flex-direction: column;
                // justify-content: center; /* Center vertically */
                // align-items: flex-start;
                // color:#111111;
                // text-align: justify;
                // font-size: 0.9vw;
                // height: fit-content;
                // font-family: Sarial, sans-serif, "Microsoft Yahei";
                // font-weight: 400;
                // line-height: 4vh;
                margin: 1vh auto;
                display: flex;
                flex-direction: column;
                justify-content: center; /* Center vertically */
                align-items: center;
                color:#111111;
                text-align: left;
                font-size: 0.9vw;
                height: fit-content;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 2.8vh;
              }
            }
          }
        }

      }
    }
  }

}

</style>
