<template>
  <div>
    <div v-if="model===false">

    </div>
    <div v-else>

  <div class="totalModel">
  <div class="screen1">
    <headtip @showDialog="showPartner"></headtip>
    <!--      logo  you气通GPT社区 合作伙伴  登录-->

    <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
    <div class="layer2">
      <div class="title1">Oil & Gas Field Video Surveillance</div>
      <div class="title2">*The generalization ability of the Oil & Gas AI model is limited, so it may need to be adapted and optimized according to specific scenes and needs in practical applications. </div>
      <div class="title3"> <br>①&nbsp;Process-related services are provided by Antonoil's offline technical personnel<br>②&nbsp;GPT development services are provided by Antonoil's AI professionals</div>

    </div>

    <div class="titlle3" @click="clickConsult('Oil & Gas Generic AI')">
     Product Inquiry
    </div>
    <!-- <div class="title4">*油气人工智能模型的泛化能力有限，实际应用中可能需根据具体场景和需求进行适配和优化。如您有合作意向或 Product Inquiry ，请随时与我们联系。</div> -->
    <!--      平台大图-->
    <div class="layer3"></div>
  </div>

  <div class="screenmake2">
    <div class="heighLight">  Intelligent Monitoring And Analysis</div>
    <div class="grid">
      <div class="card">
        <div class="back">
          <div class="imgDesign">     <img loading="lazy" style="border-radius: 9px" src="@/assets/third13.png">
          </div>
          <div class="titleDesign">
            <div class="Title1">      <span style="border-radius: 50%; width:16px;font-size:16px;height:16px;display: flex;justify-content: center;align-items: center;color: white;margin-top: 4px">1</span>&nbsp; </div>
            <div class="Title2">
              <span style="font-weight: 500"> Customer Pain Points：</span>
              <li>The personnel structure is complex, there are many parties involved in the construction, safety awareness is uneven, and management is difficult;</li>
              <li>On-site materials are expensive and abundant, and it is difficult to ensure the safety of materials by relying on people alone;</li>
              <li>There are many crossing operations and the fast-paced construction process makes it difficult to ensure the safety of people and vehicles crossing.</li>
              <span style="margin-top: 0.5vh;font-weight: 500">Solution：</span>
              <li>Reuse the old: access the original video of the station and access the platform to realize full scene monitoring;</li>
              <li>Uninterrupted monitoring: Perimeter security of surrounding construction and intelligent monitoring of equipment area construction to achieve blind-angle monitoring of key areas;</li>
              <li>Early warning and warning: realize the analysis of more than 20 unsafe behaviors, remote monitoring and inspection, recording of the entire operation process, project progress control, and construction safety alarm.</li>
            </div>
          </div>
        </div>


      </div>




    </div>

  </div>
    <div ref="bottom">
      <bottomTip></bottomTip>
    </div>
  <partnerTip
      :dialogVisible="dialogconsult"
      @hideDialog="hide"
  ></partnerTip>
<consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
      @hideDialog="hideDialog"
  ></consultTip>
    </div>
</div>
</div>



</template>


<script>

import headtip from "@/views/headTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import consultTip from "@/components/consultTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import {shareUrl} from "@/utils/vxshare";

export default {

  name: "",

  props: [],

  components: {bottomTip, consultTip, PartnerTip, headtip},

  data() {

     return {
      title:'Oil & Gas Generic AI',
      model:false,
      dialogconsult:false,   scrollback:false,
      dialogVisible:false,
      success:[
      {
          img:require("@/assets/gpt26.png"),
          title1:"XX深层煤层气钻井项目",
          title2:"安东自2022年三季度进入xx煤项目施工，2部钻机（50LDB带顶驱）参与，共施工10口深层煤层气水平井，平均井深3530m，水平段长1000-1500m，平均钻井周期30.48天（最短27.58天），多口单井或开次创区块记录，得到客户高度认可。",
        },
        {
          img:require("@/assets/gpt46.png"),
          title1:"智能采油机器人服务",
          title2:"        <li style=\"margin-top: 1vh;line-height: 16px;\">针对抽油机井，实时采集压力温度、电参、示功图、动液面等数据，输入到智能采油机器人工艺脑，输出得到最优化生产运行参数，减少无效冲次，杜绝空抽，使抽油机在最佳工况下运行，油井能够保持动液面稳定，达到了油井供采平衡，实现了油井的稳产增产。</li>\n" +
              "                        <li style=\"margin-top: 1vh;line-height: 16px;\">应用业绩：分别在大港油田、华北油田、胜利油田、中原油田、江汉油田以及延长石油开展智能采油机器人工业现场应用测试，运行稳定，取得较好的应用效果。</li>",
        },
        {
          img:require("@/assets/gpt56.png"),
          title1:"XX石油天然气脱烃增压智慧场站项目",
          title2:"<span style=\"margin-top: 0.5vh;font-weight: bold;line-height: 12px;\">设备检测，精准实时：</span><br>  根据智能化目标需求，配套高效新技术新产品，通过现场集成应用，全天候实时监测设备运行状况<br><br> <span style=\"margin-top: 0.5vh;font-weight: bold;line-height: 12px;\">方案成果：</span>  <li>降低维护成本: 及时发现潜在故障和问题，使维护更加预防性和精确，降低维护成本；</li>\n" +
              "                        <li style=\"margin-top: 0.5vh; line-height: 12px;\">增强设备可靠性: 减少设备故障和停机时间，提高设备的寿命；</li>\n" +
              "                        <li style=\"margin-top: 0.5vh;line-height: 12px;\">优化资源利用: 实时监测和数据分析帮助更好地利用资源，包括能源、原材料和人力资源，降低成本。</li>",
        },
      ]
    }

  },

 methods: {

    clickConsult(item){

      console.log(item)
      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    detail(){
      window.open("/ground", "_blank");
    },
    detail1(){
      window.open("/frack", "_blank");
    },
    detail2(){
      window.open("/decision", "_blank");
    },
    detail3(){
      window.open("/monitior", "_blank");
    },
    showPartner() {
      this.dialogconsult = true;
    },

    hideDialog() {
      this.dialogVisible = false;
    },
    hide() {
      this.dialogconsult = false;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
  },

  mounted() {

    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }
    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: 'Process GPTs_Oil and gas industry process large model artificial intelligence application-Oil and Gas Communication GPT',
        pageDescription: 'The Oil and Gas GPT Cloud Platform provides process-oriented GPTs, which not only contribute to enhancing development effectiveness and recovery rates but also minimize risk and losses during the development process, ensuring the safe and stable exploitation of Oil & Gas fields.',
        pageKeywords: 'Process GPTs, process GPTs, intelligent geosteering, intelligent injection and production decision-making, Oil & Gas field video monitoring, drilling risk warning, artificial lift optimization, mechanical production intelligent management and control, Oil & Gas field energy consumption optimization, Oil & Gas communication, Oil & Gas communication GPT, Oil & Gas communication GPT cloud Platform, Oil & Gas channel large model, Oil & Gas channel GPT large model',

      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>


<style type="text/scss" lang="scss" scoped>

.totalModel {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  position: relative;

  .screen1 {
    //z-index: 3;
    position: relative;
    width: 100%;
    height: 450px;
    padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 7.86077411900635vw;
    background-image: url("../../assets/monitiorB.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    /* Add any additional styling as needed */
    .titlle3{
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translateX(-60%);
      font-size: 14px;
      font-family: Roboto, Helvetica Neue, Helvetica, Tahoma, Arial, sans-serif;
      font-weight: 400;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 53px;
      width: fit-content;
      margin: 0 15px;
      padding: 5px 4.6875vw;
      line-height: 15px;
      height: 35px;
      color: #fff;
      background-color: #2f6eff;
      border: 1px solid #2f6eff;
      overflow: hidden;

    }
    .title4{
      position: absolute;
      left:10%;
      bottom:25px;
      margin: 35px auto 0 auto;
      width: 80%;
      height: 18px;
      font-size: 10px;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 400;
      color: white;
      line-height: 14px;
    }
    .layer2 {
      position: absolute;
      left:0;
      .title1 {
        padding:0 15px;
        margin:25px auto;
        width: fit-content;
        text-align: center;
        height: fit-content ;
        font-size: 23px;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 500;
        color: #111111;
        line-height:26PX;
      }

      .title2 {
        margin: 25px auto 0 auto;
        width: 90%;
        text-align: center;
        height: fit-content;
        font-size: 12px;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #111111;
        line-height: 22px;
      }
      .title3 {
        margin: -5px auto;
        width: 80%;
        text-align: left;
        height: 17px;
        font-size: 12px;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #111111;
        line-height: 24px;
      }
    }

  }
  .screenmake0 {
    padding: 20px 4.6875vw;
    width: 100%;
    height: fit-content;
    background: white;

    .heighLight {
      text-align: center;
      height: fit-content;
      opacity: 1;
      font-size: 21px;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 700;
      line-height: 26px;

    }

    .heightLight2 {
      margin:19px 2vw;
      //text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size:14px;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 400;
      color: #2C3E50;
      line-height: 20px;
    }

    .grid {

      display: grid;
      grid-template-columns: minmax(0, 1fr);
      column-gap: 1vw;

      .card1 {
        //cursor: pointer;
        width: 100%;
        height: fit-content;
        padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
        opacity: 1;

        .back {
          border-radius: 5px;
          position: relative;
          height: fit-content;
          overflow: hidden;
          margin-bottom: 1.5248454883vh;

          .imgDesign {
            text-align: center;
            width: 100%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }

          .titleDesign {
            padding: 1.5248454883vh 1.5248454883vw;
            //background-color: #2F6EFF;
            .Title1 {
              color: black;
              text-align: center;
              font-size: 1vw;

              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 700;
            }

          }

          .backconsult{
            position: absolute;
            bottom: 1.81248454882571vh;
            text-align: center;
            font-size: 0.9665511265vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            color: #2168DB;
            align-items: center; /* Vertically center the text */
            justify-content: center; /* Horizontally center the text */
            height: 2.95414091470952vh;
            left: 50%;
            transform: translateX(-50%);

            color: #2168DB;
          }
          .backconsultArrow{
            position: absolute;
            bottom: 1.81248454882571vh;
            text-align: center;
            font-size: 0.9665511265vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            color: #2168DB;
            align-items: center; /* Vertically center the text */
            justify-content: center; /* Horizontally center the text */
            height: 2.95414091470952vh;
            left: 80%;
            transform: translateX(-50%);
          }

        }


      }

    }
  }
  .screenmake2{
    padding: 20px 15px;
    width: 100%;
    height: fit-content;
    background:#FDFDFD;
    .heighLight {
      text-align: center;
      height: fit-content;
      opacity: 1;
      font-size: 18px;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 700;
      line-height: 26px;

    }




    .grid{
      margin-top:2.78739184177998vh ;
      display: grid;
      grid-template-columns: minmax(0,1fr);
      column-gap:0 ;
      row-gap: 15px;
      .card{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          // box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          // background: white;
          .imgDesign{
            //position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: fit-content;
            opacity: 1;
            //background-image: url("../../assets/recognition1.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .imgDesign1{
            //position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: fit-content;
            opacity: 1;
            //background-image: url("../../assets/recognition2.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              display: flex;
              justify-content: center;
              text-align: center;
              height: 20px;
              font-size: 16px;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 700;
              color:#FF6400;
              line-height: 2.7194066749vh;
            }
            .Title2{
              margin: 0 12px 15px 12px;
              //text-align: justify;
              height: fit-content;
              font-size:14px;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              line-height: 22px;
            }
            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
            }
            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
      .card2{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 190px;
            opacity: 1;
            background-image: url("../../assets/gpt25.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin-top: 200px;
              display: flex;
              justify-content: center;
              text-align: center;
              height: 20px;
              font-size: 16px;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 700;
              color:#FF6400;
              line-height: 2.7194066749vh;
            }
            .Title2{
              margin: 14px 12px 15px 12px;
              //text-align: justify;
              height: fit-content;
              font-size:14px;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              line-height: 22px;
            }
            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
      .card1{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            border-top-right-radius: 9px;
            border-top-left-radius: 9px;
            height: 170px;
            opacity: 1;
            background-image: url("../../assets/gpt24.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .imgDesign11{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 30.69962917181706vh;
            opacity: 1;
            background-image: url("../../assets/gpt34.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin-top: 180px;
              display: flex;
              justify-content: center;
              text-align: center;
              height: 20px;
              font-size: 16px;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 700;
              color:#FF6400;
              line-height: 2.7194066749vh;
            }
            .Title2{
              margin: 14px 12px 15px 12px;
              //text-align: justify;
              height: fit-content;
              font-size:14px;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              line-height: 22px;
            }
            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }
  .screenmake3{
    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;

    padding:20px 15px;
    background: black;
    .heighLight{
      text-align: center;
      height:21px;
      opacity: 1;
      font-size: 21px;
      color: white;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 700;
      line-height: 26px;
    }
    .heightLight2{
      margin:19px 2vw;
      //text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size:14px;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 400;
      color: #2C3E50;
      line-height: 20px;
    }
    .grid{
      margin-top:2.78739184177998vh ;
      position: relative;

      .card{
        cursor: pointer;
        width: 98%;
        margin:0 auto;
        min-height: 250px ;
        padding:0 0.69324090121317vw 0 0;
        opacity: 1;
        background: #19202e;
        .back{
          border-radius: 5px;
          position: relative;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          min-height: 360px ;
          overflow:hidden;
          background: #19202e;
          .imgDesign{
            width: 100%;
            height:200px;
            opacity: 1;
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            padding: 15px 20px 25px 20px;
            color: white;
            width:100%;
            float: right;
            .title1{
              text-align: center;
              margin-bottom:10px;
              font-size: 16px;
            }
            .title2{
              height: 180px;
              // line-height: 20px;
              font-size:12px;
              ::v-deep li{
                margin-top:5px;
              }
            ;
            }


          }

        }
      }

      ::v-deep .el-carousel__container{
        height: 430px !important;

      }
      .el-carousel__item h3 {
        color: white;
        opacity: 0.75;
        margin: 0;
      }

      .el-carousel__item:nth-child(2n) {
        background-color: transparent;
      }

      .el-carousel__item:nth-child(2n+1) {
        background-color: transparent;
      }

      ::v-deep .el-carousel__arrow {
        display: block;
        //border: 1px solid white;
        outline: -5px;
        padding: 0;
        margin: -68px -10px  ;
        cursor: pointer;

        border-radius: 0;
        width: 25px;
        height: 48px;
        background: rgba(245, 246, 250, 0.8);
        color: #131313;
        position: absolute;
        top: 50%;
        //z-index: 100;
        transform: translateY(-50%);
        text-align: center;
        font-size: 14px;
      }
    }
  }
}
</style>
