var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.model===false)?_c('div',[_c('div',{ref:"total",staticClass:"totalmakeHole"},[_c('div',{staticClass:"topback",style:({ height: '7vh', backgroundColor: _vm.scrollback ? 'rgb(196,196,196)' : 'transparent' ,position:'fixed'})},[_c('headtip',{on:{"showDialog":_vm.showPartner}})],1),_c('div',{staticClass:"makeHole1"},[_c('div',{staticClass:"background"},[_c('div',{staticClass:"titlle1"},[_vm._v("企业管理GPT开发服务")]),_c('div',{staticClass:"title4"},[_vm._v("帮助客户企业全线上办公平台建设，定制化人工智能办公服务方案。围绕客户业务的端到端价值流，通过流程自动化、数据驱动、在线社区、智能客服等智能化解决方案，全面提升客户内外部的运营管理和决策效率。")]),_c('div',{staticClass:"titlle3",on:{"click":function($event){return _vm.clickConsult('Oil & Gas Generic AI')}}},[_vm._v(" Product Inquiry ")])]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('bottomTip')],1),_c('partnerTip',{attrs:{"dialogVisible":_vm.dialogconsult},on:{"hideDialog":_vm.hide}}),_c('consultTip',{attrs:{"dialogVisible":_vm.dialogVisible,"thisTitle":_vm.title},on:{"hideDialog":_vm.hideDialog}})],1)]):_c('div',[_c('div',{staticClass:"totalmakeHoleModel"},[_c('div',{staticClass:"makeHole1"},[_c('div',{staticClass:"background"},[_c('headtip',{on:{"showDialog":_vm.showPartner}}),_vm._m(6),_c('div',{staticClass:"title3",on:{"click":function($event){return _vm.clickConsult('Oil & Gas Generic AI')}}},[_vm._v(" Product Inquiry ")])],1),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_c('div',{ref:"bottom"},[_c('bottomTip')],1)]),_c('partnerTip',{attrs:{"dialogVisible":_vm.dialogconsult},on:{"hideDialog":_vm.hide}}),_c('consultTip',{attrs:{"dialogVisible":_vm.dialogVisible,"thisTitle":_vm.title},on:{"hideDialog":_vm.hideDialog}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake0"},[_c('div',{staticClass:"heighLight"},[_vm._v("企业管理数智化架构")]),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"back",staticStyle:{"height":"45vh"}},[_c('div',{staticClass:"imgDesign"},[_c('img',{staticStyle:{"border-radius":"9px"},attrs:{"loading":"lazy","src":require("@/assets/menageService2.png")}})]),_c('div',{staticClass:"titleDesign"},[_c('div',{staticClass:"title2"},[_vm._v(" 以企业的核心业务及管理支持流程为主线，通过平台，构建运营管理工作的全线上化，并通过智能化手段提升运营管理效率。 ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake1"},[_c('div',{staticClass:"heighLight"},[_vm._v("管理流程自动化平台")]),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"back"},[_c('div',{staticClass:"titleDesign"},[_c('div',{staticClass:"title2"},[_vm._v(" 管理及业务支持的流程从线上化向智能化升级。通过流程自动机器人RPA、图形识别OCR、Python等智能化工具及语言，实现基于规则的流程系统可以24小时不间断的自动识别核验、自动处理流程，显著的减少人工操作，提高流程效率。 ")])]),_c('div',{staticClass:"imgDesign"},[_c('img',{staticStyle:{"border-radius":"9px"},attrs:{"loading":"lazy","src":require("@/assets/menageService3.png")}})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake0"},[_c('div',{staticClass:"heighLight"},[_vm._v("数据驱动智慧决策平台")]),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"back"},[_c('div',{staticClass:"imgDesign"},[_c('img',{staticStyle:{"border-radius":"9px"},attrs:{"loading":"lazy","src":require("@/assets/menageService4.png")}})]),_c('div',{staticClass:"titleDesign"},[_c('div',{staticClass:"title2"},[_vm._v(" 数据驱动，智慧决策。构建面向经营管理领域的数据服务地图，以终为始，基于预测以及实际执行情况，在核心业务流程的经营各环节，提前预警，智能预判，支持业绩达成。 ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake1"},[_c('div',{staticClass:"heighLight"},[_vm._v("智慧员工社区")]),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"back"},[_c('div',{staticClass:"titleDesign"},[_c('div',{staticClass:"title2"},[_vm._v(" 员工社区作为员工可以随时访问，获取各项管理智能化服务的入口。安东基于基础大模型，并利用内部各领域文档、知识开展训练，建设安东通知识GPT，实现智能问答对话，让员工快速便捷获取知识。 ")])]),_c('div',{staticClass:"imgDesign"},[_c('img',{staticStyle:{"border-radius":"9px"},attrs:{"loading":"lazy","src":require("@/assets/menageService.png")}})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake0"},[_c('div',{staticClass:"heighLight"},[_vm._v("智慧电商平台")]),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"back"},[_c('div',{staticClass:"imgDesign"},[_c('img',{staticStyle:{"border-radius":"9px"},attrs:{"loading":"lazy","src":require("@/assets/menageService5.png")}})]),_c('div',{staticClass:"titleDesign"},[_c('div',{staticClass:"title2"},[_vm._v(" 安东在线、全球油气展馆等各类油气电子商务平台，实现面向用户的智能问答、智能在线助手服务、搜索智能匹配，并可以根据用户的爱好和访问历史，智能推荐产品和解决方案服务。 ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake1"},[_c('div',{staticClass:"heighLight"},[_vm._v("企业内部管理类知识GPT开发服务")]),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"back"},[_c('div',{staticClass:"titleDesign"},[_c('div',{staticClass:"title2"},[_vm._v(" 基于大模型GPT能力，建设安东通GPT，内嵌集团各领域文档知识，实现智能问答对话，员工可以快速便捷获取知识。 ")])]),_c('div',{staticClass:"imgDesign"},[_c('img',{staticStyle:{"border-radius":"9px"},attrs:{"loading":"lazy","src":require("@/assets/menageService6.png")}})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layer2"},[_c('div',{staticClass:"titlle1"},[_vm._v("企业管理GPT开发服务")]),_c('div',{staticClass:"title4"},[_vm._v("帮助客户企业全线上办公平台建设，定制化人工智能办公服务方案。围绕客户业务的端到端价值流，通过流程自动化、数据驱动、在线社区、智能客服等智能化解决方案，全面提升客户内外部的运营管理和决策效率。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake0"},[_c('div',{staticClass:"heighLight"},[_vm._v("企业管理数智化架构")]),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"back"},[_c('div',{staticClass:"imgDesign",staticStyle:{"height":"155px"}},[_c('img',{staticStyle:{"border-radius":"9px"},attrs:{"loading":"lazy","src":require("@/assets/menageService2.png")}})]),_c('div',{staticClass:"titleDesign"},[_c('div',{staticClass:"title2",staticStyle:{"margin-top":"10px"}},[_vm._v(" 以企业的核心业务及管理支持流程为主线，通过平台，构建运营管理工作的全线上化，并通过智能化手段提升运营管理效率。 ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake1"},[_c('div',{staticClass:"heighLight"},[_vm._v("管理流程自动化平台")]),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"back"},[_c('div',{staticClass:"imgDesign",staticStyle:{"height":"150px"}},[_c('img',{staticStyle:{"border-radius":"9px"},attrs:{"loading":"lazy","src":require("@/assets/menageService3.png")}})]),_c('div',{staticClass:"titleDesign"},[_c('div',{staticClass:"title2"},[_vm._v(" 管理及业务支持的流程从线上化向智能化升级。通过流程自动机器人RPA、图形识别OCR、Python等智能化工具及语言，实现基于规则的流程系统可以24小时不间断的自动识别核验、自动处理流程，显著的减少人工操作，提高流程效率。 ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake0"},[_c('div',{staticClass:"heighLight"},[_vm._v("数据驱动智慧决策平台")]),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"back"},[_c('div',{staticClass:"imgDesign"},[_c('img',{staticStyle:{"border-radius":"9px"},attrs:{"loading":"lazy","src":require("@/assets/menageService4.png")}})]),_c('div',{staticClass:"titleDesign"},[_c('div',{staticClass:"title2"},[_vm._v(" 数据驱动，智慧决策。构建面向经营管理领域的数据服务地图，以终为始，基于预测以及实际执行情况，在核心业务流程的经营各环节，提前预警，智能预判，支持业绩达成。 ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake1"},[_c('div',{staticClass:"heighLight"},[_vm._v("智慧员工社区")]),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"back"},[_c('div',{staticClass:"imgDesign"},[_c('img',{staticStyle:{"border-radius":"9px"},attrs:{"loading":"lazy","src":require("@/assets/menageService.png")}})]),_c('div',{staticClass:"titleDesign"},[_c('div',{staticClass:"title2"},[_vm._v(" 员工社区作为员工可以随时访问，获取各项管理智能化服务的入口。安东基于基础大模型，并利用内部各领域文档、知识开展训练，建设安东通知识GPT，实现智能问答对话，让员工快速便捷获取知识。 ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake0"},[_c('div',{staticClass:"heighLight"},[_vm._v("智慧电商平台")]),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"back"},[_c('div',{staticClass:"imgDesign"},[_c('img',{staticStyle:{"border-radius":"9px"},attrs:{"loading":"lazy","src":require("@/assets/menageService5.png")}})]),_c('div',{staticClass:"titleDesign"},[_c('div',{staticClass:"title2"},[_vm._v(" 安东在线、全球油气展馆等各类油气电子商务平台，实现面向用户的智能问答、智能在线助手服务、搜索智能匹配，并可以根据用户的爱好和访问历史，智能推荐产品和解决方案服务。 ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake1"},[_c('div',{staticClass:"heighLight"},[_vm._v("企业内部管理类知识GPT开发服务")]),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"back"},[_c('div',{staticClass:"imgDesign",staticStyle:{"height":"150px"}},[_c('img',{staticStyle:{"border-radius":"9px"},attrs:{"loading":"lazy","src":require("@/assets/menageService6.png")}})]),_c('div',{staticClass:"titleDesign"},[_c('div',{staticClass:"title2"},[_vm._v(" 基于大模型GPT能力，建设安东通GPT，内嵌集团各领域文档知识，实现智能问答对话，员工可以快速便捷获取知识。 ")])])])])])])
}]

export { render, staticRenderFns }