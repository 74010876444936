var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.model===false)?_c('div'):_c('div',[_c('div',{staticClass:"totalModel"},[_c('div',{staticClass:"screen1"},[_c('headtip',{on:{"showDialog":_vm.showPartner}}),_vm._m(0),_c('div',{staticClass:"titlle3",on:{"click":function($event){return _vm.clickConsult('Oil & Gas Generic AI')}}},[_vm._v(" Product Inquiry ")]),_vm._m(1),_c('div',{staticClass:"layer3"})],1),_c('div',{staticClass:"screenmake2"},[_vm._m(2),_c('div',{staticClass:"grid"},[_vm._m(3),_c('div',{staticClass:"card"},[_c('div',{staticClass:"back",on:{"click":_vm.detail}},[_c('div',{staticClass:"imgDesign"}),_vm._m(4)])]),_vm._m(5)])]),_c('div',{staticClass:"screenmake3"},[_c('div',{staticClass:"heighLight"},[_vm._v("Successful Cases")]),_c('div',{staticClass:"grid"},[_c('el-carousel',{attrs:{"indicator-position":"outside"}},_vm._l((1),function(item){return _c('el-carousel-item',{key:item},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"back"},[_c('div',{staticClass:"imgDesign"},[_c('img',{attrs:{"src":require("@/assets/gpt16.png")}})]),_c('div',{staticClass:"titleDesign"},[_c('div',{staticStyle:{"font-size":"14px","text-align":"center"}},[_vm._v("Fracturing Project For Shale Gas Wells Of An Oil & Gas Field Branch In XX")]),_c('div',{staticClass:"title1"},[_vm._v("In 2022, Anton provided fracturing engineering services for an Oil & Gas field branch, increasing the average EUR of shale gas wells in Changning block by 18% synchronously, and in Yuxi block by more than 10% year-on-year; and the average daily production of wells tested under the new process of tight gas fracturing amounted to 409,800 m3, which is 25% higher than that of the conventional process.")])])])])])}),1)],1)]),_c('bottomTip'),_c('partnerTip',{attrs:{"dialogVisible":_vm.dialogconsult},on:{"hideDialog":_vm.hide}}),_c('consultTip',{attrs:{"dialogVisible":_vm.dialogVisible,"thisTitle":_vm.title},on:{"hideDialog":_vm.hideDialog}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layer2"},[_c('div',{staticClass:"title1"},[_vm._v("Design-oriented GPTs")]),_c('div',{staticClass:"title2"},[_vm._v("Design-oriented GPTs, based on various factors such as geological conditions, formation structure, engineering requirements, and production targets of the target area, formulate scientific, reasonable, and feasible design solutions to realize efficient, safe, and economic development of Oil & Gas resources.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title4"},[_vm._v("*The generalization ability of the Oil & Gas AI model is limited, so it may need to be adapted and optimized according to specific scenes and needs in practical applications. "),_c('br'),_vm._v("① Design-oriented services are provided by Antonoil's offline technical personnel"),_c('br'),_vm._v("② GPT development services are provided by Antonoil's AI professionals")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"heighLight"},[_c('span',[_vm._v("Design-oriented GPTs Services")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card1"},[_c('div',{staticClass:"back"},[_c('div',{staticClass:"imgDesign"}),_c('div',{staticClass:"titleDesign"},[_c('div',{staticClass:"Title1"},[_vm._v(" Optimization Of Fracking Design Scheme Parameters")]),_c('div',{staticClass:"Title2"},[_vm._v("Geology and engineering were combined to develop a personalized scheme based on the actual drilling trajectory of the new well and the stacking and contact relationship of different river sand bodies, optimize the segmentation and cluster parameters, strengthen the fracture complexity, maximize the fracture control volume, and release the effective sand production capacity. Meanwhile, the bottomhole pressure and net pressure are calculated in real time, and according to the change of net pressure, the fracture extension situation is judged in time, the risk of sand plugging is predicted, and the construction parameters are optimized and adjusted in real time to ensure the maximum fracture control volume of a single well.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"titleDesign"},[_c('div',{staticClass:"Title1"},[_vm._v(" Drilling Engineering Program Design")]),_c('div',{staticClass:"Title2"},[_vm._v(" Reasonable well location, well structure, drilling technology, etc. are designed to ensure safe and fast drilling to Oil & Gas formations. Drilling engineering program design needs to consider a variety of factors, including formation pressure, formation stability, drilling fluid performance, etc. Reasonable control of these factors is the key to ensure the smooth progress of drilling operations. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card2"},[_c('div',{staticClass:"back"},[_c('div',{staticClass:"imgDesign"}),_c('div',{staticClass:"titleDesign"},[_c('div',{staticClass:"Title1"},[_vm._v("Oil & Gas field development design ")]),_c('div',{staticClass:"Title2"},[_vm._v("A detailed Oil & Gas field exploitation program is developed according to the actual situation. It involves a number of aspects such as the extraction method of the Oil & Gas reservoir, the construction of production facilities, and the design of the process flow. The goal is to formulate an optimal development plan to realize the efficient development of Oil & Gas resources, and at the same time to guarantee production safety and environmental protection.")])])])])
}]

export { render, staticRenderFns }