import { render, staticRenderFns } from "./gptFrack.vue?vue&type=template&id=6ff24234&scoped=true"
import script from "./gptFrack.vue?vue&type=script&lang=js"
export * from "./gptFrack.vue?vue&type=script&lang=js"
import style0 from "./gptFrack.vue?vue&type=style&index=0&id=6ff24234&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ff24234",
  null
  
)

export default component.exports