<template>

  <div class="totalmakeHole">
    <div class="makeHole1">
      <div class="background">
        <!--      logo  you气通GPT社区 合作伙伴  登录-->
        <headtip @showDialog="showPartner"></headtip>
        <!--     文字-->
        <div class="titlle1">智能油田解决方案</div>
        <div class="title4">*该解决方案是现场的油气开发技术服务与人工智能模型融合，通过现场的解决方案为客户提供服务，如需对该方案进一步了解合作，请随时与我们联系。</div>
        <div class="titlle3" @click="dialogVisible=true">
          Solution Consulting
        </div>


      </div>
      <div class="screenmake0">
        <div class="heighLight">智能油田服务总览</div>
        <div class="heightLight2">致力于为客户提供数智化转型解决方案，服务于各个应用场景，与各类科技公司合作，为智慧油气的高效运行管理提供卓越服务</div>
        <div class="grid">
          <div class="card1">
            <div class="back">
              <div class="imgDesign">
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="screenmake">
        <div class="heighLight">
          <span style="background-color: #FF6400;border-radius: 50%; width:1.7vw;font-size:2vw;height:1.7vw;display: flex;justify-content: center;align-items: center;color: white;">1</span>
          <span>&nbsp;&nbsp;油气生产智能设计施工服务</span>
          </div>
        <div class="grid">
          <div class="card">
            <div class="back">

              <div class="titleDesign">
                <div class="Title1">01 井位集输系统部署方案</div>
                <div class="Title2">根据井位部署方案，自动收集地理数据、环境数据，生成集输管网部署方案，支持人工调优、环境数据补充，不断优化调整方案。</div>
              </div>
              <div class="imgDesign"></div>
            </div>


          </div>
          <div class="card1">
            <div class="back">

              <div class="titleDesign">
                <div class="Title1">02 管网设计施工方案</div>
                <div class="Title2">根据管网部署方案，细化管网开挖、埋地、隧道、穿越、阀室、网络、电力、监控等设计施工内容，预估工程周期，支持人工调整 ，周边人文、社会环境补充。

                </div>
              </div>
              <div class="imgDesign"></div>
            </div>


          </div>
          <div class="card2">
            <div class="back">

              <div class="titleDesign">
                <div class="Title1">03 场站施工与建设方案</div>
                <div class="Title2">根据场站功能，生成场站设备、设施、管网及其工艺设计参数，并根据设计人员要求不断优化，生产三维设计图，根据最终设计，预估工程周期，生成施工周期计划，指导建设过程。</div>
              </div>
              <div class="imgDesign"></div>
            </div>


          </div>

        </div>

      </div>
      <div class="screenmake2">
        <div class="heighLight">
          <span style="background-color: #FF6400;border-radius: 50%; width:1.7vw;font-size:2vw;height:1.7vw;display: flex;justify-content: center;align-items: center;color: white;">2</span>
          <span>&nbsp;&nbsp;油气生产智能交互服务</span>
          </div>
        <div class="grid">
          <div class="card">
            <div class="back">
              <div class="imgDesign"></div>
              <div class="titleDesign">
                <div class="Title1">生产专题工作汇报</div>
                <div class="Title2">
                  针对产能、产量、设备、安全、能耗、工艺、经营、财务等专项问题进行分析、解释，支持问答方式。
                </div>
              </div>
            </div>


          </div>
          <div class="card1">
            <div class="back">
              <div class="imgDesign"></div>
              <div class="titleDesign">
                <div class="Title1">生产运行情况汇报</div>
                <div class="Title2">以通用人类语言、图形界面等方式，汇报生产、安全、经营、工艺、设备等情况，并可将可能面临的问题、推荐解决方案等。</div>
              </div>
            </div>


          </div>
          <div class="card2">
            <div class="back">
              <div class="imgDesign"></div>
              <div class="titleDesign">
                <div class="Title1">知识库整理、更新</div>
                <div class="Title2">自动输出生产知识库，包括生产调度策略、计划执行、管网调度、油气水处理、经营管理及其他常识。</div>
              </div>
            </div>


          </div>
        </div>

      </div>
      <div class="screenmake3">
        <div class="heighLight">
          <span style="background-color: #FF6400;border-radius: 50%; width:1.7vw;font-size:2vw;height:1.7vw;display: flex;justify-content: center;align-items: center;color: white;">3</span>
          <span>&nbsp;&nbsp;智慧场站</span>
        </div>
        <div class="grid">
          <div class="card">
            <div class="back">
              <div class="imgDesign">
                <img src="@/assets/oillight1.png">
              </div>
              <div class="titleDesign">
                <div class="Title1">油气田视频监控</div>
                <div class="Title2">
                  建设过程使用4K全景视频监控+无人机远程监控，通过AI识别技术，管控工程进度及施工安全。
                </div>
              </div>
            </div>


          </div>
          <div class="card1">
            <div class="back">
              <div class="imgDesign">
                <img src="@/assets/oillight2.png">
              </div>
              <div class="titleDesign">
                <div class="Title1">智能生产管理</div>
                <div class="Title2">完成场站建设后，实现智能化管理，包括远程监控生产、设备、巡检、安全和作业过程。可实现提高效率和生产率、数据分析和决策支持、节省成本。</div>
              </div>
            </div>


          </div>
          <div class="card2">
            <div class="back">
              <div class="imgDesign">
                <img src="@/assets/oillight3.png">
              </div>
              <div class="titleDesign">
                <div class="Title1">设备调度策略优化</div>
                <div class="Title2">根据智能化目标需求，配套高效新技术新产品，通过现场集成应用，全天候实时监测设备运行状况。可以实现降低维护成本、增强设备可靠性、优化资源利用。</div>
              </div>
            </div>


          </div>
        </div>

      </div>
      <div class="screenmake4">
        <div class="heighLight">Successful Cases</div>
        <div class="grid">
          <el-carousel indicator-position="outside">
            <el-carousel-item v-for="item in success" :key="item">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img :src="item.img"/>
                  </div>
                  <div class="titleDesign">
                    <div class="title1">{{item.title1}}</div>
                    <div class="title2">{{item.title3}}</div>
                    <div class="title2">{{item.title4}}</div>
                    <div class="title2">{{item.title5}}</div>

                  </div>
                </div>


              </div>


            </el-carousel-item>
          </el-carousel>
        </div>

      </div>
      <bottomTip></bottomTip>
    </div>
    <partnerTip
        :dialogVisible="dialogconsult"
        @hideDialog="hide"
    ></partnerTip>
    <resultTip
        :dialogVisible="dialogVisible"
        @hideDialog="hideDialog"
    ></resultTip>
  </div>

</template>


<script>

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import resultTip from "@/components/ResultTip.vue";
import {shareUrl} from "@/utils/vxshare";
export default {

  name: "",

  props: [],

  components: {PartnerTip, resultTip, headtip,bottomTip},

  data() {

    return {
      dialogconsult:false,
      dialogVisible:false,
      success:[{
        img:require("@/assets/success7.png"),
        title1:"天然气净化厂工艺管理平台",
        title3:"采用先进任务调度技术，智能分配管理各项生产任务，确保生产流程高效顺畅。",
        title4:"引入loT，实现现场设备实时监控和数据采集，有效降低人工巡检成本和风险。" ,
        title5:   "基于数据挖掘，深度分析生产大数据，提炼数据价值，支持管理层决策。",

      },{
        img:require("@/assets/success8.png"),
        title1:"文本标签压缩机组运行智能调控脑",
        title3:"从设备能效、安全运行、成本优化等方面实现设备设施最优化运行参数获取。",
        title4:"大模型迭代，形成设备智控调优模型。" ,
        title5:   "控制协议打通，实现机组起停及切换智能化控制。",

      },
      ]
    }

  },

  methods: {
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner(){
      this.dialogconsult=true;
    },
 handleScroll() {
      if (this.model === false) {
        this.scrollHeight = this.$refs.total.scrollTop;
      } else {
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if (this.scrollHeight < 100) {
        this.scrollTrue = true
      } else {
        this.scrollTrue = false
      }
      if (this.scrollHeight < 50) {
        this.scrollback = false
      } else {
        this.scrollback = true
      }
      console.log("gaodu", this.scrollHeight < 100)
    },
  },
  //进入该页面时，用之前保存的滚动位置赋值
  beforeDestroy() {
    // 移除事件监听器
    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);


    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);


  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: '智能采油气解决方案_油气开发作业GPT应用项目服务-油气通GPT',
        pageDescription: '安东是专业的采油气技术一体化服务提供商，拥有高素质和经验丰富的采油气技术服务团队、专业设计软件、国际化标准管理体系，通过全球采油技术资源整合，为国内外油田提供全生命周期的排水采气工艺技术服务，油田化学产品及技术服务，人工举升产品及技术与油田运维服务、特色采油技术服务等。',
        pageKeywords: '智能采油气，智能采油工程，压裂施工参数优化，智能分层注采，智能人工举升，智能采气工程，智能井群能量优化管理，智能柱塞排水采气，智能泡沫排水采气，智能采油气解释，智能采油气设计，油气开发作业GPT，油气通GPT',

      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .topback{
    position: fixed;
    z-index:999999999999;
    width: 100vw;
    padding: 0 0 0 2.86077411900635vw;
  }
  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    height: 100vh;

    .background {
      padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 7.86077411900635vw;
      width: 100%;
      height: 72.41285537700865vh;
      background-image: url("../../assets/makehole1.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */
      .titlle1 {
        margin-top: 15.09270704573548vh;
        text-align: center;
        font-size: 3.0151935298vw;
         font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #111111;
        line-height: 2.84301606922126vh

      }
      .title4 {
        text-align: center;
        margin: 6.82076637824475vh auto 0 auto;
        width: 38.45869439630272vw;
        font-size: 1.0287117273vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color:#111111;
        line-height: 4.31520395550062vh;
      }
      .titlle3 {
        margin: 8.07911001236094vh auto;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.27vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #ffffff;
        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 53px;
        width: fit-content;
        padding: 2.8vh 1.5989601386vw;
        height: 4.5624227441vh;
        background: #2f6eff;
        border: #2f6eff 1px solid;

      }

      .titlle3:hover {

        color: #2168DB;
        border: #2168DB 1px solid;
        background: rgba(255, 255, 255, 0.5);
      }
    }

    .screenmake0 {
      padding: 7.78739184177998vh 11.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 1.7vw;
        //color: #53A3F7;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 8.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      }
      .grid {
        //margin-top: 3.78739184177998vh;
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        //column-gap: 1vw;

        .card1 {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;

          .back {
            border-radius: 16px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height: fit-content;
            overflow: hidden;
            background: #FFFFFF;
            margin-bottom: 1.5248454883vh;

            .imgDesign {
              text-align: center;
              height: 75vh;
              width: 100%;
              background-image: url("../../assets/oilserve1.png");
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 1.5248454883vh 1.5248454883vw;
              //background-color: #2F6EFF;
              .Title1 {
                color: black;
                text-align: center;
                font-size: 1vw;

                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 700;
              }

            }

            .backconsult {
              display: none;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              //color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 5.55414091470952vw;
            }

            .backconsultArrow {
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
            }

          }


        }

        .card2 {
         // cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;

          .back {
            border-radius: 16px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height: fit-content;
            overflow: hidden;
            background: #FFFFFF;
            margin-bottom: 1.5248454883vh;

            .imgDesign {
              text-align: center;
              height: 48vh;
              width: 100%;
              background-image: url("../../assets/frack02.png");
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 1.5248454883vh 1.5248454883vw;
              //background-color: #2F6EFF;
              .Title1 {
                color: black;
                text-align: center;
                font-size: 1vw;

                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 700;
              }

            }

            .backconsult {
              display: none;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              //color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 5.55414091470952vw;
            }

            .backconsultArrow {
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
            }

          }

          .back:hover {
            transform: scale(1.05);
          }
        }
      }
    }

    .screenmake {
      padding: 7.78739184177998vh 11.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: #ebf1fd;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:8.23733003708282vh;
        font-size: 1.7vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #FF6400;
        line-height: 4.23733003708282vh;

      }

      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 1.8vw;

        .card {
         // cursor: pointer;
          width: fit-content;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;

          .back {
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height: 50.87144622991347vh;
            border-radius: 16px;
            overflow: hidden;
            background: #FFFFFF;

            .imgDesign {
              position: absolute;
              bottom: 0;
              text-align: center;
              height: 26.24969097651422vh;
              width: 100%;
              background-image: url("../../assets/charge2.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 0 1.5248454883vw;

              .Title1 {
                margin-top: 2.8124845488vh;
                text-align: center;
                font-size: 1vw;
                height: 2.7194066749vh;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 700;
                line-height: 3.33745364647713vh;
              }

              .Title2 {
                margin-top: 4.0124845488vh;
                text-align: justify;
                font-size: 0.8243212016vw;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 500;
                line-height: 3.194066749vh;
              }
            }

            .backconsult {
              display: none;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              //color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 5.55414091470952vw;
            }

            .backconsultArrow {
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
            }

          }
        }

        .card:hover {
          transform: scale(1.05);
        }

        .card1 {
         // cursor: pointer;
          width: fit-content;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;

          .back {
            border-radius: 16px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height: 50.87144622991347vh;
            overflow: hidden;
            background: #FFFFFF;

            .imgDesign {
              position: absolute;
              bottom: 0;
              text-align: center;
              height: 26.24969097651422vh;
              width: 100%;
              background-image: url("../../assets/charge3.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 0 1.5248454883vw;

              .Title1 {
                margin-top: 2.8124845488vh;
                text-align: center;
                font-size: 1vw;
                height: 2.7194066749vh;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 700;
                line-height: 3.33745364647713vh;
              }

              .Title2 {
                margin-top: 4.0124845488vh;
                text-align: justify;
                font-size: 0.8243212016vw;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 500;
                line-height: 3.194066749vh;
              }
            }

            .backconsult {
              display: none;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              //color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 5.55414091470952vw;
            }

            .backconsultArrow {
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
            }

          }
        }

        .card1:hover {
          transform: scale(1.05);

        }

        .card2 {
         // cursor: pointer;
          width: fit-content;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;

          .back {
            border-radius: 16px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height: 50.87144622991347vh;
            overflow: hidden;
            background: #FFFFFF;

            .imgDesign {
              position: absolute;
              bottom: 0;
              text-align: center;
              height: 26.24969097651422vh;
              width: 100%;
              background-image: url("../../assets/charge4.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 0 1.5248454883vw;

              .Title1 {
                margin-top: 2.8124845488vh;
                text-align: center;
                font-size: 1vw;
                height: 2.7194066749vh;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 700;
                line-height: 3.33745364647713vh;
              }

              .Title2 {
                margin-top: 4.0124845488vh;
                text-align: justify;
                font-size: 0.8243212016vw;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 500;
                line-height: 3.194066749vh;
              }
            }

            .backconsult {
              display: none;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              //color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 5.55414091470952vw;
            }

            .backconsultArrow {
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
            }

          }
        }

        .card2:hover {
          transform: scale(1.05);

        }

        .card3 {
         // cursor: pointer;
          width: fit-content;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;

          .back {
            border-radius: 16px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height: 47.87144622991347vh;
            overflow: hidden;
            background: #FFFFFF;

            .imgDesign {
              position: absolute;
              bottom: 0;
              text-align: center;
              height: 19.24969097651422vh;
              width: 100%;
              background-image: url("../../assets/charge5.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 0 1.5248454883vw;

              .Title1 {
                margin-top: 2.8124845488vh;
                text-align: center;
                font-size: 1vw;
                height: 2.7194066749vh;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 700;
                line-height: 3.33745364647713vh;
              }

              .Title2 {
                margin-top: 4.0124845488vh;
                text-align: justify;
                font-size: 0.8243212016vw;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 500;
                line-height: 3.194066749vh;
              }
            }

            .backconsult {
              display: none;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              //color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 5.55414091470952vw;
            }

            .backconsultArrow {
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
            }

          }
        }

        .card3:hover {
          transform: scale(1.05);
        }

      }
    }

    .screenmake2 {
      padding: 7.78739184177998vh 11.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: white;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:8.23733003708282vh;
        font-size: 1.7vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #FF6400;
        line-height: 4.23733003708282vh;

      }

      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        //column-gap: 1vw;

        .card {
         // cursor: pointer;
          width: fit-content;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;

          .back {
            border-radius: 16px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height: 45.87144622991347vh;
            overflow: hidden;
            background: #FFFFFF linear-gradient(to bottom, #CFE1FF 0%, #F0F6FF 100%);

            .imgDesign {
              text-align: center;
              margin: 3.1124845488vh auto;
              height: 15.3152039555vh;
              width: 15.3152039555vh;
              opacity: 1;
              background-image: url("../../assets/icon-11.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              .Title1 {
                margin-top: 5vh;
                text-align: center;
                height: 2.7194066749vh;
                font-size: 1vw;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 700;
                line-height: 2.7194066749vh;
              }

              .Title2 {

                margin: 4.5vh 1.8597920277vw auto 1.8597920277vw;
                text-align: justify;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }

        .card:hover {
          .imgDesign {
            transform: scale(1.05);
          }

        }

        .card1 {
         // cursor: pointer;
          width: fit-content;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;

          .back {
            border-radius: 16px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height: 45.87144622991347vh;
            overflow: hidden;
            background: #FFFFFF linear-gradient(to bottom, #CFE1FF 0%, #F0F6FF 100%);

            .imgDesign {
              text-align: center;
              margin: 3.1124845488vh auto;
              height: 15.3152039555vh;
              width: 15.3152039555vh;
              opacity: 1;
              background-image: url("../../assets/icon-12.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              .Title1 {
                margin-top: 5vh;
                text-align: center;
                height: 2.7194066749vh;
                font-size: 1vw;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 700;
                line-height: 2.7194066749vh;
              }

              .Title2 {

                margin: 4.5vh 1.8597920277vw auto 1.8597920277vw;
                text-align: justify;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }

        .card1:hover {
          .imgDesign {
            transform: scale(1.05);
          }
        }

        .card2 {

         // cursor: pointer;
          width: fit-content;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;

          .back {
            border-radius: 16px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height: 45.87144622991347vh;
            overflow: hidden;
            background: #FFFFFF linear-gradient(to bottom, #CFE1FF 0%, #F0F6FF 100%);

            .imgDesign {
              text-align: center;
              margin: 3.1124845488vh auto;
              height: 15.3152039555vh;
              width: 15.3152039555vh;
              opacity: 1;
              background-image: url("../../assets/icon-13.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              .Title1 {
                margin-top: 5vh;
                text-align: center;
                height: 2.7194066749vh;
                font-size: 1vw;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 700;
                line-height: 2.7194066749vh;
              }

              .Title2 {

                margin: 4.5vh 1.8597920277vw auto 1.8597920277vw;
                text-align: justify;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }

        .card2:hover {
          .imgDesign {
            transform: scale(1.05);
          }
        }
      }
    }

    .screenmake3 {
      padding: 7.78739184177998vh 11.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: #ebf1fd;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:8.23733003708282vh;
        font-size: 1.7vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #FF6400;
        line-height: 4.23733003708282vh;

      }

      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        column-gap:1.2vw;

        .card {
         // cursor: pointer;
          width: fit-content;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;

          .back {
            border-radius: 16px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height: 48.87144622991347vh;
            overflow: hidden;
            background:white;

            .imgDesign {
              position: absolute;
              transition: transform 0.3s ease-out;
              overflow: hidden;
              width: 100%;
              height: 24.69962917181706vh;
              opacity: 1;
              background-image: url("../../assets/ground7.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              .Title1 {
                margin-top: 30vh;
                text-align: center;
                height: 2.7194066749vh;
                font-size: 1vw;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 700;
                line-height: 2.7194066749vh;
              }

              .Title2 {

                margin: 3vh 1.8597920277vw auto 1.8597920277vw;
                text-align: justify;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }

        .card:hover {
          .imgDesign {
            transform: scale(1.05);
          }

        }

        .card1 {
          border-radius: 16px;
         // cursor: pointer;
          width: fit-content;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;

          .back {
            border-radius: 16px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height: 48.87144622991347vh;
            overflow: hidden;
            background:white;

            .imgDesign {
              position: absolute;
              transition: transform 0.3s ease-out;
              overflow: hidden;
              width: 100%;
              height: 24.69962917181706vh;
              opacity: 1;
              background-image: url("../../assets/ground7.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              .Title1 {
                margin-top: 30vh;
                text-align: center;
                height: 2.7194066749vh;
                font-size: 1vw;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 700;
                line-height: 2.7194066749vh;
              }

              .Title2 {

                margin: 3vh 1.8597920277vw auto 1.8597920277vw;
                text-align: justify;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }

        .card1:hover {
          .imgDesign {
            transform: scale(1.05);
          }
        }

        .card2 {
          border-radius: 16px;
         // cursor: pointer;
          width: fit-content;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;

          .back {
            border-radius: 16px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height: 48.87144622991347vh;
            overflow: hidden;
            background:white;

            .imgDesign {
              position: absolute;
              transition: transform 0.3s ease-out;
              overflow: hidden;
              width: 100%;
              height: 24.69962917181706vh;
              opacity: 1;
              background-image: url("../../assets/ground7.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              .Title1 {
                margin-top: 30vh;
                text-align: center;
                height: 2.7194066749vh;
                font-size: 1vw;
                 font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 700;
                line-height: 2.7194066749vh;
              }

              .Title2 {

                margin: 3vh 1.8597920277vw auto 1.8597920277vw;
                text-align: justify;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }

        .card2:hover {
          .imgDesign {
            transform: scale(1.05);
          }
        }
      }
    }

    .screenmake4{
      padding: 7.78739184177998vh 11.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: #212a3c;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:8.23733003708282vh;
        font-size: 1.7vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color:white;
        line-height: 4.23733003708282vh;

      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
         // cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          left:-55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
         // cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          right: -55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .card{
         // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 16px;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:65.87144622991347vh ;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: 55.60311958405546vw;
              height:100%;
              opacity: 1;
              background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 1.5248454883vh 1.5248454883vw;
              margin:0 1vw auto auto;
              color: white;
              width: 35%;
              float: right;
              .title1{
                margin-top: 4vh;
                font-size: 1vw;
              }
              .title2{
                margin-top: 3vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }
              .success{
                display: grid;
                grid-template-columns: minmax(0,1fr) minmax(0,1fr);
                //column-gap: 1vw;
                margin-top: 3vh;
                .suc1{

                  width: 100%;
                  height: 8vh;
                  .title3{
                    text-align: center;
                    margin-top: 2vh;
                    line-height: 3.194066749vh;
                    font-size: 0.8243212016vw;
                  }

                }

              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 66.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
         // cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
  }
}

</style>
